export function getEventOnSchedule(element) {    
    var eventOnSchedule = {
        shareList: element.shareList,
        id: element.id,
        Subject: element.title,
        Description: element.description,
        Link: element.link,
        Location: element.location,
        EventType: element.type,
        StartTime: element.start_date,
        EndTime: element.end_date,
        idOutlook: element.id_outlook ? element.id_outlook : "",
        isResponsible: element.isResponsible,
        transactionId: element.transactionId ? element.transactionId : null
    }

    if (element.recurrence) {
        eventOnSchedule.RecurrenceRule = element.recurrence
    }
    return eventOnSchedule
}

export function getParsedEvents(eventsObj) {
    if (!eventsObj) {
        return []
    }
    const data = eventsObj
    var eventsOnSchedule = []
    for (var index = 0; index < data.length; index++) {
        eventsOnSchedule.push(getEventOnSchedule(data[index]))
    }
    return eventsOnSchedule
}

export function getEventFromSchedule(eventOnSchedule) {
    const eventStartDate = new Date(eventOnSchedule.StartTime);
    const eventEndDate = new Date(eventOnSchedule.EndTime);

    const startDateUTC = eventStartDate.toISOString();
    const endDateUTC = eventEndDate.toISOString();

    var events = {
        id: eventOnSchedule.id,
        recurrence: eventOnSchedule.RecurrenceRule,
        shareList: eventOnSchedule.shareList,
        title: eventOnSchedule.Subject,
        start_date: startDateUTC,
        end_date: endDateUTC,
        description: eventOnSchedule.Description ? eventOnSchedule.Description : "",
        link: eventOnSchedule.Link ? eventOnSchedule.Link : "",
        type: eventOnSchedule.EventType ? eventOnSchedule.EventType : "Événement quotidien",
        idOutlook: eventOnSchedule.idOutlook,
        location: eventOnSchedule.Location,
        transactionId: eventOnSchedule.transactionId ? eventOnSchedule.transactionId : null
    }
    return events
}

// export function getTransformedEventFromSchedule(eventOnSchedule, updateCase) {
//     var eventEndTime = convertDate(eventOnSchedule.EndTime)
//     if (updateCase && eventOnSchedule.RecurrenceRule && compareRecurrenceEndToDateObj(eventOnSchedule.RecurrenceRule, eventEndTime) === 1) {
//         var newUntilValue = getDateValue(eventEndTime)
//         eventOnSchedule.RecurrenceRule = eventOnSchedule.RecurrenceRule.replace(/UNTIL=\d{8}T\d{6}Z/, `UNTIL=${newUntilValue}`);
//     }


//     var event = {
//         shareList: eventOnSchedule.shareList,
//         id: eventOnSchedule.Id,
//         title: eventOnSchedule.Subject,
//         start_date: convertDate(eventOnSchedule.StartTime),
//         end_date: eventEndTime,
//         location: eventOnSchedule.Location ? eventOnSchedule.Location : "",
//         description: eventOnSchedule.Description ? eventOnSchedule.Description : "",
//         link: eventOnSchedule.Link ? eventOnSchedule.Link : "",
//         type: eventOnSchedule.EventType ? eventOnSchedule.EventType : "Événement quotidien",
//         idOutlook: eventOnSchedule.idOutlook ? eventOnSchedule.idOutlook : "",
//         recurrence: eventOnSchedule.RecurrenceRule

//     }
//     return event
// }



// function convertDate(d) {
//     const date = new Date(d); // Create a new Date object with the current date and time

//     const year = date.getFullYear(); // Get the four-digit year
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (adding 1 since months are zero-indexed) and pad with leading zeros if necessary
//     const day = String(date.getDate()).padStart(2, '0'); // Get the day of the month and pad with leading zeros if necessary
//     const hours = String(date.getHours()).padStart(2, '0'); // Get the hours and pad with leading zeros if necessary
//     const minutes = String(date.getMinutes()).padStart(2, '0'); // Get the minutes and pad with leading zeros if necessary
//     const seconds = String(date.getSeconds()).padStart(2, '0'); // Get the seconds and pad with leading zeros if necessary

//     const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
//     return formattedDate
// }


function parseBody(text, htmlContent) {

    var desc = ""
    var link = ""
    var eventType = ""
    // Deuxième cas: Event créer a partir de LEGALPROD
    // Le libellé description précède la description
    // On recupère les informations en se basant sur les libellés
    var beginDescription = text.toLowerCase().indexOf("description:")
    if (beginDescription != -1) {
        // Récupération de la description
        var lengthOfDescriptionLabel = ("description:".length)
        var endDescription = text.toLowerCase().indexOf("link:")

        desc = text.substring(beginDescription + lengthOfDescriptionLabel, endDescription).trim()

        // Récupération du lien du meet
        var lengthOfLinkLabel = ("Link:".length)
        var endLinkSection = text.toLowerCase().indexOf("eventtype:")

        link = text.substring(endDescription + lengthOfLinkLabel, endLinkSection).trim()

        // Récupération du type d'évent
        var lengthOfEventTypeLabel = ("EventType:".length)

        eventType = text.substring(endLinkSection + lengthOfEventTypeLabel).trim()
    } else {
        // Deuxième cas: Event créer a partir de Outlook
        // La description commence par directement et fini lorsqu'il y a
        // des pointillés et le lien on va le chercher dans la balise <a href="lien">

        // Récupération de la description
        if(text.indexOf(".....") != -1) {
            desc = text.substring(0, text.indexOf(".....")).trim()
        } else {        
            desc = text.trim()
        }
        
        // Récupération du lien skype
        var startLink = htmlContent.indexOf("href=")
        if(startLink != -1) {
            lengthOfLinkLabel = 'href="'.length
            endLinkSection = htmlContent.indexOf('"', startLink + lengthOfLinkLabel)

            link = htmlContent.substring(startLink+lengthOfLinkLabel, endLinkSection).trim()
        }
    }


    return {
        desc: desc,
        link: link,
        eventType: eventType,
    }
}

export function getTransformedEventsFromOutlook(eventsOutlook) {
    var eventsOnSchedule = []
    for (let i = 0; i < eventsOutlook.length; i++) {
        const eventTemp = transformEventOutlookToEventType(eventsOutlook[i])
        eventsOnSchedule.push(eventTemp)
    }
    return eventsOnSchedule
}

export function getEventFromScheduleOutlook(event) {
    console.log(event);
    
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDate = new Date(event.start_date).toISOString().replace(".000Z", "+00:00");
    const endDate = new Date(event.end_date).toISOString().replace(".000Z", "+00:00");

    var data = {
        subject: event.title,
        body: {
            contentType: "html", content: "<html><head><meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\"></head><body><div class=\"elementToProof\" style=\"font-family:Calibri,Arial,Helvetica,sans-serif; font-size:12pt; color:rgb(0,0,0)\">"
                + tempFuncToCheckUndefined(event) +
                "</div><div style=\"font-family:Calibri,Arial,Helvetica,sans-serif; font-size:12pt; color:rgb(0,0,0)\"></div></body></html>"
        },
        start: { dateTime: startDate, timeZone: timezone },
        end: { dateTime: endDate, timeZone: timezone },
        location: { displayName: event.Location },
        attendees: getAttendees(event.shareList),
        transactionId: event.transactionId
    }

    if (event.recurrence) {
        data['recurrence'] = stringToRecurrenceObject(event.recurrence, startDate)
    }
    
    return data
}

export function transformEventOutlookToEventType(object) {    
    let dateStart = object.start.dateTime
    let dateEnd = object.end.dateTime

    //msGraph API gives UTC timeZone all the time 
    var obj = parseBody(object.bodyPreview, object.body.content)
    // var event = {
    //     idOutlook: object.id,
    //     Subject: object.subject,
    //     StartTime: dateStart,
    //     EndTime: dateEnd,
    //     id: object.id,
    //     Description: obj.desc,
    //     Link: obj.link,
    //     Attendees: object.attendees,
    //     Location: object.location ? object.location.displayName : "",
    //     isResponsible: true,
    //     EventType: obj.eventType !== "undefined" && obj.eventType ? obj.eventType : "Événement quotidien"
    // }
    // if (object.recurrence) {
    //     event['RecurrenceRule'] = recurrenceObjectToString(object.recurrence)
    // }

    var event = {
        id: object.id,
        title: object.subject,
        start_date: dateStart,
        end_date: dateEnd,
        description: obj.desc,
        link: obj.link,
        type: obj.eventType,
        idOutlook: object.id,
        location: object.Location ? object.Location.displayName : "",
        transactionId: object.transactionId ? object.transactionId : null
    }

    if (object.recurrence) {
        event['RecurrenceRule'] = recurrenceObjectToString(object.recurrence)
    }
        
    return event
}

function getAttendees(shareList) {
    var arr = []
    if (shareList) {
        shareList.forEach(element => {
            var obj = {
                emailAddress: {
                    address: element,
                },
                type: 'required'
            }
            arr.push(obj)
        });
        return arr
    }
    return arr
}


function tempFuncToCheckUndefined(eventOnSchedule) {
    const temp = eventOnSchedule.description !== 'undefined' && eventOnSchedule.description ? eventOnSchedule.description : ""
    const temp2 = eventOnSchedule.link !== 'undefined' && eventOnSchedule.link ? eventOnSchedule.link : ""
    const temp3 = eventOnSchedule.type !== "undefined" && eventOnSchedule.type ? eventOnSchedule.eventType : "Événement quotidien"
    return "Description:" + temp + "<br><br>" + "Link:" + temp2 + "<br><br>" + "EventType:" + temp3
}


// //CONVERSION TO OUTLOOK FORMAT
function stringToRecurrenceObject(strFormat, dateToSet) {
    var recurrence = {
        pattern: {
            type: "",
            interval: 10,
            month: 0,
            dayOfMonth: 0,
            daysOfWeek: [],
            firstDayOfWeek: null,
            index: "first"
        },
        range: {
            type: "",
            startDate: "",
            endDate: "",
            recurrenceTimeZone: "",
            numberOfOccurrences: 0
        }
    };

    recurrence.range.type = "noEnd";
    recurrence.range.endDate = "0001-01-01";

    let currentDate = new Date(dateToSet);
    let year = currentDate.getFullYear();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let day = String(currentDate.getDate()).padStart(2, '0');

    let formattedDate = year + month + day;
    recurrence.range.startDate = getFormattedDate(formattedDate);
    recurrence.range.recurrenceTimeZone = "Central European Standard Time";
    var typeEvent = ''
    const parts = strFormat.split(";");
    for (let part of parts) {
        const [key, value] = part.split("=");

        if (key === "FREQ") {
            recurrence.pattern.type = getPatternType(value, false);
            typeEvent = value
            if (value === 'MONTHLY') {
                recurrence.pattern.dayOfMonth = formattedDate.slice(6, 8);
            }
            if (value === 'YEARLY') {
                recurrence.pattern.month = formattedDate.slice(4, 6)
            }
        } else if (key === "BYDAY") {
            recurrence.pattern.daysOfWeek = value.split(",").map(day => getDayOfWeek(day));
            delete recurrence.dayOfMonth
        } else if (key === "INTERVAL") {
            recurrence.pattern.interval = parseInt(value);
        } else if (key === "UNTIL") {
            var dateString = value
            const year = dateString.slice(0, 4);
            const month = dateString.slice(4, 6);
            const day = dateString.slice(6, 8);

            const date = new Date(`${year}-${month}-${day}`);
            date.setDate(date.getDate() + 1);

            const formattedDate = date.toISOString().slice(0, 10);
            recurrence.range.endDate = formattedDate //+1 day necessary
            recurrence.range.type = "endDate";
        } else if (key === "BYSETPOS") {
            recurrence.pattern.firstDayOfWeek = getFirstDayOfWeek(currentDate)
            recurrence.pattern.index = getIndex(value.toString());
            recurrence.pattern.type = getPatternType(typeEvent, true);
        } else if (key === "BYMONTH") {
            recurrence.pattern.month = parseInt(value);
        } else if (key === "BYMONTHDAY") {
            recurrence.pattern.dayOfMonth = parseInt(value);
        } else if (key === 'COUNT') {
            recurrence.range.numberOfOccurrences = parseInt(value);
        }
    }
    return recurrence;
}

function getFirstDayOfWeek(date) {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayIndex = new Date(date).getDay();
    return daysOfWeek[dayIndex];
}

function getPatternType(freqValue, rel = false) {
    if (freqValue === 'MONTHLY') {
        if (rel === true) {
            return 'relativeMonthly'
        }
        return 'absoluteMonthly'
    }
    if (freqValue === 'YEARLY') {
        if (rel === true) {
            return 'relativeYearly'
        }
        return 'absoluteYearly'
    }
    return freqValue.toLowerCase()

    // Add more conditions for other pattern types if needed
}

function getDayOfWeek(day) {
    const daysMap = {
        MO: "monday",
        TU: "tuesday",
        WE: "wednesday",
        TH: "thursday",
        FR: "friday",
        SA: "saturday",
        SU: "sunday"
    };
    return daysMap[day];
}

function getIndex(indexValue) {
    if (indexValue === "1") {
        return "first";
    }
    if (indexValue === "2") {
        return "second";
    }
    if (indexValue === "3") {
        return "third";
    }
    if (indexValue === "4") {
        return "fourth";
    }
    if (indexValue === "5" || indexValue === "6" || indexValue === "7") {
        return "last";
    }

}

function getIndexInverse(indexValue) {
    if (indexValue === "first") {
        return "1";
    }
    if (indexValue === "second") {
        return "2";
    }
    if (indexValue === "third") {
        return "3";
    }
    if (indexValue === "fourth") {
        return "4";
    }
    if (indexValue === "fifth") {
        return "5";
    }
    if (indexValue === "sixth") {
        return "6";
    }
    if (indexValue === "seventh") {
        return "7";
    }
    // Add more conditions for other index types if needed
}

function getFormattedDate(dateValue) {
    const year = dateValue.slice(0, 4);
    const month = dateValue.slice(4, 6);
    const day = dateValue.slice(6, 8);
    return `${year}-${month}-${day}`;
}

// //CONVERTION TO BD FORMAT


function recurrenceObjectToString(recurrence) {
    let strFormat = "";
    const pattern = recurrence.pattern;
    const range = recurrence.range;

    strFormat += `FREQ=${getFreqValue(pattern.type)};`;
    if (pattern.daysOfWeek) {
        strFormat += `BYDAY=${pattern.daysOfWeek.map(day => getDayValue(day)).join(",")};`;
    }
    strFormat += `INTERVAL=${pattern.interval};`;
    if (recurrence.range.type !== 'noEnd') {
        strFormat += `UNTIL=${getDateValue(range.endDate)};`;
    }
    if (recurrence.range.numberOfOccurrences > 0) {
        strFormat += `COUNT=${recurrence.range.numberOfOccurrences};`;
    }
    if ((pattern.type.includes('onthly') || pattern.type.includes('early')) && pattern.index) {
        strFormat += `BYSETPOS=${getIndexInverse(pattern.index.toString())};`;
    }

    if (pattern.month) {
        strFormat += `BYMONTH=${pattern.month};`
    }
    if (pattern.dayOfMonth) {
        strFormat += `BYMONTHDAY=${pattern.dayOfMonth};`;
    }


    return strFormat;
}

// // Helper functions used in recurrenceObjectToString

function getFreqValue(type) {
    if (type === 'absoluteMonthly') {
        return 'MONTHLY'
    }
    if (type === 'absoluteYearly') {
        return 'YEARLY'
    }
    return type.toUpperCase()
}

function getDayValue(day) {
    const daysMap = {
        monday: "MO",
        tuesday: "TU",
        wednesday: "WE",
        thursday: "TH",
        friday: "FR",
        saturday: "SA",
        sunday: "SU"
    };
    return daysMap[day];
}


function getDateValue(date) {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    const hour = date.slice(11, 13);
    const minute = date.slice(14, 16);
    var text = `${year}${month}${day}T`;
    if (hour && minute) {
        text += `${hour}${minute}00Z`
    }
    else if (hour) {
        text += `${hour}0000Z`
    }
    else {
        text += `000000Z`
    }
    return text
}

// function compareRecurrenceEndToDateObj(recurrenceRule, dateObj) {

//     // Extract the date part from the string
//     let dateString = recurrenceRule.match(/UNTIL=(\d{8}T\d{6}Z);/);
//     if (dateString) {
//         dateString = dateString[0]
//     }
//     else {
//         return 0
//     }
//     // Convert the extracted date string to a JavaScript Date object
//     const isoDate = dateString.match(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/);
//     let dateFromString = null
//     if (isoDate) {
//         const year = parseInt(isoDate[1], 10);
//         const month = parseInt(isoDate[2], 10) - 1; // Month is zero-based in JavaScript Date object
//         const day = parseInt(isoDate[3], 10);
//         const hours = parseInt(isoDate[4], 10);
//         const minutes = parseInt(isoDate[5], 10);
//         const seconds = parseInt(isoDate[6], 10);

//         dateFromString = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
//     }
//     // Current date (for comparison)
//     const currentDate = new Date(dateObj);
//     // Compare the dates
//     if (currentDate >= dateFromString) {

//         return 1 //END_DATE>RECURRENCE_RULE -> obnovim reccurence rule
//     }

//     return 0 //obnovim end_date
// }

// // function getDateObjFromUntil(recurrenceRule){
// //     const untilValue = recurrenceRule.match(/UNTIL=(\d{8}T\d{6}Z);/)[1];

// //     // Parse the date string and convert it to a JavaScript Date object
// //     return  new Date(`${untilValue.slice(0, 4)}-${untilValue.slice(4, 6)}-${untilValue.slice(6, 8)}T${untilValue.slice(9, 11)}:${untilValue.slice(11, 13)}:${untilValue.slice(13, 15)}.000Z`);
// // }