import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import i18n from "../i18n";
import routes from "./routes";
import { cancelAllRequests } from "@/AuthenticatedRoute";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _, next) => {
  // Annuler toutes les requêtes actives avant navigation
  cancelAllRequests();

  // Gestion du titre de la page
  let documentTitle = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  if (to.params.title) {
    documentTitle = `${process.env.VUE_APP_TITLE} - ${to.params.title}`;
  }

  // Gestion de la langue
  let language = to.params.lang;
  if (!language) {
    language = "fr";
  }
  i18n.locale = language;
  document.title = documentTitle;

  // verification du plan d'abonnement du LP Client
  const requiredSubscriptionsPlans = to.meta.subscriptionPlans;
  const isPartner = store.state.isPartner;
  if (requiredSubscriptionsPlans) {
    const LpClientSubscriptionPlan = store.state.subscriptionPlan;
    if (
      !LpClientSubscriptionPlan ||
      !requiredSubscriptionsPlans.includes(LpClientSubscriptionPlan)
    ) {
      // L'utilisateur n'a pas le plan d'abonnement requis
      isPartner ? next(`/${language}/dashboard/partner`) : next("/");
      return;
    }
  }

  // Verification du role sur les routes admin
  const requiredRole = to.meta.role;
  if (requiredRole) {
    const userRole = store.state.account.userInfos.role;
    if (!userRole || !userRole.includes(requiredRole)) {
      // L'utilisateur n'a pas le role requis
      next("/");
      return;
    }
  }

  // Gestion de la redirection
  if (to.fullPath === "/" && to.matched.length >= 1) {
    let path = to.matched[to.matched.length - 1].path;
    path = path.replace(":lang", language);
    next(path);
  } else {
    next();
  }

});

export default router;
