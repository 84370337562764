export const langFr={
  "fr": {
      "grid": {
          "EmptyRecord": "Aucun enregistrement à afficher",
          "True": "vrai",
          "False": "faux",
          "InvalidFilterMessage": "Données de filtre non valides",
          "GroupDropArea": "Faites glisser un en-tête de colonne ici pour regrouper sa colonne",
          "UnGroup": "Cliquez ici pour dissocier",
          "GroupDisable": "Le regroupement est désactivé pour cette colonne",
          "FilterbarTitle": "Cellule de barre de filtre \"s",
          "EmptyDataSourceError": "DataSource ne doit pas être vide lors du chargement initial car les colonnes sont générées à partir de dataSource dans AutoGenerate Column Grid",
          "Add": "Ajouter",
          "Edit": "Éditer",
          "Cancel": "Annuler",
          "Update": "Mise à jour",
          "Delete": "Supprimer",
          "Print": "Impression",
          "Pdfexport": "Exportation PDF",
          "Excelexport": "Exportation Excel",
          "Wordexport": "Exportation de mots",
          "Csvexport": "Exportation CSV",
          "Search": "Chercher",
          "Columnchooser": "Colonnes",
          "Save": "Sauvegarder",
          "Item": "article",
          "Items": "articles",
          "EditOperationAlert": "Aucun enregistrement sélectionné pour l'opération d'édition",
          "DeleteOperationAlert": "Aucun enregistrement sélectionné pour l'opération de suppression",
          "SaveButton": "Sauvegarder",
          "OKButton": "Ok",
          "CancelButton": "Annuler",
          "EditFormTitle": "Les détails de",
          "AddFormTitle": "Ajouter un nouvel enregistrement",
          "BatchSaveConfirm": "Voulez-vous vraiment enregistrer les modifications ?",
          "BatchSaveLostChanges": "Les modifications non enregistrées seront perdues. Es-tu sur de vouloir continuer ?",
          "ConfirmDelete": "Voulez-vous vraiment supprimer l'enregistrement ?",
          "CancelEdit": "Voulez-vous vraiment annuler les modifications ?",
          "ChooseColumns": "Choisissez la colonne",
          "SearchColumns": "colonnes de recherche",
          "Matchs": "Aucun résultat",
          "FilterButton": "Filtrer",
          "ClearButton": "Effacer",
          "StartsWith": "Commence par",
          "EndsWith": "Se termine par",
          "Contains": "Contient",
          "Equal": "Égal",
          "NotEqual": "Différent",
          "LessThan": "Inférieur",
          "LessThanOrEqual": "Inférieur ou égal",
          "GreaterThan": "Supérieur",
          "GreaterThanOrEqual": "Supérieur ou égal",
          "ChooseDate": "Choisissez une date",
          "EnterValue": "Entrez la valeur",
          "Copy": "Copie",
          "Group": "Regrouper par cette colonne",
          "Ungroup": "Dissocier par cette colonne",
          "autoFitAll": "Ajuster automatiquement toutes les colonnes",
          "autoFit": "Ajuster automatiquement cette colonne",
          "Export": "Exportation",
          "FirstPage": "Première page",
          "LastPage": "Dernière page",
          "PreviousPage": "Page précédente",
          "NextPage": "Page suivante",
          "SortAscending": "Trier par ordre croissant",
          "SortDescending": "Trier par ordre décroissant",
          "EditRecord": "Modifier l'enregistrement",
          "DeleteRecord": "Supprimer l'enregistrement",
          "FilterMenu": "Filtre",
          "SelectAll": "Tout sélectionner",
          "Blanks": "Blancs",
          "FilterTrue": "Vrai",
          "FilterFalse": "Faux",
          "NoResult": "Aucun résultat",
          "ClearFilter": "Effacer le filtre",
          "NumberFilter": "Filtres numériques",
          "TextFilter": "Filtres de texte",
          "DateFilter": "Filtres de date",
          "DateTimeFilter": "Filtres DateTime",
          "MatchCase": "Cas de correspondance",
          "Between": "Entre",
          "CustomFilter": "Filtre personnalisé",
          "CustomFilterPlaceHolder": "Entrez la valeur",
          "CustomFilterDatePlaceHolder": "Choisissez une date",
          "AND": "ET",
          "OR": "OU",
          "ShowRowsWhere": "Afficher les lignes où:",
          "UnGroupButton": "Cliquez ici pour dissocier",
          "AutoFitAll": "Ajuster automatiquement toutes les colonnes",
          "AutoFit": "Ajuster automatiquement cette colonne",
          "Clear": "Dégager",
          "FilterMenuDialogARIA": "Boîte de dialogue du menu Filtre",
          "ExcelFilterDialogARIA": "Boîte de dialogue de filtre Excel",
          "DialogEditARIA": "Boîte de dialogue Modifier",
          "ColumnChooserDialogARIA": "Boîte de dialogue de sélection de colonne",
          "ColumnMenuDialogARIA": "Boîte de dialogue du menu des colonnes",
          "CustomFilterDialogARIA": "Boîte de dialogue Filtre personnalisé",
          "SortAtoZ": "Trier de A à Z",
          "SortZtoA": "Trier de Z à A",
          "SortByOldest": "Trier par le plus ancien",
          "SortByNewest": "Trier par Plus récent",
          "SortSmallestToLargest": "Trier du plus petit au plus grand",
          "SortLargestToSmallest": "Trier du plus grand au plus petit",
          "Sort": "Sorte"
      },
      "pager": {
          "currentPageInfo": "{0} de {1} pages",
          "totalItemsInfo": "({0} éléments)",
          "firstPageTooltip": "Aller à la première page",
          "lastPageTooltip": "Aller à la dernière page",
          "nextPageTooltip": "Aller à la page suivante",
          "previousPageTooltip": "Aller à la page précédente",
          "nextPagerTooltip": "Aller aux éléments suivants du téléavertisseur",
          "previousPagerTooltip": "Accéder aux éléments de téléavertisseur précédents",
          "pagerDropDown": "objets par page",
          "pagerAllDropDown": "Articles",
          "All": "Tout",
          "totalItemInfo": "({0} élément)"
      },
      "kanban": {
          "items": "articles",
          "min": "Min",
          "max": "Max",
          "cardsSelected": "Cartes sélectionnées",
          "addTitle": "Ajouter une nouvelle carte",
          "editTitle": "Modifier les détails de la carte",
          "deleteTitle": "Supprimer la carte",
          "deleteContent": "Voulez-vous vraiment supprimer cette carte ?",
          "save": "sauver",
          "delete": "Supprimer",
          "cancel": "Annuler",
          "yes": "Oui",
          "no": "Non",
          "close": "Fermer",
          "noCard": "Aucune carte à afficher",
          "unassigned": "Non attribué"
      },
      "dropdowns": {
          "noRecordsTemplate": "Aucun enregistrement trouvé",
          "actionFailureTemplate": "La demande a échoué",
          "overflowCountTemplate": "+${count} de plus ..",
          "selectAllText": "Tout sélectionner",
          "unSelectAllText": "Tout déselectionner",
          "totalCountTemplate": "${count} sélectionné"
      },
      "drop-down-list": {
          "noRecordsTemplate": "Aucun enregistrement trouvé",
          "actionFailureTemplate": "La demande a échoué"
      },
      "combo-box": {
          "noRecordsTemplate": "Aucun enregistrement trouvé",
          "actionFailureTemplate": "La demande a échoué"
      },
      "auto-complete": {
          "noRecordsTemplate": "Aucun enregistrement trouvé",
          "actionFailureTemplate": "La demande a échoué"
      },
      "multi-select": {
          "noRecordsTemplate": "Aucun enregistrement trouvé",
          "actionFailureTemplate": "La demande a échoué",
          "overflowCountTemplate": "+${count} de plus ..",
          "selectAllText": "Tout sélectionner",
          "unSelectAllText": "Tout déselectionner",
          "totalCountTemplate": "${count} sélectionné"
      },
      "listbox": {
          "noRecordsTemplate": "Aucun enregistrement trouvé",
          "actionFailureTemplate": "La demande a échoué",
          "selectAllText": "Tout sélectionner",
          "unSelectAllText": "Tout déselectionner",
          "moveUp": "Déplacer vers le haut",
          "moveDown": "Descendre",
          "moveTo": "Déménager à",
          "moveFrom": "Déplacer de",
          "moveAllTo": "Déplacer tout vers",
          "moveAllFrom": "Tout déplacer de"
      },
      "spreadsheet": {
          "InsertingEmptyValue": "La valeur de référence n'est pas valide.",
          "FindValue": "Trouver de la valeur",
          "ReplaceValue": "Remplacer la valeur",
          "FindReplaceTooltip": "Rechercher et remplacer",
          "ByRow": " Par lignes",
          "ByColumn": "Par colonnes",
          "MatchExactCellElements": "Faire correspondre le contenu exact des cellules",
          "EntercellAddress": "Entrez l'adresse de la cellule",
          "FindAndReplace": "Trouver et remplacer",
          "ReplaceAllEnd": " matches remplacés par",
          "FindNextBtn": "Find Next",
          "FindPreviousBtn": "Trouver précédent",
          "ReplaceBtn": "Remplacer",
          "ReplaceAllBtn": "Remplace tout",
          "GotoHeader": "Aller à",
          "GotoSpecialHeader": "Aller à spécial",
          "Sheet": "Feuille",
          "SearchWithin": "Rechercher dans",
          "SearchBy": "Recherché par",
          "Reference": "Référence",
          "Workbook": "Classeur",
          "NoElements": "Nous n'avons pas pu trouver ce que vous cherchiez. Cliquez sur les options pour plus de recherche",
          "FindWhat": "Trouver quoi",
          "ReplaceWith": "Remplacer par",
          "EnterValue": "Entrez une valeur",
          "FileName": "Nom de fichier",
          "EnterName": "Entrez le nom",
          "ExtendValidation": "La sélection contient des cellules sans validation des données. Voulez-vous étendre la validation à ces cellules ?",
          "Yes": "Oui",
          "No": "Non",
          "InvalidFileName": "Le nom de fichier contient un caractère invalide.",
          "PROPER": "Convertit un texte en casse appropriée ; la première lettre en majuscule et les autres lettres en minuscule.",
          "Cut": "Couper",
          "Copy": "Copie",
          "Paste": "Pâte",
          "PasteSpecial": "Collage spécial",
          "All": "Tout",
          "Values": "Valeurs",
          "Formats": "Les formats",
          "Font": "Police de caractère",
          "FontSize": "Taille de police",
          "Bold": "Audacieux",
          "Italic": "Italique",
          "Underline": "Souligner",
          "Strikethrough": "Barré",
          "TextColor": "Couleur du texte",
          "FillColor": "La couleur de remplissage",
          "HorizontalAlignment": "Alignement horizontal",
          "AlignLeft": "Alignez à gauche",
          "AlignCenter": "Centre",
          "AlignRight": "Aligner à droite",
          "VerticalAlignment": "Alignement vertical",
          "AlignTop": "Aligner en haut",
          "AlignMiddle": "Aligner au milieu",
          "AlignBottom": "Aligner en bas",
          "MergeCells": "Fusionner les cellules",
          "MergeAll": "Tout fusionner",
          "MergeHorizontally": "Fusionner horizontalement",
          "MergeVertically": "Fusionner verticalement",
          "Unmerge": "Unmerge",
          "UnmergeCells": "Dissocier les cellules",
          "SelectMergeType": "Sélectionnez le type de fusion",
          "MergeCellsAlert": "La fusion de cellules ne conservera que la valeur en haut à gauche (la plus haute). Fusionner quand même ?",
          "PasteMergeAlert": "Nous ne pouvons pas faire cela à une cellule de fusion.",
          "Borders": "Les frontières",
          "TopBorders": "Frontières supérieures",
          "LeftBorders": "Frontières gauches",
          "RightBorders": "Frontières droites",
          "BottomBorders": "Bordures inférieures",
          "AllBorders": "Toutes les frontières",
          "HorizontalBorders": "Bordures horizontales",
          "VerticalBorders": "Bordures verticales",
          "OutsideBorders": "Frontières extérieures",
          "InsideBorders": "Inside Borders",
          "NoBorders": "Pas de frontières",
          "BorderColor": "Couleur de la bordure",
          "BorderStyle": "Style de bordure",
          "InsertFunction": "Insérer une fonction",
          "Insert": "Insérer",
          "Delete": "Supprimer",
          "DuplicateSheet": "Dupliquer",
          "MoveRight": "Déplacer vers la droite",
          "MoveLeft": "Déplacer vers la gauche",
          "Rename": "Renommer",
          "Hide": "Cacher",
          "Unhide": "Afficher",
          "NameBox": "Boîte de nom",
          "ShowHeaders": "Afficher les en-têtes",
          "HideHeaders": "Masquer les en-têtes",
          "ShowGridLines": "Afficher le quadrillage",
          "HideGridLines": "Masquer le quadrillage",
          "FreezePanes": "Figer les volets",
          "FreezeRows": "Figer les lignes",
          "FreezeColumns": "Figer les colonnes",
          "UnfreezePanes": "Dégeler les volets",
          "UnfreezeRows": "Libérer les lignes",
          "UnfreezeColumns": "Dégeler les colonnes",
          "AddSheet": "Ajouter une feuille",
          "ListAllSheets": "Liste de toutes les feuilles",
          "FullScreen": "Plein écran",
          "CollapseToolbar": "Réduire la barre d'outils",
          "ExpandToolbar": "Développer la barre d'outils",
          "CollapseFormulaBar": "Réduire la barre de formule",
          "ExpandFormulaBar": "Développer la barre de formule",
          "File": "Fichier",
          "Home": "Accueil",
          "Formulas": "Formules",
          "View": "Vue",
          "New": "Nouveau",
          "Open": "Ouvert",
          "SaveAs": "Enregistrer sous",
          "ExcelXlsx": "Microsoft Excel",
          "ExcelXls": "Microsoft Excel 97-2003",
          "CSV": "Valeurs séparées par des virgules",
          "FormulaBar": "Barre de formule",
          "Sort": "Trier",
          "SortAscending": "Ascendant",
          "SortDescending": "Descendant",
          "CustomSort": "Tri personnalisé",
          "AddColumn": "Ajouter une colonne",
          "ContainsHeader": "Les données contiennent un en-tête",
          "CaseSensitive": "Sensible aux majuscules et minuscules",
          "SortBy": "Trier par",
          "ThenBy": "Puis par",
          "SelectAColumn": "Sélectionnez une colonne",
          "SortEmptyFieldError": "Tous les critères de tri doivent avoir une colonne spécifiée. Vérifiez les critères de tri sélectionnés et réessayez.",
          "SortDuplicateFieldError": " est trié par valeurs plusieurs fois. Supprimez les critères de tri en double et réessayez.",
          "SortOutOfRangeError": "Sélectionnez une cellule ou une plage dans la plage utilisée et réessayez.",
          "MultiRangeSortError": "Cela ne peut pas être fait sur une sélection de plages multiples. Sélectionnez une seule plage et réessayez.",
          "HideRow": "Masquer la ligne",
          "HideRows": "Masquer les lignes",
          "UnHideRows": "Afficher les lignes",
          "HideColumn": "Masquer la colonne",
          "HideColumns": "Masquer les colonnes",
          "UnHideColumns": "Afficher les colonnes",
          "InsertRow": "Insérer une ligne",
          "InsertRows": "Insérer des lignes",
          "Above": "Au dessus de",
          "Below": "Au dessous de",
          "InsertColumn": "Insérer une colonne",
          "InsertColumns": "Insérer des colonnes",
          "Before": "Avant",
          "After": "Après",
          "DeleteRow": "Supprimer la ligne",
          "DeleteRows": "Supprimer des lignes",
          "DeleteColumn": "Supprimer la colonne",
          "DeleteColumns": "Supprimer les colonnes",
          "Ok": "D'accord",
          "Close": "Fermer",
          "Cancel": "Annuler",
          "Apply": "Appliquer",
          "MoreColors": "Plus de couleurs",
          "StandardColors": "Couleurs standard",
          "General": "Général",
          "Number": "Nombre",
          "Currency": "Devise",
          "Accounting": "Comptabilité",
          "ShortDate": "Date courte",
          "LongDate": "Date longue",
          "Time": "Temps",
          "Percentage": "Pourcentage",
          "Fraction": "Fraction",
          "Scientific": "Scientifique",
          "Text": "Texte",
          "NumberFormat": "Format de nombre",
          "MobileFormulaBarPlaceHolder": "Entrez une valeur ou une formule",
          "PasteAlert": "Vous ne pouvez pas le coller ici, car la zone de copie et la zone de collage ne sont pas de la même taille. Veuillez essayer de coller dans une plage différente.",
          "DestroyAlert": "Voulez-vous vraiment détruire le classeur actuel sans l'enregistrer et créer un nouveau classeur ?",
          "SheetRenameInvalidAlert": "Le nom de la feuille contient un caractère non valide.",
          "SheetRenameEmptyAlert": "Le nom de la feuille ne peut pas être vide.",
          "SheetRenameAlreadyExistsAlert": "Le nom de la feuille existe déjà. Veuillez saisir un autre nom.",
          "DeleteSheetAlert": "Voulez-vous vraiment supprimer cette feuille ?",
          "DeleteSingleLastSheetAlert": "Un classeur doit contenir au moins une feuille de calcul visible.",
          "PickACategory": "Choisissez une catégorie",
          "Description": "La description",
          "UnsupportedFile": "Fichier non supporté",
          "InvalidUrl": "URL invalide",
          "SUM": "Ajoute une série de nombres et / ou de cellules.",
          "SUMIF": "Ajoute les cellules en fonction de la condition spécifiée.",
          "SUMIFS": "Ajoute les cellules en fonction des conditions spécifiées.",
          "ABS": "Renvoie la valeur d'un nombre sans son signe.",
          "RAND": "Renvoie un nombre aléatoire compris entre 0 et 1.",
          "RANDBETWEEN": "Renvoie un entier aléatoire basé sur des valeurs spécifiées.",
          "FLOOR": "Arrondit un nombre au multiple le plus proche d'un facteur donné.",
          "CEILING": "Arrondit un nombre au multiple le plus proche d'un facteur donné.",
          "PRODUCT": "Multiplie une série de nombres et / ou de cellules.",
          "AVERAGE": "Calcule la moyenne de la série de nombres et / ou de cellules hors texte.",
          "AVERAGEIF": "Calcule la moyenne des cellules en fonction du critère spécifié.",
          "AVERAGEIFS": "Calcule la moyenne des cellules en fonction des conditions spécifiées.",
          "AVERAGEA": "Calcule la moyenne des cellules évaluant VRAI comme 1, texte et FAUX comme 0.",
          "COUNT": "Compte les cellules qui contiennent des valeurs numériques dans une plage.",
          "COUNTIF": "Compte les cellules en fonction de la condition spécifiée.",
          "COUNTIFS": "Compte les cellules en fonction des conditions spécifiées.",
          "COUNTA": "Compte les cellules qui contiennent des valeurs dans une plage.",
          "MIN": "Renvoie le plus petit nombre des arguments donnés.",
          "MAX": "Renvoie le plus grand nombre d'arguments donnés.",
          "DATE": "Renvoie la date en fonction de l'année, du mois et du jour donnés.",
          "DAY": "Renvoie le jour à partir de la date donnée.",
          "DAYS": "Renvoie le nombre de jours entre deux dates.",
          "IF": "Renvoie une valeur basée sur l'expression donnée.",
          "IFS": "Renvoie une valeur basée sur les expressions multiples données.",
          "CalculateAND": "Renvoie TRUE si tous les arguments sont TRUE, sinon renvoie FALSE.",
          "CalculateOR": "Renvoie VRAI si l'un des arguments est VRAI, sinon renvoie FAUX.",
          "IFERROR": "Renvoie une valeur si aucune erreur n'a été trouvée sinon, elle renverra la valeur spécifiée.",
          "CHOOSE": "Renvoie une valeur de la liste de valeurs, basée sur le numéro d'index.",
          "INDEX": "Renvoie une valeur de la cellule dans une plage donnée en fonction du numéro de ligne et de colonne.",
          "FIND": "Renvoie la position d'une chaîne dans une autre chaîne, sensible à la casse",
          "CONCATENATE": "Combine deux chaînes ou plus ensemble.",
          "CONCAT": "Concatène une liste ou une plage de chaînes de texte.",
          "SUBTOTAL": "Renvoie le sous-total d'une plage à l'aide du numéro de fonction donné.",
          "RADIANS": "Convertit les degrés en radians.",
          "MATCH": "Renvoie la position relative d'une valeur spécifiée dans une plage donnée.",
          "SLOPE": "Renvoie la pente de la ligne à partir de la régression linéaire des points de données.",
          "INTERCEPT": "Calcule le point de la ligne d'interception Y via une régression linéaire.",
          "UNIQUE": "Renvoie des valeurs uniques à partir d'une plage ou d'un tableau",
          "TEXT": "Convertit une valeur en texte au format numérique spécifié.",
          "DefineNameExists": "Ce nom existe déjà, essayez un autre nom.",
          "CircularReference": "Lorsqu'une formule fait référence à une ou plusieurs références circulaires, cela peut entraîner un calcul incorrect.",
          "SORT": "Trie une plage d'un tableau",
          "T": "Vérifie si une valeur est du texte ou non et renvoie le texte.",
          "EXACT": "Vérifie si deux chaînes de texte sont exactement les mêmes et renvoie VRAI ou FAUX.",
          "LEN": "Renvoie un nombre de caractères dans une chaîne donnée.",
          "MOD": "Renvoie un reste après qu'un nombre est divisé par un diviseur.",
          "ODD": "Arrondit un nombre positif vers le haut et un nombre négatif vers le bas à l'entier impair le plus proche.",
          "PI": "Renvoie la valeur de pi.",
          "COUNTBLANK": "Renvoie le nombre de cellules vides dans une plage de cellules spécifiée.",
          "EVEN": "Arrondit un nombre positif vers le haut et un nombre négatif vers le bas à l'entier pair le plus proche.",
          "DECIMAL": "Convertit une représentation textuelle d'un nombre dans une base donnée en un nombre décimal.",
          "ADDRESS": "Renvoie une référence de cellule sous forme de texte, en fonction des numéros de ligne et de colonne spécifiés.",
          "CHAR": "Renvoie le caractère du nombre spécifié.",
          "CODE": "Renvoie le code numérique du premier caractère d'une chaîne donnée.",
          "DOLLAR": "Convertit le nombre en texte au format monétaire.",
          "SMALL": "Renvoie la k-ième plus petite valeur dans un tableau donné.",
          "LARGE": "Renvoie la k-ième plus grande valeur dans un tableau donné.",
          "TIME": "Convertit les heures, minutes, secondes en texte formaté par heure.",
          "DEGREES": "Convertit les radians en degrés.",
          "FACT": "Renvoie la factorielle d'un nombre.",
          "MEDIAN": "Renvoie la médiane de l'ensemble de nombres donné.",
          "EDATE": "Renvoie une date avec un nombre donné de mois avant ou après la date spécifiée.",
          "DATEVALUE": "Convertit une chaîne de date en valeur de date.",
          "NOW": "Renvoie la date et l'heure actuelles.",
          "HOUR": "Renvoie le nombre d'heures dans une chaîne d'heure spécifiée.",
          "MINUTE": "Renvoie le nombre de minutes dans une chaîne d'heure spécifiée.",
          "SECOND": "Renvoie le nombre de secondes dans une chaîne de temps spécifiée.",
          "MONTH": "Renvoie le nombre de mois dans une chaîne de date spécifiée.",
          "ShowRowsWhere": "Afficher les lignes où:",
          "OR": "OU",
          "AND": "ET",
          "CustomFilterDatePlaceHolder": "Choisissez une date",
          "CustomFilterPlaceHolder": "Entrez la valeur",
          "CustomFilter": "Filtre personnalisé",
          "Between": "Entre",
          "MatchCase": "Cas de correspondance",
          "DateTimeFilter": "Filtres DateTime",
          "Undo": "annuler",
          "Redo": "Refaire",
          "ClearAllFilter": "Clair",
          "ReapplyFilter": "Réappliquer",
          "DateFilter": "Filtres de date",
          "TextFilter": "Filtres de texte",
          "NumberFilter": "Filtres numériques",
          "ClearFilter": "Effacer le filtre",
          "NoResult": "Aucun résultat",
          "FilterFalse": "Faux",
          "FilterTrue": "Vrai",
          "Blanks": "Blancs",
          "SelectAll": "Tout sélectionner",
          "GreaterThanOrEqual": "Meilleur que ou égal",
          "GreaterThan": "Plus grand que",
          "LessThanOrEqual": "Inférieur ou égal",
          "LessThan": "Moins que",
          "NotEqual": "Inégal",
          "Equal": "Égal",
          "Contains": "Contient",
          "EndsWith": "Se termine par",
          "StartsWith": "Commence avec",
          "ClearButton": "Clair",
          "FilterButton": "Filtre",
          "CancelButton": "Annuler",
          "OKButton": "D'accord",
          "Search": "Chercher",
          "DataValidation": "La validation des données",
          "CLEARALL": "TOUT EFFACER",
          "APPLY": "APPLIQUER",
          "CellRange": "Gamme de cellules",
          "Allow": "Autoriser",
          "Data": "Les données",
          "Minimum": "Le minimum",
          "Maximum": "Maximum",
          "IgnoreBlank": "Ignorer le blanc",
          "WholeNumber": "Nombre entier",
          "Decimal": "Décimal",
          "Date": "Date",
          "TextLength": "Longueur du texte",
          "List": "liste",
          "NotBetween": "Pas entre",
          "EqualTo": "Égal à",
          "NotEqualTo": "Pas égal à",
          "Greaterthan": "Plus grand que",
          "Lessthan": "Moins que",
          "GreaterThanOrEqualTo": "Plus grand ou égal à",
          "LessThanOrEqualTo": "Inférieur ou égal à",
          "InCellDropDown": "Liste déroulante dans la cellule",
          "Sources": "Sources",
          "Value": "Valeur",
          "Retry": "Réessayez",
          "DialogError": "La source de la liste doit être une référence à une seule ligne ou colonne.",
          "MinMaxError": "Le Maximum doit être supérieur ou égal au Minimum.",
          "Spreadsheet": "Tableur",
          "MoreValidation": "Cette sélection contient plus d'une validation.",
          "FileNameError": " Effacer les paramètres actuels et continuer ?",
          "ValidationError": "Cette valeur ne correspond pas aux restrictions de validation des données définies pour la cellule.",
          "ListLengthError": "Les valeurs de la liste ne permettent que jusqu'à 256 caractères",
          "ProtectSheet": "Feuille de protection",
          "UnprotectSheet": "Déprotéger la feuille",
          "SelectCells": "Sélectionnez les cellules",
          "SelectUnLockedCells": "Sélectionner des cellules",
          "Save": "Sélectionner des cellules",
          "EmptyFileName": "Sauvegarder",
          "LargeName": "Le nom du fichier ne peut pas être vide.",
          "FormatCells": "Formater les cellules",
          "FormatRows": "Mettre en forme les lignes",
          "Format Columns": "Format des colonnes",
          "InsertLinks": "Insérer des liens",
          "ProtectContent": "Protéger le contenu des cellules verrouillées",
          "ProtectAllowUser": "Autorisez tous les utilisateurs de cette feuille de calcul à:",
          "EditAlert": "La cellule que vous essayez de changer est protégée. Pour apporter des modifications, déprotégez la feuille.",
          "ClearValidation": "Validation claire",
          "ISNUMBER": "Renvoie true lorsque la valeur est analysée en tant que valeur numérique.",
          "Round": "Arrondit un nombre à un nombre spécifié de chiffres.",
          "GEOMEAN": "Renvoie la moyenne géométrique d'un tableau ou d'une plage de données positives.",
          "POWER": "Renvoie le résultat d'un nombre élevé à la puissance",
          "LOG": "Renvoie le logarithme d'un nombre à la base que vous spécifiez.",
          "TRUNC": "Renvoie la valeur tronquée d'un nombre à un nombre spécifié de décimales.",
          "EXP": "Renvoie e élevé à la puissance du nombre donné.",
          "HighlightCellsRules": "Mettre en évidence les règles des cellules",
          "CFEqualTo": "Égal à",
          "TextThatContains": "Texte qui contient",
          "ADateOccuring": "Une date survenant",
          "DuplicateValues": "Dupliquer les valeurs",
          "TopBottomRules": "Règles haut / bas",
          "Top10Items": "Top 10 des articles",
          "Top10": "Top 10",
          "Bottom10Items": "10 derniers articles",
          "Bottom10": "10 bas",
          "AboveAverage": "Au dessus de la moyenne",
          "BelowAverage": "Sous la moyenne",
          "FormatCellsGreaterThan": "Mettre en forme des cellules PLUS GRANDES QUE:",
          "FormatCellsLessThan": "Mettre en forme les cellules inférieures à:",
          "FormatCellsBetween": "Mettre en forme les cellules qui sont ENTRE:",
          "FormatCellsEqualTo": "Mettre en forme les cellules ÉGALES À:",
          "FormatCellsThatContainTheText": "Mettre en forme les cellules contenant le texte:",
          "FormatCellsThatContainADateOccurring": "Mettre en forme les cellules contenant une date:",
          "FormatCellsDuplicate": "Mettre en forme les cellules qui contiennent:",
          "FormatCellsTop": "Mettre en forme les cellules qui se classent dans le TOP:",
          "FormatCellsBottom": "Mettez en forme les cellules qui se classent dans le BAS:",
          "FormatCellsAbove": "Mettre en forme les cellules supérieures à la moyenne:",
          "FormatCellsBelow": "Mettre en forme les cellules EN-DESSOUS DE LA MOYENNE:",
          "With": "avec",
          "DataBars": "Barres de données",
          "ColorScales": "Échelles de couleurs",
          "IconSets": "Jeux d'icônes",
          "ClearRules": "Effacer les règles",
          "SelectedCells": "Effacer les règles des cellules sélectionnées",
          "EntireSheet": "Effacer les règles de la feuille entière",
          "LightRedFillWithDarkRedText": "Remplissage rouge clair avec texte rouge foncé",
          "YellowFillWithDarkYellowText": "Remplissage jaune avec texte jaune foncé",
          "GreenFillWithDarkGreenText": "Remplissage vert avec texte vert foncé",
          "RedFill": "Remplissage rouge",
          "RedText": "Texte rouge",
          "Duplicate": "Dupliquer",
          "Unique": "Unique",
          "And": "et",
          "WebPage": "PAGE WEB",
          "ThisDocument": "CE DOCUMENT",
          "DisplayText": "Afficher le texte",
          "Url": "URL",
          "CellReference": "Référence de cellule",
          "DefinedNames": "Noms définis",
          "EnterTheTextToDisplay": "Entrez le texte à afficher",
          "EnterTheUrl": "Entrez l'URL",
          "INT": "Renvoie un nombre à l'entier le plus proche.",
          "SUMPRODUCT": "Renvoie la somme du produit de plages de tableaux données.",
          "TODAY": "Renvoie la date actuelle sous forme de valeur de date.",
          "ROUNDUP": "Arrondit un nombre à partir de zéro.",
          "Replace": "Remplacer...",
          "Find": "Trouver et remplacer...",
          "Goto": "Aller à...",
          "GotoSpecial": "Aller à Spécial ...",
          "Link": "Lien",
          "SpreadsheetHyperlink": "Feuille de calculHyperlink",
          "Hyperlink": "Hyperlien",
          "EditHyperlink": "Modifier l'hyperlien",
          "OpenHyperlink": "Ouvrir le lien hypertexte",
          "RemoveHyperlink": "Supprimer l'hyperlien",
          "InvalidHyperlinkAlert": "L'adresse de ce site n'est pas valide. Vérifie l'adresse et essaye de nouveau.",
          "InsertLink": "Insérer un lien",
          "EditLink": "Modifier le lien",
          "WrapText": "Envelopper le texte",
          "Update": "Mettre à jour",
          "SortAndFilter": "Trier et filtrer",
          "Filter": "Filtre",
          "FilterCellValue": "Filtrer par valeur de la cellule sélectionnée",
          "FilterOutOfRangeError": "Sélectionnez une cellule ou une plage dans la plage utilisée et réessayez.",
          "ClearFilterFrom": "Effacer le filtre de",
          "LN": "Renvoie le logarithme naturel d'un nombre.",
          "DefineNameInValid": "Le nom que vous avez entré n'est pas valide.",
          "LockCells": "Verrouiller les cellules",
          "EmptyError": "Vous devez saisir une valeur",
          "ClearHighlight": "Effacer la surbrillance",
          "HighlightInvalidData": "Mettre en évidence les données non valides",
          "Clear": "Clair",
          "ClearContents": "Effacer le contenu",
          "ClearAll": "Tout effacer",
          "ClearFormats": "Effacer les formats",
          "ClearHyperlinks": "Effacer les hyperliens",
          "Image": "Image",
          "ConditionalFormatting": "Mise en forme conditionnelle",
          "BlueDataBar": "Barre de données bleue",
          "GreenDataBar": "Barre de données verte",
          "RedDataBar": "Barre de données rouge",
          "OrangeDataBar": "Barre de données orange",
          "LightblueDataBar": "Barre de données bleu clair",
          "PurpleDataBar": "Barre de données violette",
          "GYRColorScale": "Échelle de couleur verte - jaune - rouge",
          "RYGColorScale": "Échelle de couleur rouge - jaune - verte",
          "GWRColorScale": "Échelle de couleur verte - blanche - rouge",
          "RWGColorScale": "Échelle de couleur rouge - blanc - vert",
          "BWRColorScale": "Échelle de couleur bleu - blanc - rouge",
          "RWBColorScale": "Échelle de couleur rouge - blanc - bleu",
          "WRColorScale": "Blanc - Échelle de couleur rouge",
          "RWColorScale": "Rouge - Échelle de couleur blanche",
          "GWColorScale": "Échelle de couleur verte - blanche",
          "WGColorScale": "Échelle de couleur blanche - verte",
          "GYColorScale": "Échelle de couleur verte - jaune",
          "YGColorScale": "Échelle de couleur jaune - verte",
          "ThreeArrowsColor": "3 flèches (colorées)",
          "ThreeArrowsGray": "3 flèches (grises)",
          "ThreeTriangles": "3 triangles",
          "FourArrowsColor": "4 flèches (grises)",
          "FourArrowsGray": "4 flèches (colorées)",
          "FiveArrowsColor": "5 flèches (grises)",
          "FiveArrowsGray": "5 flèches (colorées)",
          "ThreeTrafficLights1": "3 feux de signalisation (non garnis)",
          "ThreeTrafficLights2": "3 feux de signalisation (cerclés)",
          "ThreeSigns": "3 signes",
          "FourTrafficLights": "4 feux de signalisation",
          "RedToBlack": "Du rouge au noir",
          "ThreeSymbols1": "3 symboles (cerclés)",
          "ThreeSymbols2": "3 symboles (non cerclés)",
          "ThreeFlags": "3 drapeaux",
          "ThreeStars": "3 étoiles",
          "FourRatings": "4 évaluations",
          "FiveQuarters": "5 trimestres",
          "FiveRatings": "5 évaluations",
          "FiveBoxes": "5 boîtes",
          "Chart": "Graphique",
          "Column": "Colonne",
          "Bar": "Bar",
          "Area": "Région",
          "Pie": "Tarte",
          "Doughnut": "Donut",
          "PieAndDoughnut": "Tarte / Donut",
          "Line": "Ligne",
          "Radar": "Radar",
          "Scatter": "Dispersion",
          "ChartDesign": "Conception graphique",
          "ClusteredColumn": "Colonne groupée",
          "StackedColumn": "Colonne empilée",
          "StackedColumn100": "Colonne 100% empilée",
          "ClusteredBar": "Barre groupée",
          "StackedBar": "Barre empilée",
          "StackedBar100": "Barre 100% empilée",
          "StackedArea": "Zone empilée",
          "StackedArea100": "Zone 100% empilée",
          "StackedLine": "Ligne empilée",
          "StackedLine100": "Ligne 100% empilée",
          "AddChartElement": "Ajouter un élément de graphique",
          "Axes": "Haches",
          "AxisTitle": "Titre de l'axe",
          "ChartTitle": "Titre du graphique",
          "DataLabels": "Étiquettes de données",
          "Gridlines": "Quadrillage",
          "Legends": "Légendes",
          "PrimaryHorizontal": "Horizontal primaire",
          "PrimaryVertical": "Verticale primaire",
          "None": "Aucun",
          "AboveChart": "Au-dessus du graphique",
          "Center": "Centre",
          "InsideEnd": "Extrémité intérieure",
          "InsideBase": "Base intérieure",
          "OutsideEnd": "OutSide Fin",
          "PrimaryMajorHorizontal": "Principal principal horizontal",
          "PrimaryMajorVertical": "Verticale principale principale",
          "PrimaryMinorHorizontal": "Mineur principal horizontal",
          "PrimaryMinorVertical": "Verticale mineure primaire",
          "Right": "Droite",
          "Left": "La gauche",
          "Bottom": "Bas",
          "Top": "Haut",
          "SwitchRowColumn": "Changer de ligne / colonne",
          "ChartTheme": "Thème du graphique",
          "ChartType": "Type de graphique",
          "Material": "Matériel",
          "Fabric": "en tissu",
          "Bootstrap": "Amorcer",
          "HighContrastLight": "HighContrastLight",
          "MaterialDark": "MatérielSombre",
          "FabricDark": "TissuSombre",
          "HighContrast": "Contraste élevé",
          "BootstrapDark": "BootstrapDark",
          "Bootstrap4": "Bootstrap4",
          "Bootstrap5Dark": "Bootstrap4",
          "Bootstrap5": "Bootstrap5 Sombre",
          "Tailwind": "Bootstrap5",
          "TailwindDark": "Vent arrière",
          "VerticalAxisTitle": "Titre de l'axe vertical",
          "HorizontalAxisTitle": "Titre de l'axe horizontal",
          "EnterTitle": "Entrez le titre",
          "UnProtectWorksheet": "Entrez le titre",
          "ReEnterPassword": "Déprotéger la feuille",
          "SheetPassword": "Entrez à nouveau le mot de passe pour continuer",
          "ProtectWorkbook": "Protéger le classeur",
          "Password": "Mot de passe (facultatif):",
          "unProtectPassword": "Mot de passe",
          "EnterThePassword": "Entrer le mot de passe",
          "ConfirmPassword": "Confirmez le mot de passe",
          "EnterTheConfirmPassword": "Entrez à nouveau votre mot de passe",
          "PasswordAlert": "Le mot de passe de confirmation n'est pas identique",
          "UnProtectWorkbook": "Déprotéger le classeur",
          "UnProtectPasswordAlert": "Le mot de passe que vous avez fourni n'est pas correct.",
          "InCorrectPassword": "Impossible d'ouvrir le fichier ou la feuille de calcul avec le mot de passe donné",
          "PasswordAlertMsg": "S'il vous plaît entrer le mot de passe",
          "ConfirmPasswordAlertMsg": "Veuillez saisir le mot de passe de confirmation",
          "IsProtected": "est protégé",
          "PDF": "Portable Document Format",
          "AutoFillMergeAlertMsg": "Portable Document Format",
          "SelectBorderOption": "Sélectionnez l'option de bordure",
          "ok": "Valider"
      },
      "filemanager": {
          "NewFolder": "Nouveau dossier",
          "Upload": "Télécharger",
          "Delete": "Supprimer",
          "Rename": "Renommer",
          "Download": "Télécharger",
          "Cut": "Couper",
          "Copy": "Copie",
          "Paste": "Pâte",
          "SortBy": "Trier par",
          "Refresh": "Rafraîchir",
          "Item-Selection": "élément sélectionné",
          "Items-Selection": "éléments sélectionnés",
          "View": "Vue",
          "Details": "Détails",
          "SelectAll": "Tout sélectionner",
          "Open": "Ouvert",
          "Tooltip-NewFolder": "Nouveau dossier",
          "Tooltip-Upload": "Télécharger",
          "Tooltip-Delete": "Supprimer",
          "Tooltip-Rename": "Renommer",
          "Tooltip-Download": "Télécharger",
          "Tooltip-Cut": "Couper",
          "Tooltip-Copy": "Copie",
          "Tooltip-Paste": "Pâte",
          "Tooltip-SortBy": "Trier par",
          "Tooltip-Refresh": "Rafraîchir",
          "Tooltip-Selection": "Effacer la sélection",
          "Tooltip-View": "Vue",
          "Tooltip-Details": "Détails",
          "Tooltip-SelectAll": "Tout sélectionner",
          "Name": "Nom",
          "Size": "Taille",
          "DateModified": "Modifié",
          "DateCreated": "Date créée",
          "Path": "Chemin",
          "Modified": "Modifié",
          "Created": "Créé",
          "Location": "Emplacement",
          "Type": "Type",
          "Permission": "Autorisation",
          "Ascending": "Ascendant",
          "Descending": "Descendant",
          "None": "Aucun",
          "View-LargeIcons": "Grandes icônes",
          "View-Details": "Détails",
          "Search": "Chercher",
          "Button-Ok": "D'accord",
          "Button-Cancel": "Annuler",
          "Button-Yes": "Oui",
          "Button-No": "Non",
          "Button-Create": "Créer",
          "Button-Save": "Sauvegarder",
          "Header-NewFolder": "Dossier",
          "Content-NewFolder": "Entrez le nom de votre dossier",
          "Header-Rename": "Renommer",
          "Content-Rename": "Entrez votre nouveau nom",
          "Header-Rename-Confirmation": "Renommer la confirmation",
          "Content-Rename-Confirmation": "Si vous modifiez une extension de nom de fichier, le fichier peut devenir instable. Voulez-vous vraiment le modifier ?",
          "Header-Delete": "Supprimer le fichier",
          "Content-Delete": "Voulez-vous vraiment supprimer ce fichier ?",
          "Header-Folder-Delete": "Supprimer le dossier",
          "Content-Folder-Delete": "Êtes-vous sûr de vouloir supprimer ce dossier?",
          "Header-Multiple-Delete": "Supprimer plusieurs fichiers",
          "Content-Multiple-Delete": "Voulez-vous vraiment supprimer ces {0} fichiers ?",
          "Header-Duplicate": "Le fichier / dossier existe",
          "Content-Duplicate": "{0} existe déjà. Voulez-vous renommer et coller ?",
          "Header-Upload": "Télécharger des fichiers",
          "Error": "Erreur",
          "Validation-Empty": "Le nom du fichier ou du dossier ne peut pas être vide.",
          "Validation-Invalid": "Le nom de fichier ou de dossier {0} contient des caractères non valides. Veuillez utiliser un nom différent. Les noms de fichiers ou de dossiers valides ne peuvent pas se terminer par un point ou un espace et ne peuvent contenir aucun des caractères suivants: \\ /: *? \"<> |",
          "Validation-NewFolder-Exists": "Un fichier ou un dossier portant le nom {0} existe déjà.",
          "Validation-Rename-Exists": "Impossible de renommer {0} en {1}: la destination existe déjà.",
          "Folder-Empty": "Ce dossier est vide",
          "File-Upload": "Faites glisser les fichiers ici pour les télécharger",
          "Search-Empty": "Aucun résultat trouvé",
          "Search-Key": "Essayez avec différents mots clés",
          "Filter-Empty": "Aucun résultat trouvé",
          "Filter-Key": "Essayez avec un filtre différent",
          "Sub-Folder-Error": "Le dossier de destination est le sous-dossier du dossier source.",
          "Same-Folder-Error": "Le dossier de destination est le même que le dossier source.",
          "Access-Denied": "Accès refusé",
          "Access-Details": "Vous n'êtes pas autorisé à accéder à ce dossier.",
          "Header-Retry": "Le fichier existe déjà",
          "Content-Retry": "Un fichier portant ce nom existe déjà dans ce dossier. Qu'est-ce que tu aimerais faire?",
          "Button-Keep-Both": "Garde les deux",
          "Button-Replace": "Remplacer",
          "Button-Skip": "Sauter",
          "ApplyAll-Label": "Faites ceci pour tous les articles actuels",
          "KB": "Ko",
          "Access-Message": "{0} n'est pas accessible. Vous devez être autorisé à effectuer l'action {1}.",
          "Network-Error": "NetworkError: échec d'envoi sur XMLHTTPRequest: échec de chargement",
          "Server-Error": "ServerError: réponse non valide de"
      },
      "calendar": {
          "today": "Aujourd'hui"
      },
      "datepicker": {
          "today": "Aujourd'hui",
          "placeholder": "Choisissez une date"
      },
      "daterangepicker": {
          "placeholder": "Choisissez une plage de dates",
          "startLabel": "Date de début",
          "endLabel": "Date de fin",
          "applyText": "Appliquer",
          "cancelText": "Annuler",
          "selectedDays": "Jours sélectionnés",
          "days": "Journées",
          "customRange": "Période personnalisée"
      },
      "timepicker": {
          "placeholder": "Choisissez une heure"
      },
      "datetimepicker": {
          "today": "Aujourd'hui",
          "placeholder": "Choisissez une date et une heure"
      },
      "dialog": {
          "close": "Fermer"
      },
      "inplaceeditor": {
          "save": "Sauvegarder",
          "cancel": "Annuler",
          "loadingText": "Chargement...",
          "editIcon": "Cliquez pour modifier",
          "editAreaClick": "Cliquez pour modifier",
          "editAreaDoubleClick": "Double-cliquez pour modifier"
      },
      "toast": {
          "close": "Fermer"
      },
      "tab": {
          "closeButtonTitle": "Fermer"
      },
      "schedule": {
          "day": "Journée",
          "week": "Semaine",
          "workWeek": "Semaine de travail",
          "month": "Mois",
          "year": "An",
          "agenda": "Agenda",
          "weekAgenda": "Agenda de la semaine",
          "workWeekAgenda": "Agenda de la semaine de travail",
          "monthAgenda": "Agenda du mois",
          "today": "Aujourd'hui",
          "noEvents": "Pas d'événements",
          "emptyContainer": "Aucun événement n'est prévu ce jour-là.",
          "allDay": "Toute la journée",
          "start": "Début",
          "end": "Fin",
          "more": "plus",
          "close": "Fermer",
          "cancel": "Annuler",
          "noTitle": "(Pas de titre)",
          "delete": "Supprimer",
          "deleteEvent": "Cet evènement",
          "deleteMultipleEvent": "Supprimer plusieurs événements",
          "selectedItems": "Articles sélectionnés",
          "deleteSeries": "Série entière",
          "edit": "Éditer",
          "editSeries": "Série entière",
          "editEvent": "Cet evènement",
          "createEvent": "Créer",
          "subject": "Matière",
          "addTitle": "Ajouter un titre",
          "moreDetails": "Plus de détails",
          "save": "Sauvegarder",
          "editContent": "Comment aimeriez-vous changer le rendez-vous de la série ?",
          "deleteContent": "Voulez-vous vraiment supprimer cet événement ?",
          "deleteMultipleContent": "Voulez-vous vraiment supprimer les événements sélectionnés ?",
          "newEvent": "Nouvel évènement",
          "title": "Titre",
          "location": "Emplacement",
          "description": "La description",
          "timezone": "Fuseau horaire",
          "startTimezone": "Démarrer le fuseau horaire",
          "endTimezone": "Fin du fuseau horaire",
          "repeat": "Répéter",
          "saveButton": "Sauvegarder",
          "cancelButton": "Annuler",
          "deleteButton": "Supprimer",
          "recurrence": "Récurrence",
          "wrongPattern": "Le modèle de récurrence n'est pas valide.",
          "seriesChangeAlert": "Voulez-vous annuler les modifications apportées à des instances spécifiques de cette série et les associer à nouveau à l'ensemble de la série ?",
          "createError": "La durée de l'événement doit être plus courte que sa fréquence. Raccourcissez la durée ou modifiez le modèle de récurrence dans l'éditeur d'événements de récurrence.",
          "sameDayAlert": "Deux occurrences du même événement ne peuvent pas se produire le même jour.",
          "occurenceAlert": "Impossible de replanifier une occurrence du rendez-vous périodique s'il ignore une occurrence ultérieure du même rendez-vous.",
          "editRecurrence": "Modifier la récurrence",
          "repeats": "Répète",
          "alert": "Alerte",
          "startEndError": "La date de fin sélectionnée se produit avant la date de début.",
          "invalidDateError": "La valeur de date entrée n'est pas valide.",
          "blockAlert": "Les événements ne peuvent pas être planifiés dans l'intervalle de temps bloqué.",
          "ok": "Valider",
          "yes": "Oui",
          "no": "Non",
          "occurrence": "Occurrence",
          "series": "Séries",
          "previous": "précédent",
          "next": "Prochain",
          "timelineDay": "Jour de la chronologie",
          "timelineWeek": "Semaine chronologique",
          "timelineWorkWeek": "Semaine de travail",
          "timelineMonth": "Mois de la chronologie",
          "timelineYear": "Année de la chronologie",
          "editFollowingEvent": "Événements suivants",
          "deleteTitle": "Supprimer l'événement",
          "editTitle": "Modifier l'événement",
          "beginFrom": "Commencer à partir de",
          "endAt": "Fin à",
          "expandAllDaySection": "Développer la section toute la journée",
          "collapseAllDaySection": "Réduire la section toute la journée",
          "searchTimezone": "Recherche de fuseau horaire",
          "noRecords": "Aucun enregistrement trouvé"
      },
      "recurrenceeditor": {
          "none": "Aucun",
          "daily": "Quotidien",
          "weekly": "Hebdomadaire",
          "monthly": "Mensuel",
          "month": "Mois",
          "yearly": "Annuel",
          "never": "Jamais",
          "until": "Jusqu'à",
          "count": "Compter",
          "first": "Premier",
          "second": "Seconde",
          "third": "Troisième",
          "fourth": "Quatrième",
          "last": "Dernier",
          "repeat": "Répéter",
          "repeatEvery": "Répétez tous les",
          "on": "Répéter sur",
          "end": "Fin",
          "onDay": "journée",
          "days": "Journées)",
          "weeks": "Semaines)",
          "months": "Mois)",
          "years": "Ans)",
          "every": "chaque",
          "summaryTimes": "fois)",
          "summaryOn": "sur",
          "summaryUntil": "jusqu'à",
          "summaryRepeat": "Répète",
          "summaryDay": "journées)",
          "summaryWeek": "semaines)",
          "summaryMonth": "mois)",
          "summaryYear": "ans)",
          "monthWeek": "Mois Semaine",
          "monthPosition": "Position du mois",
          "monthExpander": "Expander mois",
          "yearExpander": "Expander année",
          "repeatInterval": "Intervalle de répétition"
      },
      "gantt": {
          "emptyRecord": "Aucun enregistrement à afficher",
          "id": "Identifiant",
          "name": "Nom",
          "startDate": "Date de début",
          "endDate": "Date de fin",
          "duration": "Durée",
          "progress": "Le progrès",
          "dependency": "Dépendance",
          "notes": "Remarques",
          "baselineStartDate": "Date de début de référence",
          "baselineEndDate": "Date de fin de référence",
          "taskMode": "Mode tâche",
          "changeScheduleMode": "Changer le mode de programmation",
          "subTasksStartDate": "Date de début des sous-tâches",
          "subTasksEndDate": "Date de fin des sous-tâches",
          "scheduleStartDate": "Date de début du programme",
          "scheduleEndDate": "Date de fin du programme",
          "auto": "Auto",
          "manual": "Manuel",
          "type": "Type",
          "offset": "Décalage",
          "resourceName": "Ressources",
          "resourceID": "ID de ressource",
          "day": "Journée",
          "hour": "heure",
          "minute": "minute",
          "days": "journées",
          "hours": "heures",
          "minutes": "minutes",
          "generalTab": "Général",
          "customTab": "Colonnes personnalisées",
          "writeNotes": "Rédiger des notes",
          "addDialogTitle": "Nouvelle tâche",
          "editDialogTitle": "Informations sur la tâche",
          "saveButton": "Sauvegarder",
          "add": "Ajouter",
          "edit": "Éditer",
          "update": "Mise à jour",
          "delete": "Supprimer",
          "cancel": "Annuler",
          "search": "Chercher",
          "task": " tâche",
          "tasks": " Tâches",
          "zoomIn": "Agrandir",
          "zoomOut": "Dézoomer",
          "zoomToFit": "Zoom pour s'adapter",
          "excelExport": "Exportation Excel",
          "csvExport": "Exportation CSV",
          "expandAll": "Développer tout",
          "collapseAll": "Réduire tout",
          "nextTimeSpan": "Période suivante",
          "prevTimeSpan": "Période précédente",
          "okText": "D'accord",
          "confirmDelete": "Voulez-vous vraiment supprimer l'enregistrement ?",
          "from": "De",
          "to": "À",
          "taskLink": "Lien de tâche",
          "lag": "Décalage",
          "start": "Début",
          "finish": "terminer",
          "enterValue": "Entrez la valeur",
          "taskBeforePredecessor_FS": "Vous avez déplacé «{0}» pour commencer avant la fin de «{1}» et les deux tâches sont liées. Par conséquent, les liens ne peuvent pas être honorés. Sélectionnez une action ci-dessous pour effectuer",
          "taskAfterPredecessor_FS": "Vous avez déplacé '{0}' de '{1}' et les deux tâches sont liées. Par conséquent, les liens ne peuvent pas être honorés. Sélectionnez une action ci-dessous pour effectuer",
          "taskBeforePredecessor_SS": "Vous avez déplacé '{0}' pour démarrer avant le démarrage de '{1}' et les deux tâches sont liées. Par conséquent, les liens ne peuvent pas être honorés. Sélectionnez une action ci-dessous pour effectuer",
          "taskAfterPredecessor_SS": "Vous avez déplacé '{0}' pour démarrer après le démarrage de '{1}' et les deux tâches sont liées. Par conséquent, les liens ne peuvent pas être honorés. Sélectionnez une action ci-dessous pour effectuer",
          "taskBeforePredecessor_FF": "Vous avez déplacé '{0}' pour terminer avant la fin de '{1}' et les deux tâches sont liées. Par conséquent, les liens ne peuvent pas être honorés. Sélectionnez une action ci-dessous pour effectuer",
          "taskAfterPredecessor_FF": "Vous avez déplacé «{0}» pour terminer une fois «{1}» terminé et les deux tâches sont liées. Par conséquent, les liens ne peuvent pas être honorés. Sélectionnez une action ci-dessous pour effectuer",
          "taskBeforePredecessor_SF": "Vous avez déplacé '{0}' de '{1}' vers le début et les deux tâches sont liées. Par conséquent, les liens ne peuvent pas être honorés. Sélectionnez une action ci-dessous pour effectuer",
          "taskAfterPredecessor_SF": "Vous avez déplacé '{0}' pour terminer après le démarrage de '{1}' et les deux tâches sont liées. Par conséquent, les liens ne peuvent pas être honorés. Sélectionnez une action ci-dessous pour effectuer",
          "taskInformation": "Informations sur la tâche",
          "deleteTask": "Supprimer la tâche",
          "deleteDependency": "Supprimer la dépendance",
          "convert": "Convertir",
          "save": "Sauvegarder",
          "above": "Au dessus",
          "below": "Au dessous de",
          "child": "Enfant",
          "milestone": "Étape importante",
          "toTask": "À la tâche",
          "toMilestone": "Vers Milestone",
          "eventMarkers": "Marqueurs d'événement",
          "leftTaskLabel": "Étiquette de tâche gauche",
          "rightTaskLabel": "Étiquette de tâche droite",
          "timelineCell": "Cellule de chronologie",
          "confirmPredecessorDelete": "Voulez-vous vraiment supprimer le lien de dépendance ?",
          "unit": "Unité",
          "work": "Travail",
          "taskType": "Type de tâche",
          "unassignedTask": "Tâche non affectée",
          "group": "Grouper",
          "indent": "Retrait",
          "outdent": "Outdent",
          "segments": "Segments",
          "splitTask": "Diviser la tâche",
          "mergeTask": "Fusionner la tâche",
          "left": "La gauche",
          "right": "Droite"
      },
      "richtexteditor": {
          "alignments": "Alignements",
          "justifyLeft": "Alignez à gauche",
          "justifyCenter": "Aligner le centre",
          "justifyRight": "Aligner à droite",
          "justifyFull": "Aligner Justifier",
          "fontName": "Nom de police",
          "fontSize": "Taille de police",
          "fontColor": "Couleur de la police",
          "backgroundColor": "Couleur de l'arrière plan",
          "bold": "Audacieux",
          "italic": "Italique",
          "underline": "Souligner",
          "strikethrough": "Barré",
          "clearFormat": "Format clair",
          "clearAll": "Tout effacer",
          "cut": "Couper",
          "copy": "Copie",
          "paste": "Pâte",
          "unorderedList": "Liste à puces",
          "orderedList": "Liste numérotée",
          "indent": "Augmenter le retrait",
          "outdent": "Diminuer le retrait",
          "undo": "annuler",
          "redo": "Refaire",
          "superscript": "Exposant",
          "subscript": "Indice",
          "createLink": "Insérer un lien hypertexte",
          "openLink": "Ouvrir le lien",
          "editLink": "Modifier le lien",
          "removeLink": "Supprimer le lien",
          "image": "Insérer une image",
          "replace": "Remplacer",
          "align": "Aligner",
          "caption": "Légende",
          "remove": "Retirer",
          "insertLink": "Insérer un lien",
          "display": "Afficher",
          "altText": "Texte alternatif",
          "dimension": "Changer la taille",
          "fullscreen": "Maximiser",
          "maximize": "Maximiser",
          "minimize": "Minimiser",
          "lowerCase": "Minuscules",
          "upperCase": "Majuscules",
          "print": "Impression",
          "formats": "Les formats",
          "sourcecode": "Affichage du code",
          "preview": "Aperçu",
          "viewside": "ViewSide",
          "insertCode": "Insérer le code",
          "linkText": "Afficher le texte",
          "linkTooltipLabel": "Titre",
          "linkWebUrl": "Adresse web",
          "linkTitle": "Entrez un titre",
          "linkurl": "http://example.com",
          "linkOpenInNewWindow": "Ouvre le lien dans une nouvelle fenêtre",
          "linkHeader": "Insérer un lien",
          "dialogInsert": "Insérer",
          "dialogCancel": "Annuler",
          "dialogUpdate": "Mise à jour",
          "imageHeader": "Insérer une image",
          "imageLinkHeader": "Vous pouvez également fournir un lien à partir du Web",
          "mdimageLink": "Veuillez fournir une URL pour votre image",
          "imageUploadMessage": "Déposer l'image ici ou parcourir pour télécharger",
          "imageDeviceUploadMessage": "Cliquez ici pour télécharger",
          "imageAlternateText": "Texte alternatif",
          "alternateHeader": "Texte alternatif",
          "browse": "Feuilleter",
          "imageUrl": "http://example.com/image.png",
          "imageCaption": "Légende",
          "imageSizeHeader": "Taille de l'image",
          "imageHeight": "la taille",
          "imageWidth": "Largeur",
          "textPlaceholder": "Entrez du texte",
          "inserttablebtn": "Insérer un tableau",
          "tabledialogHeader": "Insérer un tableau",
          "tableWidth": "Largeur",
          "cellpadding": "Rembourrage cellulaire",
          "cellspacing": "Espacement des cellules",
          "columns": "Le nombre de colonnes",
          "rows": "Nombre de rangées",
          "tableRows": "Lignes de table",
          "tableColumns": "Colonnes de table",
          "tableCellHorizontalAlign": "Alignement horizontal des cellules du tableau",
          "tableCellVerticalAlign": "Alignement vertical des cellules du tableau",
          "createTable": "Créer une table",
          "removeTable": "Supprimer le tableau",
          "tableHeader": "En-tête de tableau",
          "tableRemove": "Tableau Supprimer",
          "tableCellBackground": "Fond de cellule de tableau",
          "tableEditProperties": "Propriétés de modification du tableau",
          "styles": "modes",
          "insertColumnLeft": "Insérer une colonne à gauche",
          "insertColumnRight": "Insérer une colonne à droite",
          "deleteColumn": "Supprimer la colonne",
          "insertRowBefore": "Insérer une ligne avant",
          "insertRowAfter": "Insérer une ligne après",
          "deleteRow": "Supprimer la ligne",
          "tableEditHeader": "Modifier le tableau",
          "TableHeadingText": "Titre",
          "TableColText": "Col",
          "imageInsertLinkHeader": "Insérer un lien",
          "editImageHeader": "Éditer l'image",
          "alignmentsDropDownLeft": "Alignez à gauche",
          "alignmentsDropDownCenter": "Aligner le centre",
          "alignmentsDropDownRight": "Aligner à droite",
          "alignmentsDropDownJustify": "Aligner Justifier",
          "imageDisplayDropDownInline": "En ligne",
          "imageDisplayDropDownBreak": "Pause",
          "tableInsertRowDropDownBefore": "Insérer une ligne avant",
          "tableInsertRowDropDownAfter": "Insérer une ligne après",
          "tableInsertRowDropDownDelete": "Supprimer la ligne",
          "tableInsertColumnDropDownLeft": "Insérer la colonne à gauche",
          "tableInsertColumnDropDownRight": "Insérer une colonne à droite",
          "tableInsertColumnDropDownDelete": "Supprimer la colonne",
          "tableVerticalAlignDropDownTop": "Aligner en haut",
          "tableVerticalAlignDropDownMiddle": "Aligner au milieu",
          "tableVerticalAlignDropDownBottom": "Aligner en bas",
          "tableStylesDropDownDashedBorder": "Bordures pointillées",
          "tableStylesDropDownAlternateRows": "Lignes alternatives",
          "pasteFormat": "Coller le format",
          "pasteFormatContent": "Choisissez l'action de formatage",
          "plainText": "Texte brut",
          "cleanFormat": "Nettoyer",
          "keepFormat": "Garder",
          "pasteDialogOk": "D'accord",
          "pasteDialogCancel": "Annuler",
          "fileManager": "Gestionnaire de fichiers",
          "fileDialogHeader": "Navigateur de fichiers",
          "formatsDropDownParagraph": "Paragraphe",
          "formatsDropDownCode": "Code",
          "formatsDropDownQuotation": "Devis",
          "formatsDropDownHeading1": "Titre 1",
          "formatsDropDownHeading2": "Titre 2",
          "formatsDropDownHeading3": "Titre 3",
          "formatsDropDownHeading4": "Titre 4",
          "fontNameSegoeUI": "SegoeUI",
          "fontNameArial": "Arial",
          "fontNameGeorgia": "Géorgie",
          "fontNameImpact": "Impacter",
          "fontNameTahoma": "Tahoma",
          "fontNameTimesNewRoman": "Times New Roman",
          "fontNameVerdana": "Verdana",
          "formatsOLListNumber": "Nombre",
          "formatsOLListLowerAlpha": "LowerAlpha",
          "formatsOLListUpperAlpha": "UpperAlpha",
          "formatsOLListLowerRoman": "Bas-Romain",
          "formatsOLListUpperRoman": "Haut-Romain",
          "formatsOLListLowerGreek": "bas grec",
          "formatsULListDisc": "Disque",
          "formatsULListCircle": "Cercle",
          "formatsULListSquare": "Carré",
          "formatsOLListNone": "Rien",
          "formatsULListNone": "Rien"
      },
      "colorpicker": {
          "Apply": "Appliquer",
          "Cancel": "Annuler",
          "ModeSwitcher": "Mode de commutation"
      },
      "uploader": {
          "Browse": "Parcourir...",
          "Clear": "Effacer",
          "Upload": "Télécharger",
          "dropFilesHint": "Ou déposer des fichiers ici",
          "invalidMaxFileSize": "La taille du fichier est trop grande",
          "invalidMinFileSize": "La taille du fichier est trop petite",
          "invalidFileType": "Le type de fichier n'est pas autorisé",
          "uploadFailedMessage": "Échec du téléchargement du fichier",
          "uploadSuccessMessage": "Fichier téléchargé avec succès",
          "removedSuccessMessage": "Fichier supprimé avec succès",
          "removedFailedMessage": "Impossible de supprimer le fichier",
          "inProgress": "Téléchargement",
          "readyToUploadMessage": "Prêt à télécharger",
          "abort": "Interrompre",
          "remove": "Retirer",
          "cancel": "Annuler",
          "delete": "Supprimer le fichier",
          "pauseUpload": "Téléchargement du fichier interrompu",
          "pause": "Pause",
          "resume": "Reprendre",
          "retry": "Réessayer",
          "fileUploadCancel": "Téléchargement du fichier annulé"
      },
      "numerictextbox": {
          "incrementTitle": "Incrémenter la valeur",
          "decrementTitle": "Décrémenter la valeur"
      },
      "slider": {
          "incrementTitle": "Augmenter",
          "decrementTitle": "Diminution"
      },
      "formValidator": {
          "required": "Ce champ est requis.",
          "email": "S'il vous plaît, mettez une adresse email valide.",
          "url": "Veuillez saisir une URL valide.",
          "date": "Veuillez entrer une date valide.",
          "dateIso": "Veuillez saisir une date valide (ISO).",
          "creditcard": "Veuillez entrer un numéro de carte valide",
          "number": "S'il vous plait, entrez un nombre valide.",
          "digits": "Merci de n'entrer que des chiffres.",
          "maxLength": "Veuillez ne pas saisir plus de {0} caractères.",
          "minLength": "Veuillez saisir au moins {0} caractères.",
          "rangeLength": "Veuillez saisir une valeur comprise entre {0} et {1} caractères.",
          "range": "Veuillez saisir une valeur comprise entre {0} et {1}.",
          "max": "Veuillez saisir une valeur inférieure ou égale à {0}.",
          "min": "Veuillez saisir une valeur supérieure ou égale à {0}.",
          "regex": "Veuillez saisir une valeur correcte.",
          "tel": "S'il vous plaît entrer un numéro de téléphone valide.",
          "pattern": "Veuillez saisir une valeur de motif correcte.",
          "equalTo": "Veuillez saisir le texte de correspondance valide"
      },
      "treegrid": {
          "Above": "Au dessus",
          "Below": "Au dessous de",
          "AddRow": "Ajouter une rangée",
          "ExpandAll": "Développer tout",
          "CollapseAll": "Réduire tout"
      },
      "querybuilder": {
          "StartsWith": "Commence avec",
          "EndsWith": "Se termine par",
          "Contains": "Contient",
          "Equal": "Égal",
          "NotEqual": "Inégal",
          "LessThan": "Moins que",
          "LessThanOrEqual": "Inférieur ou égal",
          "GreaterThan": "Plus grand que",
          "GreaterThanOrEqual": "Meilleur que ou égal",
          "Between": "Entre",
          "NotBetween": "Pas entre",
          "Empty": "Vide",
          "NotEmpty": "Pas vide",
          "In": "Dans",
          "NotIn": "Pas dedans",
          "NotContains": "Ne contient pas",
          "Remove": "RETIRER",
          "SelectField": "Sélectionnez un champ",
          "SelectOperator": "Sélectionnez un opérateur",
          "DeleteRule": "Supprimer cette condition",
          "DeleteGroup": "Supprimer le groupe",
          "AddGroup": "Ajouter un groupe",
          "AddCondition": "Ajouter une condition",
          "Edit": "ÉDITER",
          "ValidationMessage": "Ce champ est requis",
          "SummaryViewTitle": "Vue récapitulative",
          "OtherFields": "Autres domaines",
          "AND": "ET",
          "OR": "OU",
          "SelectValue": "Entrez une valeur",
          "IsEmpty": "Est vide",
          "IsNotEmpty": "N'est pas vide",
          "IsNull": "Est nul",
          "IsNotNull": "Est non nulle",
          "True": "vrai",
          "False": "faux"
      },
      "barcode": {},
      "datamatrix": {},
      "qrcode": {},
      "pivotview": {
          "grandTotal": "Grand total",
          "total": "Total",
          "value": "Valeur",
          "noValue": "Aucune valeur",
          "row": "Ligne",
          "column": "Colonne",
          "collapse": "Réduire",
          "expand": "Développer",
          "rowAxisPrompt": "Déposer la ligne ici",
          "columnAxisPrompt": "Déposer la colonne ici",
          "valueAxisPrompt": "Déposer la valeur ici",
          "filterAxisPrompt": "Déposer le filtre ici",
          "filter": "Filtre",
          "filtered": "Filtré",
          "sort": "Trier",
          "filters": "Filtres",
          "rows": "Lignes",
          "columns": "Colonnes",
          "values": "Valeurs",
          "close": "Fermer",
          "cancel": "Annuler",
          "delete": "Supprimer",
          "CalculatedField": "Champ calculé",
          "createCalculatedField": "Créer un champ calculé",
          "fieldName": "Entrez le nom du champ",
          "error": "Erreur",
          "invalidFormula": "Formule non valide.",
          "dropText": "Exemple: ('Sum (Order_Count)' + 'Sum (In_Stock)') * 250",
          "dropTextMobile": "Ajoutez des champs et modifiez la formule ici.",
          "dropAction": "Le champ calculé ne peut être placé dans aucune autre région à l'exception de l'axe des valeurs.",
          "alert": "Alerte",
          "warning": "avertissement",
          "ok": "Valider",
          "search": "Chercher",
          "drag": "Glisser",
          "remove": "Retirer",
          "allFields": "Tous les champs",
          "formula": "Formule",
          "addToRow": "Ajouter à la ligne",
          "addToColumn": "Ajouter à la colonne",
          "addToValue": "Ajouter à la valeur",
          "addToFilter": "Ajouter au filtre",
          "emptyData": "Aucun enregistrement à afficher",
          "fieldExist": "Un champ existe déjà sous ce nom. Veuillez saisir un nom différent.",
          "confirmText": "Un champ de calcul existe déjà sous ce nom. Voulez-vous le remplacer ?",
          "noMatches": "Pas de correspondance",
          "format": "Résume les valeurs par",
          "edit": "Éditer",
          "clear": "Clair",
          "formulaField": "Glissez et déposez les champs dans la formule",
          "dragField": "Faites glisser le champ vers la formule",
          "clearFilter": "Effacer les filtres",
          "by": "par",
          "all": "Tout",
          "multipleItems": "Articles multiples",
          "member": "Membre",
          "label": "Étiquette",
          "date": "Date",
          "enterValue": "Entrez une valeur",
          "chooseDate": "Entrez la date",
          "Before": "Avant",
          "BeforeOrEqualTo": "Avant ou égal à",
          "After": "Après",
          "AfterOrEqualTo": "Après ou égal à",
          "labelTextContent": "Afficher les éléments pour lesquels l'étiquette",
          "dateTextContent": "Afficher les éléments pour lesquels la date",
          "valueTextContent": "Afficher les articles pour lesquels",
          "Equals": "Équivaut à",
          "DoesNotEquals": "N'est pas égal",
          "BeginWith": "Commence par",
          "DoesNotBeginWith": "Ne commence pas par",
          "EndsWith": "Se termine par",
          "DoesNotEndsWith": "Ne se termine pas par",
          "Contains": "Contient",
          "DoesNotContains": "Ne contient pas",
          "GreaterThan": "Plus grand que",
          "GreaterThanOrEqualTo": "Plus grand ou égal à",
          "LessThan": "Moins que",
          "LessThanOrEqualTo": "Inférieur ou égal à",
          "Between": "Entre",
          "NotBetween": "Pas entre",
          "And": "et",
          "Sum": "Somme",
          "Count": "Compter",
          "DistinctCount": "Nombre distinct",
          "Product": "Produit",
          "Avg": "Moy.",
          "Min": "Min",
          "SampleVar": "Échantillon Var",
          "PopulationVar": "Population Var",
          "RunningTotals": "Totaux en cours d'exécution",
          "Max": "Max",
          "Index": "Indice",
          "SampleStDev": "Exemple StDev",
          "PopulationStDev": "La population de StDev",
          "PercentageOfRowTotal": "% du total des lignes",
          "PercentageOfParentTotal": "% du total des parents",
          "PercentageOfParentColumnTotal": "% du total de la colonne parent",
          "PercentageOfParentRowTotal": "% du total de la ligne parent",
          "DifferenceFrom": "Différence de",
          "PercentageOfDifferenceFrom": "% de différence par rapport à",
          "PercentageOfGrandTotal": "% du grand total",
          "PercentageOfColumnTotal": "% du total de la colonne",
          "NotEquals": "Pas égal",
          "AllValues": "Toutes les valeurs",
          "conditionalFormating": "Mise en forme conditionnelle",
          "apply": "APPLIQUER",
          "condition": "Ajouter une condition",
          "formatLabel": "Format",
          "valueFieldSettings": "Paramètres de champ de valeur",
          "baseField": "Champ de base:",
          "baseItem": "Élément de base:",
          "summarizeValuesBy": "Résumez les valeurs en:",
          "sourceName": "Nom de domaine :",
          "sourceCaption": "Légende du champ:",
          "example": "par exemple:",
          "editorDataLimitMsg": " Plus d'articles. Recherchez pour affiner davantage.",
          "details": "Détails",
          "manageRecords": "Gérer les enregistrements",
          "Years": "Ans",
          "Quarters": "Quartiers",
          "Months": "Mois",
          "Days": "Journées",
          "Hours": "Heures",
          "Minutes": "Minutes",
          "Seconds": "Secondes",
          "save": "Enregistrer un rapport",
          "new": "Créer un nouveau rapport",
          "load": "Charger",
          "saveAs": "Enregistrer en tant que rapport actuel",
          "rename": "Renommer un rapport actuel",
          "deleteReport": "Supprimer un rapport actuel",
          "export": "Exportation",
          "subTotals": "Sous-totaux",
          "grandTotals": "Totaux généraux",
          "reportName": "Nom du rapport:",
          "pdf": "PDF",
          "excel": "Exceller",
          "csv": "CSV",
          "png": "PNG",
          "jpeg": "JPEG",
          "svg": "SVG",
          "mdxQuery": "Requête MDX",
          "showSubTotals": "Afficher les sous-totaux",
          "doNotShowSubTotals": "Ne pas afficher les sous-totaux",
          "showSubTotalsRowsOnly": "Afficher uniquement les lignes de sous-totaux",
          "showSubTotalsColumnsOnly": "Afficher uniquement les colonnes de sous-totaux",
          "showGrandTotals": "Afficher les totaux généraux",
          "doNotShowGrandTotals": "Ne pas afficher les totaux généraux",
          "showGrandTotalsRowsOnly": "Afficher uniquement les lignes des totaux généraux",
          "showGrandTotalsColumnsOnly": "Afficher uniquement les colonnes des totaux généraux",
          "fieldList": "Afficher la liste des champs",
          "grid": "Afficher le tableau",
          "toolbarFormatting": "Mise en forme conditionnelle",
          "chart": "Graphique",
          "reportMsg": "Veuillez entrer un nom de rapport valide !",
          "reportList": "Liste des rapports",
          "removeConfirm": "Voulez-vous vraiment supprimer ce rapport ?",
          "emptyReport": "Aucun rapport trouvé !",
          "bar": "Barre",
          "line": "Ligne",
          "area": "Zone",
          "scatter": "Dispersion",
          "polar": "Polaire",
          "of": "de",
          "emptyFormat": "Aucun format trouvé !",
          "emptyInput": "Entrez une valeur",
          "newReportConfirm": "Vous souhaitez enregistrer les modifications à signaler ?",
          "emptyReportName": "Entrez un nom de rapport",
          "qtr": "Qtr",
          "null": "nul",
          "undefined": "indéfini",
          "groupOutOfRange": "Hors de portée",
          "fieldDropErrorAction": "Le champ que vous déplacez ne peut pas être placé dans cette zone du rapport",
          "MoreOption": "Plus...",
          "aggregate": "Agrégat",
          "drillThrough": "Afficher le détail",
          "ascending": "Ascendant",
          "descending": "Descendant",
          "number": "Nombre",
          "currency": "Devise",
          "percentage": "Pourcentage",
          "formatType": "Type de format",
          "customText": "Symbole de la monnaie",
          "symbolPosition": "Position du symbole",
          "left": "La gauche",
          "right": "Droite",
          "grouping": "Regroupement",
          "true": "Vrai",
          "false": "Faux",
          "decimalPlaces": "Décimales",
          "numberFormat": "Formatage des nombres",
          "memberType": "Type de champ",
          "formatString": "Format de chaîne",
          "expressionField": "Expression",
          "customFormat": "Entrez une chaîne de format personnalisé",
          "selectedHierarchy": "Hiérarchie parente",
          "olapDropText": "Exemple: [Mesures]. [Quantité de commande] + ([Mesures]. [Quantité de commande] * 0,10)",
          "Percent": "Pour cent",
          "Custom": "Personnalisé",
          "Measure": "Mesure",
          "Dimension": "Dimension",
          "Standard": "Standard",
          "blank": "(Vide)",
          "fieldTooltip": "Faites glisser et déposez les champs pour créer une expression. Si vous souhaitez modifier les champs calculés existants, vous pouvez y parvenir en sélectionnant simplement le champ sous «Membres calculés».",
          "QuarterYear": "Trimestre de l'année",
          "fieldTitle": "Nom de domaine",
          "drillError": "Impossible d'afficher les éléments bruts des champs calculés.",
          "caption": "Légende de champ",
          "copy": "Copie",
          "defaultReport": "Rapport par défaut",
          "customFormatString": "Format personnalisé",
          "invalidFormat": "Format invalide.",
          "group": "Grouper",
          "unGroup": "Dissocier",
          "invalidSelection": "Impossible de grouper cette sélection.",
          "groupName": "Entrez la légende à afficher dans l'en-tête",
          "captionName": "Entrez la légende pour le champ de groupe",
          "selectedItems": "Éléments sélectionnés",
          "groupFieldCaption": "Légende de champ",
          "groupTitle": "Nom de groupe",
          "startAt": "À partir de",
          "endAt": "Se terminant à",
          "groupBy": "Grouper par",
          "selectGroup": "Sélectionnez des groupes",
          "numberFormatString": "Exemple: C, P, 0000%, ### 0. ## 0 #, etc.",
          "stackingcolumn": "Colonne empilée",
          "stackingbar": "Barre empilée",
          "stackingarea": "Zone empilée",
          "stepline": "Ligne à étape",
          "steparea": "Zone de l'étape",
          "splinearea": "Zone de point",
          "spline": "Ligne douces",
          "stackingcolumn100": "Colonne 100% empilée",
          "stackingbar100": "Barre 100% empilée",
          "stackingarea100": "Zone 100% empilée",
          "bubble": "Bulle",
          "pareto": "Pareto",
          "radar": "Radar",
          "chartTypeSettings": "Paramètres de type de graphique",
          "multipleAxes": "Plusieurs axes",
          "sortAscending": "Trier par ordre croissant",
          "sortDescending": "Trier par ordre décroissant",
          "sortNone": "Trier par ordre des données",
          "clearCalculatedField": "Effacer les informations des champs modifiés",
          "editCalculatedField": "Modifier le champ calculé",
          "ChartType": "Type de graphique",
          "yes": "Oui",
          "no": "Non",
          "numberFormatMenu": "Formatage des nombres ...",
          "conditionalFormatingMenu": "Mise en forme conditionnelle...",
          "removeCalculatedField": "Voulez-vous vraiment supprimer ce champ calculé ?",
          "replaceConfirmBefore": "Un rapport nommé",
          "replaceConfirmAfter": " existe déjà. Voulez-vous le remplacer ?",
          "pie": "Tarte",
          "funnel": "Entonnoir",
          "doughnut": "Donut",
          "pyramid": "Pyramide",
          "showLegend": "Afficher la légende",
          "exit": "Sortie",
          "invalidJSON": "Données JSON non valides",
          "invalidCSV": "Données CSV non valides",
          "stacked": "Empilé",
          "single": "Seul",
          "multipleAxisMode": "Mode à axes multiples",
          "grandTotalPosition": "Position des totaux généraux",
          "top": "Haut",
          "bottom": "Bas",
          "None": "Aucun",
          "stackingline": "Ligne empilée",
          "stackingline100": "100% Ligne empilée"
      },
      "pivotfieldlist": {
          "staticFieldList": "Liste des champs de pivot",
          "fieldList": "Liste de champ",
          "dropFilterPrompt": "Déposer le filtre ici",
          "dropColPrompt": "Déposer la colonne ici",
          "dropRowPrompt": "Déposer la ligne ici",
          "dropValPrompt": "Déposer la valeur ici",
          "addPrompt": "Ajouter un champ ici",
          "adaptiveFieldHeader": "Choisissez un champ",
          "centerHeader": "Faites glisser les champs entre les axes ci-dessous:",
          "add": "Ajouter",
          "drag": "Glisser",
          "filter": "Filtre",
          "filtered": "Filtré",
          "sort": "Trier",
          "remove": "Retirer",
          "filters": "Filtres",
          "rows": "Lignes",
          "columns": "Colonnes",
          "values": "Valeurs",
          "CalculatedField": "Champ calculé",
          "createCalculatedField": "Créer un champ calculé",
          "fieldName": "Entrez le nom du champ",
          "error": "Erreur",
          "invalidFormula": "Formule non valide.",
          "dropText": "Exemple: ('Sum (Order_Count)' + 'Sum (In_Stock)' ') * 250",
          "dropTextMobile": "Ajoutez des champs et modifiez la formule ici.",
          "dropAction": "Le champ calculé ne peut être placé dans aucune autre région à l'exception de l'axe des valeurs.",
          "search": "Chercher",
          "close": "Fermer",
          "cancel": "Annuler",
          "delete": "Supprimer",
          "alert": "Alerte",
          "warning": "avertissement",
          "ok": "Valider",
          "allFields": "Tous les champs",
          "formula": "Formule",
          "fieldExist": "Un champ existe déjà sous ce nom. Veuillez saisir un nom différent.",
          "confirmText": "Un champ de calcul existe déjà sous ce nom. Voulez-vous le remplacer ?",
          "noMatches": "Pas de correspondance",
          "format": "Résume les valeurs par",
          "edit": "Éditer",
          "clear": "Effacer",
          "formulaField": "Glissez et déposez les champs dans la formule",
          "dragField": "Faites glisser le champ vers la formule",
          "clearFilter": "Effacer les filtres",
          "by": "par",
          "enterValue": "Entrez une valeur",
          "chooseDate": "Entrez la date",
          "all": "Tout",
          "multipleItems": "Articles multiples",
          "Equals": "Équivaut à",
          "DoesNotEquals": "N'est pas égal",
          "BeginWith": "Commence par",
          "DoesNotBeginWith": "Ne commence pas par",
          "EndsWith": "Se termine par",
          "DoesNotEndsWith": "Ne se termine pas par",
          "Contains": "Contient",
          "DoesNotContains": "Ne contient pas",
          "GreaterThan": "Plus grand que",
          "GreaterThanOrEqualTo": "Plus grand ou égal à",
          "LessThan": "Moins que",
          "LessThanOrEqualTo": "Inférieur ou égal à",
          "Between": "Entre",
          "NotBetween": "Pas entre",
          "Before": "Avant",
          "BeforeOrEqualTo": "Avant ou égal à",
          "After": "Après",
          "AfterOrEqualTo": "Après ou égal à",
          "member": "Membre",
          "label": "Étiquette",
          "date": "Date",
          "value": "Valeur",
          "labelTextContent": "Afficher les éléments pour lesquels l'étiquette",
          "dateTextContent": "Afficher les éléments pour lesquels la date",
          "valueTextContent": "Afficher les articles pour lesquels",
          "And": "et",
          "Sum": "Somme",
          "Count": "Compter",
          "DistinctCount": "Nombre distinct",
          "Product": "Produit",
          "Avg": "Moy.",
          "Min": "Min",
          "Max": "Max",
          "Index": "Indice",
          "SampleStDev": "Exemple StDev",
          "PopulationStDev": "La population de StDev",
          "SampleVar": "Échantillon Var",
          "PopulationVar": "Population Var",
          "RunningTotals": "Totaux en cours d'exécution",
          "DifferenceFrom": "Différence de",
          "PercentageOfDifferenceFrom": "% de différence par rapport à",
          "PercentageOfGrandTotal": "% du grand total",
          "PercentageOfColumnTotal": "% du total de la colonne",
          "PercentageOfRowTotal": "% du total des lignes",
          "PercentageOfParentTotal": "% du total des parents",
          "PercentageOfParentColumnTotal": "% du total de la colonne parent",
          "PercentageOfParentRowTotal": "% du total de la ligne parent",
          "Years": "Ans",
          "Quarters": "Quartiers",
          "Months": "Mois",
          "Days": "Journées",
          "Hours": "Heures",
          "Minutes": "Minutes",
          "Seconds": "Secondes",
          "apply": "APPLIQUER",
          "valueFieldSettings": "Paramètres de champ de valeur",
          "sourceName": "Nom de domaine :",
          "sourceCaption": "Légende du champ :",
          "summarizeValuesBy": "Résumez les valeurs en :",
          "baseField": "Champ de base:",
          "baseItem": "Élément de base:",
          "example": "par exemple:",
          "editorDataLimitMsg": " Plus d'articles. Recherchez pour affiner davantage.",
          "deferLayoutUpdate": "Différer la mise à jour de la mise en page",
          "null": "nul",
          "undefined": "indéfini",
          "groupOutOfRange": "Hors de portée",
          "fieldDropErrorAction": "Le champ que vous déplacez ne peut pas être placé dans cette zone du rapport",
          "MoreOption": "Plus...",
          "memberType": "Type de champ",
          "selectedHierarchy": "Hiérarchie parente",
          "formatString": "Format de chaîne",
          "expressionField": "Expression",
          "olapDropText": "Exemple: [Mesures]. [Quantité de commande] + ([Mesures]. [Quantité de commande] * 0,10)",
          "customFormat": "Entrez une chaîne de format personnalisé",
          "Measure": "Mesure",
          "Dimension": "Dimension",
          "Standard": "Standard",
          "Currency": "Devise",
          "Percent": "Pour cent",
          "Custom": "Personnalisé",
          "blank": "(Vide)",
          "fieldTooltip": "Faites glisser et déposez les champs pour créer une expression. Si vous souhaitez modifier les champs calculés existants, Vous pouvez y parvenir en sélectionnant simplement le champ sous «Membres calculés».",
          "fieldTitle": "Nom de domaine",
          "QuarterYear": "Trimestre de l'année",
          "caption": "Légende de champ",
          "copy": "Copie",
          "group": "Grouper",
          "numberFormatString": "Exemple: C, P, 0000%, ### 0. ## 0 #, etc.",
          "sortAscending": "Trier par ordre croissant",
          "sortDescending": "Trier par ordre décroissant",
          "sortNone": "Trier par ordre des données",
          "clearCalculatedField": "Effacer les informations des champs modifiés",
          "editCalculatedField": "Modifier le champ calculé",
          "selectGroup": "Sélectionnez des groupes",
          "of": "de",
          "removeCalculatedField": "Voulez-vous vraiment supprimer ce champ calculé ?",
          "yes": "Voulez-vous vraiment supprimer ce champ calculé ?",
          "no": "Non",
          "None": "Aucun"
      },
      "pdfviewer": {
          "PdfViewer": "Visionneuse PDF",
          "Cancel": "Annuler",
          "Download file": "Télécharger un fichier",
          "Download": "Télécharger",
          "Enter Password": "Ce document est protégé par mot de passe. Veuillez entrer un mot de passe.",
          "File Corrupted": "Fichier corrompu",
          "File Corrupted Content": "Le fichier est corrompu et ne peut pas être ouvert.",
          "Fit Page": "Ajuster la page",
          "Fit Width": "Largeur de coupe",
          "Automatic": "Automatique",
          "Go To First Page": "Afficher la première page",
          "Invalid Password": "Mot de passe incorrect. Veuillez réessayer.",
          "Next Page": "Afficher la page suivante",
          "OK": "D'accord",
          "Open": "Fichier ouvert",
          "Page Number": "Numéro de page actuel",
          "Previous Page": "Afficher la page précédente",
          "Go To Last Page": "Afficher la dernière page",
          "Zoom": "Zoom",
          "Zoom In": "Agrandir",
          "Zoom Out": "Dézoomer",
          "Page Thumbnails": "Vignettes de page",
          "Bookmarks": "Signets",
          "Print": "Imprimer le fichier",
          "Password Protected": "Mot de passe requis",
          "Copy": "Copie",
          "Text Selection": "Outil de sélection de texte",
          "Panning": "Mode panoramique",
          "Text Search": "Rechercher du texte",
          "Find in document": "Rechercher dans le document",
          "Match case": "Cas de correspondance",
          "Apply": "Appliquer",
          "GoToPage": "Aller à la page",
          "No matches": "La visionneuse a terminé la recherche du document. Aucune autre correspondance n'a été trouvée",
          "No Text Found": "Aucun texte trouvé",
          "Undo": "annuler",
          "Redo": "Refaire",
          "Annotation": "Ajouter ou modifier des annotations",
          "Highlight": "Texte surligné",
          "Underline": "Souligner le texte",
          "Strikethrough": "Texte barré",
          "Delete": "Supprimer l'annotation",
          "Opacity": "Opacité",
          "Color edit": "Changer de couleur",
          "Opacity edit": "Changer l'opacité",
          "highlight": "Modifier l'opacité",
          "underline": "Souligner",
          "strikethrough": "Souligner",
          "Server error": "Le service Web n'écoute pas. PDF Viewer dépend du service Web pour toutes ses fonctionnalités. Veuillez démarrer le service Web pour continuer.",
          "Open text": "Ouvert",
          "First text": "Première page",
          "Previous text": "Page précédente",
          "Next text": "Page suivante",
          "Last text": "Dernière page",
          "Zoom in text": "Agrandir",
          "Zoom out text": "Dézoomer",
          "Selection text": "Sélection",
          "Pan text": "La poêle",
          "Print text": "Impression",
          "Seach text": "Imprimer",
          "Annotation Edit text": "Modifier l'annotation",
          "Highlight context": "Surligner",
          "Underline context": "Souligner",
          "Strikethrough context": "Grève",
          "Search text": "Chercher",
          "Line Thickness": "Épaisseur de ligne",
          "Line Properties": "Propriétés de ligne",
          "Start Arrow": "Flèche de départ",
          "End Arrow": "Flèche de fin",
          "Line Style": "Style de ligne",
          "Fill Color": "La couleur de remplissage",
          "Line Color": "Couleur de la ligne",
          "None": "Aucun",
          "Open Arrow": "Ouvert",
          "Closed Arrow": "Fermé",
          "Round Arrow": "Rond",
          "Square Arrow": "Carré",
          "Diamond Arrow": "diamant",
          "Cut": "Couper",
          "Paste": "Pâte",
          "Delete Context": "Supprimer",
          "Properties": "Propriétés",
          "Add Stamp": "Ajouter un tampon",
          "Add Shapes": "Ajouter des formes",
          "Stroke edit": "Changer la couleur du trait",
          "Change thickness": "Modifier l'épaisseur de la bordure",
          "Add line": "Ajouter une ligne",
          "Add arrow": "Ajouter une flèche",
          "Add rectangle": "Ajouter un rectangle",
          "Add circle": "Ajouter un cercle",
          "Add polygon": "Ajouter un polygone",
          "Add Comments": "Ajoutez des commentaires",
          "Comments": "commentaires",
          "No Comments Yet": "Aucun commentaire pour l'instant",
          "Accepted": "Accepté",
          "Completed": "Terminé",
          "Cancelled": "Annulé",
          "Rejected": "Rejeté",
          "Leader Length": "Longueur de ligne de repère",
          "Scale Ratio": "Rapport d'échelle",
          "Calibrate": "Étalonner",
          "Calibrate Distance": "Calibrer la distance",
          "Calibrate Perimeter": "Calibrer le périmètre",
          "Calibrate Area": "Étalonner la zone",
          "Calibrate Radius": "Calibrer le rayon",
          "Calibrate Volume": "Calibrer le volume",
          "ok": "Valider"
      },
      "diagram": {
          "Copy": "Copie",
          "Cut": "Couper",
          "Paste": "Pâte",
          "Undo": "annuler",
          "Redo": "Refaire",
          "SelectAll": "Tout sélectionner",
          "Grouping": "Regroupement",
          "Group": "Regroupement",
          "UnGroup": "Dissocier",
          "Order": "Commande",
          "BringToFront": "Mettre au premier plan",
          "MoveForward": "Avance",
          "SendToBack": "Envoyer au fond",
          "SendBackward": "Envoyer vers l'arrière",
          "group": "Grouper"
      },
      "inplace-editor": {
          "editIcon": "Cliquez pour modifier",
          "save": "Sauvegarder",
          "cancel": "Annuler"
      },
      "DocumentEditor": {
          "Table": "Table",
          "Row": "Table",
          "Cell": "Cellule",
          "Ok": "D'accord",
          "Cancel": "Annuler",
          "Size": "Taille",
          "Preferred Width": "Largeur préférée",
          "Points": "Points",
          "Percent": "Pour cent",
          "Measure in": "Mesurer en",
          "Alignment": "Alignement",
          "Left": "La gauche",
          "Center": "Centre",
          "Right": "Droite",
          "Justify": "Justifier",
          "Indent from left": "Retrait de gauche",
          "Borders and Shading": "Bordures et ombrage",
          "Options": "Les options",
          "Specify height": "Précisez la hauteur",
          "At least": "Au moins",
          "Exactly": "Exactement",
          "Row height is": "La hauteur de ligne est",
          "Allow row to break across pages": "Autoriser la ligne à traverser les pages",
          "Repeat as header row at the top of each page": "Répéter comme ligne d'en-tête en haut de chaque page",
          "Vertical alignment": "Alignement vertical",
          "Top": "Haut",
          "Bottom": "Bas",
          "Default cell margins": "Marges de cellule par défaut",
          "Default cell spacing": "Espacement des cellules par défaut",
          "Allow spacing between cells": "Autoriser l'espacement entre les cellules",
          "Cell margins": "Marges des cellules",
          "Same as the whole table": "Identique à toute la table",
          "Borders": "Les frontières",
          "None": "Aucun",
          "Single": "Seul",
          "Dot": "Point",
          "DashSmallGap": "DashSmallGap",
          "DashLargeGap": "DashLargeGap",
          "DashDot": "TiretPoint",
          "DashDotDot": "TiretPointPoint",
          "Double": "Double",
          "Triple": "Tripler",
          "ThinThickSmallGap": "MinceÉpaisPetitEspace",
          "ThickThinSmallGap": "ÉpaisMincePetitEspace",
          "ThinThickThinSmallGap": "MinceÉpaisMincePetitEspace",
          "ThinThickMediumGap": "FinÉpaisMoyenÉcart",
          "ThickThinMediumGap": "ÉpaisMinceMoyenÉcart",
          "ThinThickThinMediumGap": "MinceÉpaisMinceMoyenÉcart",
          "ThinThickLargeGap": "MinceÉpaisGrandEspace",
          "ThickThinLargeGap": "ÉpaisMinceGrandEspace",
          "ThinThickThinLargeGap": "MinceÉpaisMinceLargeGap",
          "SingleWavy": "SimpleWavy",
          "DoubleWavy": "DoubleOndulé",
          "DashDotStroked": "TiretPointTracé",
          "Emboss3D": "Emboss3D",
          "Engrave3D": "Graver3D",
          "Outset": "Début",
          "Inset": "Encart",
          "Thick": "Épais",
          "Style": "Style",
          "Width": "Largeur",
          "Height": "la taille",
          "Letter": "Lettre",
          "Tabloid": "Tabloïde",
          "Legal": "Légal",
          "Statement": "Déclaration",
          "Executive": "Exécutif",
          "A3": "A3",
          "A4": "A4",
          "A5": "A5",
          "B4": "B4",
          "B5": "B5",
          "Custom Size": "Format personnalisé",
          "Different odd and even": "Différent impair et pair",
          "Different first page": "Première page différente",
          "From edge": "Du bord",
          "Header": "Entête",
          "Footer": "Bas de page",
          "Margin": "Marges",
          "Paper": "Papier",
          "Layout": "Disposition",
          "Orientation": "Orientation",
          "Landscape": "Paysage",
          "Portrait": "Portrait",
          "Table Of Contents": "Table des matières",
          "Show page numbers": "Afficher les numéros de page",
          "Right align page numbers": "Aligner à droite les numéros de page",
          "Nothing": "Rien",
          "Tab leader": "Repère de tabulation",
          "Show levels": "Afficher les niveaux",
          "Use hyperlinks instead of page numbers": "Utilisez des hyperliens au lieu des numéros de page",
          "Build table of contents from": "Créer une table des matières à partir de",
          "Styles": "modes",
          "Available styles": "Styles disponibles",
          "TOC level": "Niveau COT",
          "Heading": "Titre",
          "List Paragraph": "Paragraphe de liste",
          "Normal": "Ordinaire",
          "Outline levels": "Niveaux de contour",
          "Table entry fields": "Champs de saisie de table",
          "Modify": "Modifier",
          "Color": "Couleur",
          "Setting": "Réglage",
          "Box": "Boîte",
          "All": "Tout",
          "Custom": "Personnalisé",
          "Preview": "Aperçu",
          "Shading": "Ombres",
          "Fill": "Remplir",
          "Apply To": "Postuler à",
          "Table Properties": "Propriétés du tableau",
          "Cell Options": "Options de cellule",
          "Table Options": "Options de table",
          "Insert Table": "Insérer un tableau",
          "Number of columns": "Le nombre de colonnes",
          "Number of rows": "Nombre de rangées",
          "Text to display": "Texte à afficher",
          "Address": "Adresse",
          "Insert Hyperlink": "Insérer un lien hypertexte",
          "Edit Hyperlink": "Modifier l'hyperlien",
          "Insert": "Insérer",
          "General": "Général",
          "Indentation": "Échancrure",
          "Before text": "Avant le texte",
          "Special": "Spécial",
          "First line": "Première ligne",
          "Hanging": "Pendaison",
          "After text": "Après le texte",
          "By": "Par",
          "Before": "Avant",
          "Line Spacing": "Interligne",
          "After": "Après",
          "At": "À",
          "Multiple": "Plusieurs",
          "Spacing": "Espacement",
          "Define new Multilevel list": "Définir une nouvelle liste à plusieurs niveaux",
          "List level": "Niveau liste",
          "Choose level to modify": "Choisissez le niveau à modifier",
          "Level": "Niveau",
          "Number format": "Format de nombre",
          "Number style for this level": "Style de nombre pour ce niveau",
          "Enter formatting for number": "Entrez la mise en forme du nombre",
          "Start at": "Commencer à",
          "Restart list after": "Redémarrez la liste après",
          "Position": "Position",
          "Text indent at": "Retrait du texte à",
          "Aligned at": "Aligné à",
          "Follow number with": "Suivez le numéro avec",
          "Tab character": "Caractère de tabulation",
          "Space": "Espace",
          "Arabic": "arabe",
          "UpRoman": "UpRoman",
          "LowRoman": "LowRoman",
          "UpLetter": "UpLetter",
          "LowLetter": "LowLetter",
          "Number": "Nombre",
          "Leading zero": "Zéro en tête",
          "Bullet": "Balle",
          "Ordinal": "Ordinal",
          "Ordinal Text": "Texte ordinal",
          "For East": "Pour l'Est",
          "No Restart": "Pas de redémarrage",
          "Font": "Police de caractère",
          "Font style": "Le style de police",
          "Underline style": "Souligner le style",
          "Font color": "Couleur de la police",
          "Effects": "Effets",
          "Strikethrough": "Barré",
          "Superscript": "Exposant",
          "Subscript": "Indice",
          "Double strikethrough": "Barré double",
          "Regular": "Ordinaire",
          "Bold": "Audacieux",
          "Italic": "Italique",
          "Cut": "Couper",
          "Copy": "Copie",
          "Paste": "Pâte",
          "Hyperlink": "Hyperlien",
          "Open Hyperlink": "Ouvrir l'hyperlien",
          "Copy Hyperlink": "Copier l'hyperlien",
          "Remove Hyperlink": "Supprimer l'hyperlien",
          "Paragraph": "Paragraphe",
          "Merge Cells": "Fusionner des cellules",
          "Insert Above": "Insérer au-dessus",
          "Insert Below": "Insérer ci-dessous",
          "Insert Left": "Insérer à gauche",
          "Insert Right": "Insérer à droite",
          "Delete": "Supprimer",
          "Delete Table": "Supprimer le tableau",
          "Delete Row": "Supprimer la ligne",
          "Delete Column": "Supprimer la colonne",
          "File Name": "Nom de fichier",
          "Format Type": "Type de format",
          "Save": "Sauvegarder",
          "Navigation": "La navigation",
          "Results": "Résultats",
          "Replace": "Remplacer",
          "Replace All": "Remplace tout",
          "We replaced all": "Nous avons remplacé tous",
          "Find": "Trouver",
          "No matches": "Pas de correspondance",
          "All Done": "Terminé",
          "Result": "Résultat",
          "of": "de",
          "instances": "instances",
          "with": "avec",
          "Click to follow link": "Cliquez pour suivre le lien",
          "Continue Numbering": "Continuer la numérotation",
          "Bookmark name": "Nom du signet",
          "Close": "Fermer",
          "Restart At": "Redémarrer à",
          "Properties": "Propriétés",
          "Name": "Nom",
          "Style type": "Type de style",
          "Style based on": "Style basé sur",
          "Style for following paragraph": "Style pour le paragraphe suivant",
          "Formatting": "Mise en page",
          "Numbering and Bullets": "Numérotation et puces",
          "Numbering": "Numérotage",
          "Update Field": "Champ de mise à jour",
          "Edit Field": "Modifier le champ",
          "Bookmark": "Signet",
          "Page Setup": "Mise en page",
          "No bookmarks found": "Aucun signet trouvé",
          "Number format tooltip information": "Format numérique à un niveau: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Par exemple, Chapitre% 1. affichera la numérotation comme </br> Chapitre 1. Article </br> Chapitre 2. Article </br> ... </br> Chapitre N. Article </br> </br> Format numérique à plusieurs niveaux: </ br > [PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Par exemple,% 1.% 2. affichera la numérotation comme </br> 1.1. Article </br> 1.2. Article </br>… </br> 1.N. Article",
          "Format": "Format",
          "Create New Style": "Créer un nouveau style",
          "Modify Style": "Modifier le style",
          "New": "Nouveau",
          "Bullets": "Balles",
          "Use bookmarks": "Utiliser des signets",
          "Table of Contents": "Table des matières",
          "row": "Ligne",
          "ok": "Valider"
      },
      "drawing": {},
      "rich-text-editor": {
          "alignments": "alignements",
          "justifyleft": "justifier à gauche",
          "justifycenter": "justifierCentre",
          "justifyright": "justifierDroit",
          "justifyfull": "justifierFull",
          "fontname": "fontName",
          "fontsize": "taille de police",
          "fontcolor": "couleur de la police",
          "backgroundcolor": "Couleur de l'arrière plan",
          "clearformat": "clearFormat",
          "clearall": "tout effacer",
          "unorderedlist": "liste non ordonnée",
          "orderedlist": "commandéListe",
          "createlink": "createLink",
          "openlink": "ouvrir le lien",
          "editlink": "editLink",
          "removelink": "removeLink",
          "openimagelink": "ouvrir le lien",
          "editimagelink": "editLink",
          "removeimagelink": "removeLink",
          "image": "image",
          "replace": "remplacer",
          "align": "aligner",
          "caption": "légende",
          "remove": "retirer",
          "insertlink": "insertLink",
          "display": "afficher",
          "alttext": "altText",
          "dimension": "dimension",
          "fullscreen": "plein écran",
          "maximize": "maximiser",
          "minimize": "minimiser",
          "lowercase": "minuscule",
          "uppercase": "majuscule",
          "print": "impression",
          "formats": "formats",
          "sourcecode": "code source",
          "preview": "Aperçu",
          "viewside": "vue",
          "insertcode": "insérer le code",
          "justifyLeft": "Alignez à gauche",
          "justifyCenter": "Aligner le centre",
          "justifyRight": "Aligner à droite",
          "justifyFull": "Aligner Justifier",
          "fontName": "Nom de police",
          "fontSize": "Taille de police",
          "fontColor": "Couleur de la police",
          "backgroundColor": "Couleur de l'arrière plan",
          "bold": "audacieux",
          "italic": "italique",
          "underline": "souligner",
          "strikethrough": "barré",
          "cut": "Couper",
          "copy": "copie",
          "paste": "pâte",
          "indent": "tiret",
          "outdent": "dépassé",
          "undo": "annuler",
          "redo": "refaire",
          "superscript": "exposant",
          "subscript": "indice",
          "createLink": "Insérer un lien hypertexte",
          "openLink": "Ouvrir le lien",
          "editLink": "Modifier le lien",
          "removeLink": "Supprimer le lien",
          "insertLink": "Insérer un lien",
          "altText": "Texte alternatif",
          "lowerCase": "Minuscules",
          "upperCase": "Majuscules",
          "insertCode": "Insérer le code",
          "linkText": "Afficher le texte",
          "linkTooltipLabel": "Info-bulle",
          "linkWebUrl": "Adresse web",
          "linkurl": "http://example.com",
          "linkOpenInNewWindow": "Ouvre le lien dans une nouvelle fenêtre",
          "linkHeader": "Insérer un lien",
          "dialogInsert": "Insérer",
          "dialogCancel": "Annuler",
          "dialogUpdate": "Mise à jour",
          "imageHeader": "Insérer une image",
          "imageLinkHeader": "Vous pouvez également fournir un lien à partir du Web",
          "mdimageLink": "Veuillez fournir une URL pour votre image",
          "imageUploadMessage": "Déposer l'image ici ou parcourir pour télécharger",
          "imageDeviceUploadMessage": "Cliquez ici pour télécharger",
          "imageAlternateText": "Texte alternatif",
          "alternateHeader": "Texte alternatif",
          "browse": "Feuilleter",
          "imageUrl": "http://example.com/image.png",
          "imageCaption": "Légende",
          "imageSizeHeader": "Taille de l'image",
          "imageHeight": "la taille",
          "imageWidth": "Largeur",
          "textPlaceholder": "Entrez du texte",
          "tblhead": "1X1",
          "inserttablebtn": "Insérer un tableau",
          "tabledialogHeader": "Insérer un tableau",
          "tableWidth": "Largeur",
          "cellpadding": "Rembourrage de cellule",
          "cellspacing": "Espacement des cellules",
          "columns": "Le nombre de colonnes",
          "rows": "Nombre de rangées",
          "tableRows": "Lignes du tableau",
          "tableColumns": "Colonnes du tableau",
          "tableCellHorizontalAlign": "Alignement horizontal des cellules du tableau",
          "tableCellVerticalAlign": "Alignement vertical des cellules du tableau",
          "createTable": "Créer un tableau",
          "removeTable": "Supprimer le tableau",
          "tableHeader": "En-tête de tableau",
          "tableRemove": "Tableau Supprimer",
          "tableCellBackground": "Arrière-plan de la cellule du tableau",
          "tableEditProperties": "Propriétés d'édition de tableau",
          "styles": "modes",
          "insertColumnLeft": "Insérer une colonne à gauche",
          "insertColumnRight": "Insérer une colonne à droite",
          "deleteColumn": "Supprimer la colonne",
          "insertRowBefore": "Insérer une ligne avant",
          "insertRowAfter": "Insérer une ligne après",
          "deleteRow": "Supprimer la ligne",
          "tablerows": "tableRows",
          "tablecolumns": "tableColonnes",
          "tablecellhorizontalalign": "tableCellHorizontalAlign",
          "tablecellverticalalign": "tableCellVerticalAlign",
          "createtable": "créerTable",
          "removetable": "supprimerTable",
          "tableheader": "tableHeader",
          "tableremove": "tableSupprimer",
          "tablecellbackground": "tableCelluleArrière-Plan",
          "tableeditproperties": "tableEditProperties",
          "insertcolumnleft": "insertColumnLeft",
          "insertcolumnright": "insertColumnRight",
          "deletecolumn": "supprimerColonne",
          "aligntop": "Aligner en haut",
          "alignmiddle": "Aligner au milieu",
          "alignbottom": "Aligner en bas",
          "insertrowbefore": "insertRowBefore",
          "insertrowafter": "insertRowAfter",
          "deleterow": "Supprimer la ligne"
      },
      "drop-down-base": {
          "noRecordsTemplate": "Aucun enregistrement trouvé",
          "actionFailureTemplate": "La demande a échoué"
      },
      "chart": {
          "Zoom": "Zoom",
          "ZoomIn": "Agrandir",
          "ZoomOut": "Dézoomer",
          "Reset": "Réinitialiser",
          "Pan": "La poêle",
          "ResetZoom": "Réinitialiser le zoom"
      },
      "maps": {
          "Zoom": "Zoom",
          "ZoomIn": "Agrandir",
          "ZoomOut": "Dézoomer",
          "Reset": "Réinitialiser",
          "Pan": "Déplacer",
          "ResetZoom": "Réinitialiser le zoom"
      },
      "drop-down-tree": {
          "noRecordsTemplate": "Aucun enregistrement trouvé",
          "actionFailureTemplate": "Demande échoué",
          "overflowCountTemplate": "+ $ {count} de plus ..",
          "totalCountTemplate": "$ {count} sélectionné"
      },
      "PdfViewer": {
          "PdfViewer": "Visionneuse PDF",
          "Cancel": "Annuler",
          "Download file": "Télécharger un fichier",
          "Download": "Télécharger",
          "Enter Password": "Ce document est protégé par mot de passe. Veuillez entrer un mot de passe.",
          "File Corrupted": "Fichier corrompu",
          "File Corrupted Content": "Le fichier est corrompu et ne peut pas être ouvert.",
          "Fit Page": "Ajuster la page",
          "Fit Width": "Largeur de coupe",
          "Automatic": "Automatique",
          "Go To First Page": "Afficher la première page",
          "Invalid Password": "Mot de passe incorrect. Veuillez réessayer.",
          "Next Page": "Afficher la page suivante",
          "OK": "D'accord",
          "Open": "Fichier ouvert",
          "Page Number": "Numéro de page actuel",
          "Previous Page": "Afficher la page précédente",
          "Go To Last Page": "Afficher la dernière page",
          "Zoom": "Zoom",
          "Zoom In": "Agrandir",
          "Zoom Out": "Dézoomer",
          "Page Thumbnails": "Vignettes de page",
          "Bookmarks": "Signets",
          "Print": "Imprimer le fichier",
          "Password Protected": "Mot de passe requis",
          "Copy": "Copie",
          "Text Selection": "Outil de sélection de texte",
          "Panning": "Mode panoramique",
          "Text Search": "Rechercher du texte",
          "Find in document": "Rechercher dans le document",
          "Match case": "Cas de correspondance",
          "Apply": "Appliquer",
          "GoToPage": "Aller à la page",
          "No matches": "La visionneuse a terminé la recherche du document. Aucune autre correspondance n'a été trouvée",
          "No Text Found": "Aucun texte trouvé",
          "Undo": "annuler",
          "Redo": "Refaire",
          "Annotation": "Ajouter ou modifier des annotations",
          "Highlight": "Texte surligné",
          "Underline": "Souligner le texte",
          "Strikethrough": "Texte barré",
          "Delete": "Supprimer l'annotation",
          "Opacity": "Opacité",
          "Color edit": "Changer de couleur",
          "Opacity edit": "Changer l'opacité",
          "Highlight context": "Surligner",
          "Underline context": "Souligner",
          "Strikethrough context": "Grève",
          "Server error": "Le service Web n'écoute pas. Visionneuse PDF dépend du service Web pour toutes ses fonctionnalités. Veuillez démarrer le service Web pour continuer.",
          "Open text": "Ouvert",
          "First text": "Première page",
          "Previous text": "Page précédente",
          "Next text": "Page suivante",
          "Last text": "Dernière page",
          "Zoom in text": "Agrandir",
          "Zoom out text": "Dézoomer",
          "Selection text": "Sélection",
          "Pan text": "La poêle",
          "Print text": "Impression",
          "Search text": "Chercher",
          "Annotation Edit text": "Modifier l'annotation",
          "Line Thickness": "Épaisseur de ligne",
          "Line Properties": "Propriétés de ligne",
          "Start Arrow": "Flèche de départ",
          "End Arrow": "Flèche de fin",
          "Line Style": "Style de ligne",
          "Fill Color": "La couleur de remplissage",
          "Line Color": "Couleur de la ligne",
          "None": "Aucun",
          "Open Arrow": "Ouvert",
          "Closed Arrow": "Fermé",
          "Round Arrow": "Rond",
          "Square Arrow": "Carré",
          "Diamond Arrow": "diamant",
          "Butt": "Bout",
          "Cut": "Couper",
          "Paste": "Pâte",
          "Delete Context": "Supprimer",
          "Properties": "Propriétés",
          "Add Stamp": "Ajouter un tampon",
          "Add Shapes": "Ajouter des formes",
          "Stroke edit": "Changer la couleur du trait",
          "Change thickness": "Modifier l'épaisseur de la bordure",
          "Add line": "Ajouter une ligne",
          "Add arrow": "Ajouter une flèche",
          "Add rectangle": "Ajouter un rectangle",
          "Add circle": "Ajouter un cercle",
          "Add polygon": "Ajouter un polygone",
          "Add Comments": "Ajoutez des commentaires",
          "Comments": "commentaires",
          "No Comments Yet": "Aucun commentaire pour l'instant",
          "Accepted": "Accepté",
          "Completed": "Terminé",
          "Cancelled": "Annulé",
          "Rejected": "Rejeté",
          "Leader Length": "Longueur de ligne de repère",
          "Scale Ratio": "Rapport d'échelle",
          "Calibrate": "Étalonner",
          "Calibrate Distance": "Calibrer la distance",
          "Calibrate Perimeter": "Calibrer le périmètre",
          "Calibrate Area": "Étalonner la zone",
          "Calibrate Radius": "Calibrer le rayon",
          "Calibrate Volume": "Calibrer le volume",
          "ok": "Valider",
          "Depth": "Profondeur",
          "Closed": "Fermé",
          "Round": "Tour",
          "Square": "Carré",
          "Diamond": "diamant",
          "Edit": "Éditer",
          "Comment": "Commenter",
          "Comment Panel": "Panneau de commentaires",
          "Set Status": "Définir le statut",
          "Post": "Poster",
          "Page": "Page",
          "Add a comment": "Add a comment",
          "Add a reply": "Ajouter une réponse",
          "Import Annotations": "Importer des annotations à partir d'un fichier JSON",
          "Export Annotations": "Exporter l'annotation vers un fichier JSON",
          "Export XFDF": "Exporter l'annotation vers un fichier XFDF",
          "Import XFDF": "Importer des annotations à partir d'un fichier XFDF",
          "Add": "Ajouter",
          "Clear": "Dégager",
          "Bold": "Audacieux",
          "Italic": "Italique",
          "Strikethroughs": "Barré",
          "Underlines": "Souligner",
          "Superscript": "Exposant",
          "Subscript": "Indice",
          "Align left": "Alignez à gauche",
          "Align right": "Aligner à droite",
          "Center": "Centre",
          "Justify": "Justifier",
          "Font color": "Couleur de la police",
          "Text Align": "Aligner le texte",
          "Text Properties": "Le style de police",
          "SignatureFieldDialogHeaderText": "Ajouter une signature",
          "HandwrittenSignatureDialogHeaderText": "Ajouter une signature",
          "InitialFieldDialogHeaderText": "Ajouter une initiale",
          "HandwrittenInitialDialogHeaderText": "Ajouter une initiale",
          "Draw Ink": "Dessiner de l'encre",
          "Create": "Créer",
          "Font family": "Famille de polices",
          "Font size": "Taille de police",
          "Free Text": "Texte libre",
          "Import Failed": "Type de fichier JSON ou nom de fichier non valide; veuillez sélectionner un fichier JSON valide",
          "Import PDF Failed": "Le type de fichier PDF n'est pas valide ou le fichier PDF n'existe pas. Veuillez sélectionner un fichier PDF valide",
          "File not found": "Le fichier JSON importé est introuvable à l'emplacement souhaité",
          "Export Failed": "L'action d'exportation des annotations a échoué; veuillez vous assurer que les annotations sont ajoutées correctement",
          "of": "de",
          "Dynamic": "Dynamique",
          "Standard Business": "Entreprise standard",
          "Sign Here": "Signez ici",
          "Custom Stamp": "Tampon personnalisé",
          "Enter Signature as Name": "Entrez votre nom",
          "Draw-hand Signature": "DESSINER",
          "Type Signature": "TAPER",
          "Upload Signature": "TÉLÉCHARGER",
          "Browse Signature Image": "FEUILLETER",
          "Save Signature": "Enregistrer la signature"
      },
      "documenteditor": {
          "Table": "Table",
          "Row": "Table",
          "Cell": "Cellule",
          "Ok": "D'accord",
          "Cancel": "Annuler",
          "Size": "Taille",
          "Preferred Width": "Largeur préférée",
          "Points": "Points",
          "Percent": "Pour cent",
          "Measure in": "Mesurer en",
          "Alignment": "Alignement",
          "Left": "La gauche",
          "Center": "Centre",
          "Right": "Droite",
          "Justify": "Justifier",
          "Indent from left": "Retrait de gauche",
          "Borders and Shading": "Bordures et ombrage",
          "Options": "Les options",
          "Specify height": "Précisez la hauteur",
          "At least": "Au moins",
          "Exactly": "Exactement",
          "Row height is": "La hauteur de ligne est",
          "Allow row to break across pages": "Autoriser la ligne à traverser les pages",
          "Repeat as header row at the top of each page": "Répéter comme ligne d'en-tête en haut de chaque page",
          "Vertical alignment": "Alignement vertical",
          "Top": "Haut",
          "Bottom": "Bas",
          "Default cell margins": "Marges de cellule par défaut",
          "Default cell spacing": "Espacement des cellules par défaut",
          "Allow spacing between cells": "Autoriser l'espacement entre les cellules",
          "Cell margins": "Marges des cellules",
          "Same as the whole table": "Identique à toute la table",
          "Borders": "Les frontières",
          "None": "Aucun",
          "Style": "Style",
          "Width": "Largeur",
          "Height": "la taille",
          "Letter": "Lettre",
          "Tabloid": "Tabloïde",
          "Legal": "Légal",
          "Statement": "Déclaration",
          "Executive": "Exécutif",
          "A3": "A3",
          "A4": "A4",
          "A5": "A5",
          "B4": "B4",
          "B5": "B5",
          "Custom Size": "Format personnalisé",
          "Different odd and even": "Différent impair et pair",
          "Different first page": "Première page différente",
          "From edge": "Du bord",
          "Header": "Entête",
          "Footer": "Bas de page",
          "First Page Header": "En-tête de la première page",
          "First Page Footer": "Pied de page de la première page",
          "Even Page Header": "En-tête de page pair",
          "Even Page Footer": "Pied de page pair",
          "Odd Page Header": "En-tête de page impaire",
          "Odd Page Footer": "Pied de page impair",
          "Margin": "Marges",
          "Paper": "Papier",
          "Layout": "Disposition",
          "Orientation": "Orientation",
          "Landscape": "Paysage",
          "Portrait": "Portrait",
          "Show page numbers": "Afficher les numéros de page",
          "Right align page numbers": "Aligner à droite les numéros de page",
          "Nothing": "Rien",
          "Tab leader": "Repère de tabulation",
          "Show levels": "Afficher les niveaux",
          "Use hyperlinks instead of page numbers": "Utilisez des hyperliens au lieu des numéros de page",
          "Build table of contents from": "Créer une table des matières à partir de",
          "Styles": "modes",
          "Available styles": "Styles disponibles",
          "TOC level": "Niveau COT",
          "Heading": "Titre",
          "Heading 1": "Rubrique 1",
          "Heading 2": "Rubrique 2",
          "Heading 3": "Rubrique 3",
          "Heading 4": "Rubrique 4",
          "Heading 5": "Rubrique 5",
          "Heading 6": "Rubrique 6",
          "List Paragraph": "Paragraphe de liste",
          "Normal": "Ordinaire",
          "Outline levels": "Niveaux de contour",
          "Table entry fields": "Champs de saisie de table",
          "Modify": "Modifier",
          "Color": "Couleur",
          "Setting": "Réglage",
          "Box": "Boîte",
          "All": "Tout",
          "Custom": "Personnalisé",
          "Preview": "Aperçu",
          "Shading": "Ombres",
          "Fill": "Remplir",
          "Apply To": "Postuler à",
          "Table Properties": "Propriétés du tableau",
          "Cell Options": "Options de cellule",
          "Table Options": "Options de table",
          "Insert Table": "Insérer un tableau",
          "Number of columns": "Le nombre de colonnes",
          "Number of rows": "Nombre de rangées",
          "Text to display": "Texte à afficher",
          "ScreenTip text": "Text to display",
          "Address": "Adresse",
          "Insert Hyperlink": "Insérer un lien hypertexte",
          "Edit Hyperlink": "Modifier l'hyperlien",
          "Insert": "Insérer",
          "General": "Général",
          "Indentation": "Échancrure",
          "Before text": "Avant le texte",
          "Special": "Spécial",
          "First line": "Première ligne",
          "Hanging": "Pendaison",
          "After text": "Après le texte",
          "By": "Par",
          "Before": "Avant",
          "Line Spacing": "Interligne",
          "After": "Après",
          "At": "À",
          "Multiple": "Plusieurs",
          "Spacing": "Espacement",
          "Define new Multilevel list": "Définir une nouvelle liste à plusieurs niveaux",
          "List level": "Niveau liste",
          "Choose level to modify": "Choisissez le niveau à modifier",
          "Level": "Niveau",
          "Number format": "Format de nombre",
          "Number style for this level": "Style de nombre pour ce niveau",
          "Enter formatting for number": "Entrez la mise en forme du nombre",
          "Start at": "Commencer à",
          "Restart list after": "Redémarrez la liste après",
          "Position": "Position",
          "Text indent at": "Retrait du texte à",
          "Aligned at": "Aligné à",
          "Follow number with": "Suivez le numéro avec",
          "Tab character": "Caractère de tabulation",
          "Space": "Espace",
          "Arabic": "arabe",
          "UpRoman": "UpRoman",
          "LowRoman": "LowRoman",
          "UpLetter": "UpLetter",
          "LowLetter": "LowLetter",
          "Number": "Nombre",
          "Leading zero": "Zéro en tête",
          "Bullet": "Balle",
          "Ordinal": "Ordinal",
          "Ordinal Text": "Texte ordinal",
          "For East": "Pour l'Est",
          "No Restart": "Pas de redémarrage",
          "Font": "Police de caractère",
          "Font style": "Le style de police",
          "Underline style": "Souligner le style",
          "Font color": "Couleur de la police",
          "Effects": "Effets",
          "Strikethrough": "Barré",
          "Superscript": "Exposant",
          "Subscript": "Indice",
          "Double strikethrough": "Barré double",
          "Regular": "Ordinaire",
          "Bold": "Audacieux",
          "Italic": "Italique",
          "Cut": "Couper",
          "Copy": "Copie",
          "Paste": "Pâte",
          "Hyperlink": "Hyperlien",
          "Open Hyperlink": "Ouvrir l'hyperlien",
          "Copy Hyperlink": "Copier l'hyperlien",
          "Remove Hyperlink": "Supprimer l'hyperlien",
          "Paragraph": "Paragraphe",
          "Linked Style": "Lié (paragraphe et caractère)",
          "Character": "Personnage",
          "Merge Cells": "Fusionner des cellules",
          "Insert Above": "Insérer au-dessus",
          "Insert Below": "Insérer ci-dessous",
          "Insert Left": "Insérer à gauche",
          "Insert Right": "Insérer à droite",
          "Delete": "Supprimer",
          "Delete Table": "Supprimer le tableau",
          "Delete Row": "Supprimer la ligne",
          "Delete Column": "Supprimer la colonne",
          "File Name": "Nom de fichier",
          "Format Type": "Type de format",
          "Save": "Sauvegarder",
          "Navigation": "La navigation",
          "Results": "Résultats",
          "Replace": "Remplacer",
          "Replace All": "Remplace tout",
          "We replaced all": "Nous avons remplacé tous",
          "Find": "Trouver",
          "No matches": "Pas de correspondance",
          "All Done": "Terminé",
          "Result": "Résultat",
          "of": "de",
          "instances": "instances",
          "with": "avec",
          "Click to follow link": "Cliquez pour suivre le lien",
          "Continue Numbering": "Continuer la numérotation",
          "Bookmark name": "Nom du signet",
          "Close": "Fermer",
          "Restart At": "Redémarrer à",
          "Properties": "Propriétés",
          "Name": "Nom",
          "Style type": "Type de style",
          "Style based on": "Style basé sur",
          "Style for following paragraph": "Style pour le paragraphe suivant",
          "Formatting": "Mise en page",
          "Numbering and Bullets": "Numérotation et puces",
          "Numbering": "Numérotage",
          "Update Field": "Champ de mise à jour",
          "Edit Field": "Modifier le champ",
          "Bookmark": "Signet",
          "Page Setup": "Mise en page",
          "No bookmarks found": "Aucun signet trouvé",
          "Number format tooltip information": "Format numérique à un niveau: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Par exemple, 'Chapitre% 1.' affichera la numérotation comme </br> Chapitre 1. Article </br> Chapitre 2. Article </br>… </br> Chapitre N. Article </br> </br> Format numérique à plusieurs niveaux: </br> [ PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Par exemple, '% 1.% 2.' affichera la numérotation comme </br> 1.1. Article </br> 1.2. Article </br>… </br> 1.N. Article",
          "Format": "Format",
          "Create New Style": "Créer un nouveau style",
          "Modify Style": "Modifier le style",
          "New": "Nouveau",
          "Bullets": "Balles",
          "Use bookmarks": "Utiliser des signets",
          "Table of Contents": "Table des matières",
          "AutoFit": "Ajustement automatique",
          "AutoFit to Contents": "Ajustement automatique au contenu",
          "AutoFit to Window": "Ajustement automatique à la fenêtre",
          "Fixed Column Width": "Largeur de colonne fixe",
          "Reset": "Réinitialiser",
          "Match case": "Cas de correspondance",
          "Whole words": "Mots entiers",
          "Add": "Ajouter",
          "Go To": "Aller à",
          "Search for": "Rechercher",
          "Replace with": "Remplacer par",
          "TOC 1": "TOC 1",
          "TOC 2": "TOC 2",
          "TOC 3": "TOC 3",
          "TOC 4": "TOC 4",
          "TOC 5": "TOC 5",
          "TOC 6": "TOC 6",
          "TOC 7": "TOC 7",
          "TOC 8": "TOC 8",
          "TOC 9": "TOC 9",
          "Right-to-left": "De droite à gauche",
          "Left-to-right": "De gauche à droite",
          "Direction": "Direction",
          "Table direction": "Direction de la table",
          "Indent from right": "Retrait à droite",
          "Contextual Spacing": "N'ajoutez pas d'espace entre les paragraphes des mêmes styles",
          "Password Mismatch": "Le mot de passe ne correspond pas",
          "Restrict Editing": "Restreindre l'édition",
          "Formatting restrictions": "Restrictions de formatage",
          "Allow formatting": "Autoriser la mise en forme",
          "Editing restrictions": "Modification des restrictions",
          "Read only": "Lecture seulement",
          "Exceptions Optional": "Exceptions (facultatif)",
          "Select Part Of Document And User": "Sélectionnez des parties du document et choisissez les utilisateurs autorisés à les modifier librement.",
          "Everyone": "Toutes les personnes",
          "More users": "Plus d'utilisateurs",
          "Add Users": "Ajouter des utilisateurs",
          "Enforcing Protection": "Oui, commencez à appliquer la protection",
          "Start Enforcing Protection": "Commencer à appliquer la protection",
          "Enter User": "Entrez l'utilisateur",
          "Users": "Utilisateurs",
          "Enter new password": "Entrez un nouveau mot de passe",
          "Reenter new password to confirm": "Entrez à nouveau le nouveau mot de passe pour confirmer",
          "Your permissions": "Vos autorisations",
          "Protected Document": "Ce document est protégé contre toute modification involontaire. Vous pouvez le modifier dans cette région.",
          "FormFieldsOnly": "Vous ne pouvez remplir des formulaires que dans cette région.",
          "CommentsOnly": "Vous ne pouvez insérer des commentaires que dans cette région.",
          "ReadOnlyProtection": "Vous pouvez éditer dans cette région.",
          "Stop Protection": "Arrêter la protection",
          "Password": "Mot de passe",
          "Spelling Editor": "Éditeur d'orthographe",
          "Spelling": "Orthographe",
          "Spell Check": "Vérification orthographique",
          "Underline errors": "Souligner les erreurs",
          "Ignore": "Ignorer",
          "Ignore All": "Ignorer tout",
          "Add to Dictionary": "Ajouter au dictionnaire",
          "Change": "Changement",
          "Change All": "Change tout",
          "Suggestions": "Suggestions",
          "The password is incorrect": "Le mot de passe est incorrect",
          "Error in establishing connection with web server": "Erreur lors de l'établissement de la connexion avec le serveur Web",
          "Highlight the regions I can edit": "Mettez en surbrillance les régions que je peux modifier",
          "Show All Regions I Can Edit": "Afficher toutes les régions que je peux modifier",
          "Find Next Region I Can Edit": "Rechercher la prochaine région que je peux modifier",
          "Keep source formatting": "Conserver le formatage source",
          "Match destination formatting": "Faire correspondre la mise en forme de la destination",
          "Text only": "Texte seulement",
          "Comments": "commentaires",
          "Type your comment": "Tapez votre commentaire",
          "Post": "Publier",
          "Reply": "Répondre",
          "New Comment": "Nouveau commentaire",
          "Edit": "Éditer",
          "Resolve": "Résoudre",
          "Reopen": "Rouvrir",
          "No comments in this document": "Aucun commentaire dans ce document",
          "more": "plus",
          "Type your comment here": "Tapez votre commentaire ici",
          "Next Comment": "Commentaire suivant",
          "Previous Comment": "Commentaire précédent",
          "Un-posted comments": "Commentaires non publiés",
          "Discard Comment": "Ajout de commentaires non publiés. Si vous continuez, ce commentaire sera ignoré.",
          "No Headings": "Aucune rubrique trouvée!",
          "Add Headings": "Ce document n'a pas de titres. Veuillez ajouter des titres et réessayer.",
          "More Options": "Plus d'options",
          "Click to see this comment": "Cliquez pour voir ce commentaire",
          "Drop Down Form Field": "Champ de formulaire déroulant",
          "Drop-down items": "Articles déroulants",
          "Items in drop-down list": "Éléments dans la liste déroulante",
          "ADD": "AJOUTER",
          "REMOVE": "RETIRER",
          "Field settings": "Réglages sur place",
          "Tooltip": "Info-bulle",
          "Drop-down enabled": "Liste déroulante activée",
          "Check Box Form Field": "Champ de formulaire de case à cocher",
          "Check box size": "Cochez la taille",
          "Auto": "Auto",
          "Default value": "Valeur par défaut",
          "Not checked": "Non vérifié",
          "Checked": "Vérifié",
          "Check box enabled": "Case à cocher activée",
          "Text Form Field": "Champ de formulaire texte",
          "Type": "Type",
          "Default text": "Texte par défaut",
          "Maximum length": "Longueur maximale",
          "Text format": "Format texte",
          "Fill-in enabled": "Remplissage activé",
          "Default number": "Numéro par défaut",
          "Default date": "Date par défaut",
          "Date format": "Format de date",
          "Merge Track": "This action wont be marked as change. Do you want to continue?",
          "UnTrack": "Cannot be tracked",
          "Accept": "Accept",
          "Reject": "Reject",
          "Previous Changes": "Previous Changes",
          "Next Changes": "Next Changes",
          "Inserted": "Inserted",
          "Deleted": "Deleted",
          "Changes": "Changes",
          "Accept all": "Accept all",
          "Reject all": "Reject all",
          "No changes": "No changes",
          "Accept Changes": "Accept Changes",
          "Reject Changes": "Reject Changes",
          "User": "Utilisateur",
          "View": "Vue",
          "Insertion": "Insertion",
          "Deletion": "Effacement",
          "All caps": "Toutes en majuscules",
          "This region is locked by": "Cette région est verrouillée par",
          "Lock": "Fermer à clé",
          "Unlock": "Ouvrir",
          "Already locked": "La région sélectionnée ou une partie de la région est déjà verrouillée par un autre utilisateur",
          "1, 2, 3, ...": "1, 2, 3, ...",
          "a, b, c, ...": "a, b, c, ...",
          "A, B, C, ...": "A, B, C, ...",
          "I, II, III, ...": "I, II, III, ...",
          "i, ii, iii, ...": "je, ii, iii, ...",
          "Text Form": "Formulaire de texte",
          "Check Box": "Case à cocher",
          "DropDown": "Menu déroulant",
          "Form Fields": "Champs de formulaire",
          "Fillin enabled": "Remplissage activé",
          "Multiple Comment": "Veuillez poster votre commentaire",
          "InsertFootnote": "InsérerNote de bas de page",
          "InsertEndnote": "InsérerEndnote",
          "Footnote": "note de bas de page",
          "Endnote": "Note de fin de document",
          "Click to View/Edit Footnote": "Cliquez pour afficher / modifier la note de bas de page",
          "Click to View/Edit Endnote": "Cliquez pour afficher / modifier la note de fin",
          "InsertAsRows": "Insérer en tant que nouvelles lignes",
          "InsertAsColumns": "Insérer en tant que nouvelles colonnes",
          "OverwriteCells": "Écraser les cellules",
          "NestTable": "Table gigogne",
          "No suggestions": "Pas de suggestions",
          "More Suggestion": "Plus de suggestions",
          "Ignore Once": "Ignorer une fois",
          "Keep With Next": "Continuer avec le suivant",
          "Keep Lines Together": "Gardez les lignes ensemble",
          "WidowControl": "Contrôle des veuves/orphelins",
          "Indents and Spacing": "Retraits et espacement",
          "Line and Page Breaks": "Sauts de ligne et de page",
          "Pagination": "Pagination",
          "Single": "Seul",
          "DashSmallGap": "DashSmallGap",
          "DashDot": "TiretPoint",
          "Double": "Double",
          "ThinThickSmallGap": "MinceÉpaisPetitEspace",
          "ThickThinSmallGap": "ÉpaisMincePetitEspace",
          "ThickThinMediumGap": "ÉpaisMinceMoyenÉcart",
          "ThickThinLargeGap": "ÉpaisMinceGrandEspace",
          "SingleWavy": "SimpleWavy",
          "DoubleWavy": "DoubleOndulé",
          "Inset": "Encart",
          "DashLargeGap": "DashLargeGap",
          "Dot": "Point",
          "DashDotDot": "TiretPointPoint",
          "Triple": "Tripler",
          "ThinThickThinSmallGap": "MinceÉpaisMincePetitEspace",
          "ThinThickThinMediumGap": "MinceÉpaisMinceMoyenÉcart",
          "ThinThickThinLargeGap": "MinceÉpaisMinceLargeGap",
          "DashDotStroked": "TiretPointTracé",
          "Engrave3D": "Graver3D",
          "Thick": "Épais",
          "Outset": "Début",
          "Emboss3D": "Emboss3D",
          "ThinThickLargeGap": "MinceÉpaisGrandEspace",
          "ThinThickMediumGap": "FinÉpaisMoyenÉcart",
          "Number of rows must be between 1 and 32767.": "Le nombre de lignes doit être compris entre 1 et 32767.",
          "Number of columns must be between 1 and 63.": "Le nombre de colonnes doit être compris entre 1 et 63.",
          "Unlimited": "Illimité",
          "Regular text": "Texte normal",
          "Date": "Date",
          "Uppercase": "Majuscule",
          "Lowercase": "Minuscule",
          "FirstCapital": "FirstCapital",
          "TitleCase": "Casse du titre",
          "Filling in forms": "Remplir des formulaires",
          "px": "pixels",
          "Tracked changes": "Modifications suivies",
          "TrackChangesOnly": "Vous pouvez effectuer des modifications dans cette région, mais toutes les modifications seront suivies.",
          "RemovedIgnore": "Voulez-vous supprimer les exceptions ignorées ?",
          "RemovedIgnoreExceptions": "Si vous apportez cette modification à la protection du document, Word ignorera toutes les exceptions dans ce document.",
          "Information": "Informations",
          "Yes": "Oui",
          "No": "Ligne",
          "row": "Ligne",
          "You may format text only with certain styles": "Vous ne pouvez formater le texte qu'avec certains styles.",
          "Ignore all": "Ignorer tout",
          "ok": "Valider"
      },
      "documenteditorcontainer": {
          "New": "Nouveau",
          "Open": "Ouvert",
          "Undo": "annuler",
          "Redo": "Refaire",
          "Image": "Image",
          "Table": "Table",
          "Link": "Lien",
          "Bookmark": "Signet",
          "Table of Contents": "Table des matières",
          "HEADING - - - - 1": "RUBRIQUE - - - - 1",
          "HEADING - - - - 2": "RUBRIQUE - - - - 2",
          "HEADING - - - - 3": "RUBRIQUE - - - - 3",
          "Header": "Entête",
          "Footer": "Bas de page",
          "Page Setup": "Mise en page",
          "Page Number": "Numéro de page",
          "Break": "Pause",
          "Find": "Trouver",
          "Local Clipboard": "Presse-papiers local",
          "Restrict Editing": "Restreindre l'édition",
          "Upload from computer": "Télécharger d'un ordinateur",
          "By URL": "Par URL",
          "Page Break": "Saut de page",
          "Section Break": "Saut de section",
          "Header And Footer": "En-tête et pied de page",
          "Options": "Les options",
          "Levels": "Les niveaux",
          "Different First Page": "Première page différente",
          "Different header and footer for odd and even pages": "En-tête et pied de page différents pour les pages paires et impaires.",
          "Different Odd And Even Pages": "Pages paires et impaires différentes",
          "Different header and footer for first page": "En-tête et pied de page différents pour la première page.",
          "Position": "Position",
          "Header from Top": "En-tête du haut",
          "Footer from Bottom": "Pied de page du bas",
          "Distance from top of the page to top of the header": "Distance du haut de la page au haut de l'en-tête.",
          "Distance from bottom of the page to bottom of the footer": "Distance du bas de la page au bas du pied de page.",
          "Aspect ratio": "Ratio d'aspect",
          "W": "W",
          "H": "H",
          "Width": "Largeur",
          "Height": "la taille",
          "Text": "Texte",
          "Paragraph": "Paragraphe",
          "Fill": "Remplir",
          "Fill color": "La couleur de remplissage",
          "Border Style": "Style de bordure",
          "Outside borders": "Hors frontières",
          "All borders": "Toutes les frontières",
          "Inside borders": "Frontières intérieures",
          "Left border": "Bordure gauche",
          "Inside vertical border": "Bordure verticale intérieure",
          "Right border": "Bordure droite",
          "Top border": "Bordure supérieure",
          "Inside horizontal border": "Bordure horizontale intérieure",
          "Bottom border": "Bordure inférieure",
          "Border color": "Couleur de la bordure",
          "Border width": "Largeur de la bordure",
          "Cell": "Cellule",
          "Merge cells": "Fusionner des cellules",
          "Insert Or Delete": "Insérer / supprimer",
          "Insert columns to the left": "Insérer des colonnes à gauche",
          "Insert columns to the right": "Insérer des colonnes à droite",
          "Insert rows above": "Insérer des lignes au-dessus",
          "Insert rows below": "Insérer des lignes ci-dessous",
          "Delete rows": "Supprimer des lignes",
          "Delete columns": "Supprimer des colonnes",
          "Cell Margin": "Marge cellulaire",
          "Top": "Haut",
          "Bottom": "Bas",
          "Left": "La gauche",
          "Right": "Droite",
          "Align Text": "Aligner le texte",
          "Align top": "Aligner le haut",
          "Align bottom": "Aligner en bas",
          "Align center": "Aligner le centre",
          "Number of heading or outline levels to be shown in table of contents": "Nombre de niveaux de titre ou de plan à afficher dans la table des matières.",
          "Show page numbers": "Afficher les numéros de page",
          "Show page numbers in table of contents": "Afficher les numéros de page dans la table des matières.",
          "Right align page numbers": "Aligner à droite les numéros de page",
          "Right align page numbers in table of contents": "Alignez à droite les numéros de page dans la table des matières.",
          "Use hyperlinks": "Utilisez des hyperliens",
          "Use hyperlinks instead of page numbers": "Utilisez des hyperliens au lieu des numéros de page.",
          "Font": "Police de caractère",
          "Font Size": "Taille de police",
          "Font color": "Couleur de la police",
          "Text highlight color": "Couleur de surbrillance du texte",
          "Clear all formatting": "Effacer toute mise en forme",
          "Bold Tooltip": "Gras (Ctrl + B)",
          "Italic Tooltip": "Italique (Ctrl + I)",
          "Underline Tooltip": "Souligné (Ctrl + U)",
          "Strikethrough": "Barré",
          "Superscript Tooltip": "Exposant (Ctrl + Shift ++)",
          "Subscript Tooltip": "Indice (Ctrl + =)",
          "Align left Tooltip": "Aligner à gauche (Ctrl + L)",
          "Center Tooltip": "Centre (Ctrl + E)",
          "Align right Tooltip": "Aligner à droite (Ctrl + R)",
          "Justify Tooltip": "Justifier (Ctrl + J)",
          "Decrease indent": "Diminuer le retrait",
          "Increase indent": "Augmenter le retrait",
          "Line spacing": "Interligne",
          "Bullets": "Balles",
          "Numbering": "Numérotage",
          "Styles": "modes",
          "Manage Styles": "Gérer les styles",
          "Page": "Page",
          "of": "de",
          "Fit one page": "Ajuster une page",
          "Spell Check": "Vérification orthographique",
          "Underline errors": "Souligner les erreurs",
          "Fit page width": "Ajuster la largeur de la page",
          "Update": "Mise à jour",
          "Cancel": "Annuler",
          "Insert": "Insérer",
          "No Border": "Pas de frontière",
          "Create a new document": "Créez un nouveau document.",
          "Open a document": "Ouvrez un document.",
          "Undo Tooltip": "Annule la dernière opération (Ctrl + Z).",
          "Redo Tooltip": "Refait la dernière opération (Ctrl + Y).",
          "Insert inline picture from a file": "Insérez une image en ligne à partir d'un fichier.",
          "Insert a table into the document": "Insérer un tableau dans le document",
          "Create Hyperlink": "Créez un lien dans votre document pour un accès rapide aux pages Web et aux fichiers (Ctrl + K).",
          "Insert a bookmark in a specific place in this document": "Insérez un signet à un endroit spécifique de ce document.",
          "Provide an overview of your document by adding a table of contents": "Donnez un aperçu de votre document en ajoutant une table des matières.",
          "Add or edit the header": "Ajoutez ou modifiez l'en-tête.",
          "Add or edit the footer": "Ajoutez ou modifiez le pied de page.",
          "Open the page setup dialog": "Ouvrez la boîte de dialogue de mise en page.",
          "Add page numbers": "Ajoutez des numéros de page.",
          "Find Text": "Recherchez du texte dans le document (Ctrl + F).",
          "Toggle between the internal clipboard and system clipboard": "Basculez entre le presse-papiers interne et le presse-papiers système. </br> L'accès au presse-papiers système via un script est refusé en raison de la politique de sécurité des navigateurs. Au lieu de cela, </br> 1. Vous pouvez activer le presse-papiers interne pour couper, copier et coller dans le composant. </br> 2. Vous pouvez utiliser les raccourcis clavier (Ctrl + X, Ctrl + C et Ctrl + V) pour couper , copiez et collez avec le presse-papiers du système.",
          "Current Page Number": "Numéro de page actuel dans le document. Cliquez ou appuyez sur pour parcourir une page spécifique.",
          "Read only": "Lecture seulement",
          "Protections": "Protections",
          "Error in establishing connection with web server": "Erreur lors de l'établissement de la connexion avec le serveur Web",
          "Single": "Célibataire",
          "Double": "Double",
          "New comment": "Nouveau commentaire",
          "Comments": "commentaires",
          "Print layout": "Disposition d'impression",
          "Web layout": "mise en page Web",
          "Text Form": "Formulaire texte",
          "Check Box": "Case à cocher",
          "DropDown": "Menu déroulant",
          "Update Fields": "Champs de mise à jour",
          "Update cross reference fields": "Mettre à jour les champs de référence croisée",
          "Hide properties pane": "Masquer le volet des propriétés",
          "Show properties pane": "Afficher le volet des propriétés",
          "Form Fields": "Champs de formulaire",
          "Track Changes": "Gardez une trace des modifications apportées au document",
          "TrackChanges": "Suivi des modifications",
          "AllCaps": "Toutes en majuscules",
          "Change case Tooltip": "Changer de cas",
          "Insert Footnote": "Insérer une note de bas de page",
          "Insert Endnote": "Insérer une note de fin",
          "Footnote Tooltip": "Insérer une note de bas de page (Alt + Ctrl + F).",
          "Endnote Tooltip": "Insérer une note de fin (Alt + Ctrl + D).",
          "UPPERCASE": "MAJUSCULE",
          "No color": "Sans couleur",
          "Top margin": "Marge supérieure",
          "Bottom margin": "Marge inférieure",
          "Left margin": "Marge de gauche",
          "Right margin": "Marge droite",
          "Normal": "Normal",
          "Heading": "Titre",
          "Heading 1": "Rubrique 1",
          "Heading 2": "Rubrique 2",
          "Heading 3": "Rubrique 3",
          "Heading 4": "Rubrique 4",
          "Heading 5": "Rubrique 5",
          "Heading 6": "Rubrique 6",
          "ZoomLevelTooltip": "Le niveau de zoom. Cliquez ou appuyez sur pour ouvrir les options de zoom.",
          "None": "Aucun",
          "Borders": "Les frontières"
      },
      "carousel": {
          "nextSlide": "Diapositive suivante",
          "of": "de",
          "pauseSlideTransition": "Interrompre la transition de diapositive",
          "playSlideTransition": "Lire la transition de diapositive",
          "previousSlide": "Diapositive précédente",
          "slide": "Glisser",
          "slideShow": "Diaporama"
      }
  }
}