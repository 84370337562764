import axiosRequest from "@/AuthenticatedRoute";
import {
    ADD_DISBURSEMENT,
    UPDATE_DISBURSEMENT,
} from "@/store/mutations-types";
import {
    // buildFormData,
    // downloadURI,
    getObjectKey,
    isDefined,
    // isEmptyObject,
    // stringIsNotBlank
} from "@/utils";

const state={
    allDisbursements:[],
    currentDisburs:null,
    editState:false,
}

const getters={}

const mutations={
    [ADD_DISBURSEMENT](state,{disburs}){
        for(const dis of disburs){
            if(state.allDisbursements.filter(element=>element.codeId === dis.codeId).length===0){
                state.allDisbursements.push(dis)
            }
        }
    },

    [UPDATE_DISBURSEMENT](state,disburs){
        
        state.allDisbursements=state.allDisbursements.map(element=>{
            if(element.codeId === disburs.codeId){
                return disburs
            }
            else{
                return element
            }           
        })
    },

    setNull(state){
        state.allDisbursements=[]
    },
    
    setCurrentDisburs(state,disObj){
        state.currentDisburs=disObj
    },

    setEditState(state,resp){
        state.editState=resp
    }
}

const actions={

    setCurrentDisburs({commit},dis){
        commit('setCurrentDisburs',dis)
    },

    setEditState({commit},resp){
        commit('setEditState',resp)
    },

    setNullDisbursements({commit}){
        commit('setNull')
    },

    async loadDisbursements({rootState,commit}){
        return new Promise((resolve,reject) => {
            axiosRequest({
                method: 'GET',
                url: '/project/sub/fees/list/'+rootState.project.project.slug,
            }).then(response => {
                const data = response.data.data

            if(isDefined(data)) {
                const disburs = buildDisbursements(data)

                commit(ADD_DISBURSEMENT, {disburs})
            }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async addDisbursement({commit},data){
        return new Promise((resolve,reject) => {
            axiosRequest({
                method: 'POST',
                url: '/project/sub/fees/',
                data:data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                const data = response.data.data


            if(isDefined(data)) {
                const disburs = buildDisbursement(data)

                commit(ADD_DISBURSEMENT, {disburs:[disburs]})
            }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    async updateDisbursementFile({commit},data){
       
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'POST',
                url:'/project/sub/fees/file/'+data.get('codeId'),
                data:data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                const data = response.data.data



            if(isDefined(data)) {
                const disburs = buildDisbursement(data)

                commit(UPDATE_DISBURSEMENT,disburs)
            }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

    }
    ,


    async updateDisbursement({commit},data){
       
        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'POST',
                url:'/project/sub/fees/'+data.get('codeId'),
                data:data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {
                const data = response.data.data

            if(isDefined(data)) {
                const disburs = buildDisbursement(data)

                commit(UPDATE_DISBURSEMENT,disburs)
            }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

    },
    



}
function buildDisbursements(data){
    let result = []

    for (let item of data) {
        result.push(buildDisbursement(item))
    }

    return result

}


function buildDisbursement(item,returnArray = false){

    let result = null
    
    if(isDefined(item)){
        result = {
            "codeId":getObjectKey(item, 'codeId'),
            "title": getObjectKey(item, 'title'),
            "isBilled": getObjectKey(item, 'isBilled')===true?'Facturé':'Pas facturé',
            "note": getObjectKey(item, 'note'),
            "amount": getObjectKey(item, 'amount'),
            "date": getObjectKey(item, 'date'),
            
        }

        if(isDefined(getObjectKey(item,'team'))){
            result['team']=item.team
            result['teamName']=item.team.name
        }
        
        if(isDefined(getObjectKey(item,'owner'))){
            result['owner']=item.owner
            result['ownerName']=item.owner.user.firstname+" "+item.owner.user.lastname  
        }

        if(isDefined(getObjectKey(item,'fileName'))){
            result['fileName']=item.fileName
            result['slug']=item.slug
            result['fileType']=item.fileType
        }


        if(returnArray){
            result = [result]
        }
    }

    return result

}

export default{
    namespaced:true,
    state,
    getters,mutations,actions
}
