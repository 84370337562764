
import axiosRequest from '../../AuthenticatedRoute';
import {getParsedEvents,getTransformedEventsFromOutlook,getEventOnSchedule,transformEventOutlookToEventType,getTransformedEventFromSchedule} from '../../utils/utilsEvents'


const state= {//init data
        
        eventsInBd:[],
        eventsFromCloud:[],
        mergedEvents:[],
        idOutlook:null,
        subscriptions:{
            create:'',
            update:'',
            delete:''
        }
    }
const getters= {


        getIdOutlook(state){
            return state.idOutlook
        },
        getMergedEvents(state){
            return state.mergedEvents
        },
        getEventsFromCloud(state){
            return state.eventsFromCloud
        },
     
    }
const mutations= {
        setEventsInBd(state,events){
            if(!events){
                return
            }
            var parsedEvents= getParsedEvents(events)
            parsedEvents.forEach(element=>{
                element.StartTime=new Date(element.StartTime)
                element.EndTime=new Date(element.EndTime)
            })
            state.eventsInBd=parsedEvents
            
        },
        setEventsFromCloud(state,events){
            state.eventsFromCloud=getTransformedEventsFromOutlook(events)
        },

        setEventsInMerge(state,events){
            if(state.eventsFromCloud.length===0){
                state.mergedEvents=state.eventsInBd
            }
            else{
                state.mergedEvents=events
            }
            
        },
        updateStateAfterMix(state,newData){
            state.eventsInBd.forEach(event=>{
                state.eventsFromCloud=newData(element=>{
                    if(event.idOutlook!=='' && event.idOutlook===element.idOutlook){
                        return false
                    }
                    return true
                })
            })
        },
        addEventFromCloud(state,event){
            if(state.eventsFromCloud.filter(element=>element.idOutlook===event.idOutlook).length===0){
                state.eventsFromCloud.push(event)
            }
        },
        
        addEventInBd(state,event){
            if(state.eventsInBd.length===1 && Object.prototype.hasOwnProperty.call(state.eventsInBd[0],'IsAllDay')){
                state.eventsInBd[0]=event
                return
            }
            //Bug of Syncfusion ,when we declare a datasource to some component 
            //it adds an    object automatically sometimes despite of response from server,so to avoid it I have to clear the data by synfusion attribute
            state.eventsInBd=state.eventsInBd.filter(element=>{
                return !Object.prototype.hasOwnProperty.call(element,'IsAllDay')
            })
            if(state.eventsInBd.filter(element=>element.Id===event.Id).length===0){
                state.eventsInBd.push(event)
            }
        },

        updateEventInBd(state,event){
            state.eventsInBd=state.eventsInBd.map(element=>{
                if(event.Id===element.Id){
                    return event
                }
                return element
            })
        },

        updateEventFromCloud(state,event){
            state.eventsFromCloud=state.eventsFromCloud.map(element=>{
                if(event.idOutlook===element.idOutlook){
                    return event
                }
                return element
            })
        },

        deleteEventInBd(state,event){
            state.eventsInBd=state.eventsInBd.filter(element=>{
                return event.Id!=element.Id
            })
        },
        
        deleteEventFromCloud(state,event){
            state.eventsFromCloud=state.eventsFromCloud.filter(element=>{
                return element.idOutlook!=event.idOutlook
            })
        },
        
        setIdOutlook(state,id){
            state.idOutlook=id
        },

        setSubscriptionDelete(state,id){
            state.subscriptions.delete=id
        },

        setSubscriptionUpdate(state,id){
            state.subscriptions.update=id
        },
        setSubscriptionCreate(state,id){
            state.subscriptions.create=id
        },

      
    }
const actions= {
        fetchEvents({dispatch,rootState,commit}){//Observable maybe would be better to use
            return new Promise((resolve,reject)=>{
               axiosRequest({
                    method:'GET',
                    url:  '/event/list',//API
                    headers: {
                        Authorization: 'Bearer '+rootState.account.userInfos.token
                    }
                }).then(response=>{
                    const data = response.data.data
                    if(data!==undefined){
                        commit('setEventsInBd',data)
                        dispatch('mergeEvents')
                    }
                    resolve(response)
                }).catch(error=>{
                    reject(error)
                })
            })
        },
        updateEvent({rootState,commit},data){
            data['id']=data['id'].toString()
            return new Promise((resolve,reject)=>{
                axiosRequest({
                    method:'PUT',
                    url:'/event/update/'+ data.id,//API
                    headers: {
                        Authorization: 'Bearer '+rootState.account.userInfos.token
                    },
                    data:data
                }).then(response=>{
                    const data=response.data.data
                    const transformData=getEventOnSchedule(data)
                    commit('updateEventInBd',transformData)
                    resolve()
                }).catch(error=>{
                    reject(error)
                })
            })
        },
        createEvent({rootState,commit},data){
        
            return new Promise(
                (resolve,reject)=>{
                    axiosRequest({
                        method:'POST',
                        url:'/event/create',//API
                        headers: {
                            Authorization: 'Bearer '+rootState.account.userInfos.token
                        },
                        data:data
                    }).then(response=>{
                        const data=response.data.data
                        const transformData=getEventOnSchedule(data)
                        
                        commit('addEventInBd',transformData)
                        resolve(transformData)
                    }).catch(error=>{
                        reject(error)
                    })
                }
            )
        },
        deleteEvent({rootState,commit},id){
            return new Promise(
                (resolve,reject)=>{
                    axiosRequest({
                        method:'DELETE',
                        url:'/event/delete/'+id,//API
                        headers: {
                            Authorization: 'Bearer '+rootState.account.userInfos.token
                        },
                    }).then(response=>{
                        commit('deleteEventInBd',id)
                        resolve(response)
                    }).catch(error=>{
                        reject(error)
                    })
                }
            )
        },
        getEventsOutlook({commit}){
            return new Promise(
                (resolve,reject)=>{
                    this._vm.$msal.acquireToken({scopes: ["user.read", "Calendars.Read","Calendars.ReadWrite.Shared","Calendars.ReadWrite"]})//make user consent of the permission
                .then(()=>{
                    this._vm.$msal.msGraph('https://graph.microsoft.com/v1.0/me/events?$select=subject,body,bodyPreview,organizer,attendees,start,end,location,recurrence   &$top=1000')
                .then(response=>{
                    const data=response.body.value
                    commit("setEventsFromCloud",data)
                    resolve()
                }).catch(error=>{
                    reject(error)
                })})
            }
        )},
        createEventOutlook({commit},data){
            return new Promise(
                (resolve,reject)=>{
                this._vm.$msal.msGraph({url:'https://graph.microsoft.com/v1.0/me/events',method:'POST',data:data}).then(
                    response=>{
                        const transformData=transformEventOutlookToEventType(response.body)
                        commit("addEventFromCloud",transformData)
                        commit("setIdOutlook",transformData.idOutlook)
                        resolve()
                    }).catch(error=>{
                    reject(error)
                })
            })
        },
        updateEventOutlook({commit},data){  
            return new Promise(
                (resolve,reject)=>{
                    this._vm.$msal.acquireToken({scopes: ["user.read", "Calendars.Read","Calendars.ReadWrite.Shared","Calendars.ReadWrite"]})
                this._vm.$msal.msGraph({
                    url:"https://graph.microsoft.com/v1.0/me/events/"+data.id,method:"PATCH",data:data
                }).then(
                    response=>{
                        const transformData=transformEventOutlookToEventType(response.body)
                        commit('updateEventFromCloud',transformData)
                        resolve()
                    }).catch(error=>{
                    reject(error)
                })
            })
        },
        deleteEventOutlook({commit},id){
            return new Promise(
                (resolve,reject)=>{
                
                this._vm.$msal.msGraph({
                    url:"https://graph.microsoft.com/v1.0/me/events/"+id,method:"DELETE"})
                .then(
                    response=>{
                        if(response.status===204)
                            commit('deleteEventFromCloud',id)
                        resolve()})
                .catch(error=>{
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        deleteSubscriptionById({_},id){
            return new Promise(
                (resolve,reject)=>{
                    this._vm.$msal.msGraph({
                        url:'https://graph.microsoft.com/v1.0/subscriptions/'+id,method:'DELETE'
                    }).then(resp=>resolve(resp)).catch(err=>reject(err))
                }
            )
        },

        deleteAllSubscriptions({dispatch}){
            return new Promise(
                (resolve,reject)=>{
                    this._vm.$msal.acquireToken({scopes: ["user.read", "Calendars.Read","Calendars.ReadWrite.Shared","Calendars.ReadWrite"]})//make user consent of the permission
                .then(()=>{
                    this._vm.$msal.msGraph('https://graph.microsoft.com/v1.0/subscriptions/')
                .then(async response=>{
                    const data=response.body.value
                    var promises=[]
                    data.forEach(element => {
                        promises.push(dispatch('deleteSubscriptionById',element.id))
                    });
                    await Promise.all(promises)
                    resolve(response)
                }).catch(error=>{
                    reject(error)
                })})
            })
        },
        deleteSubscriptionCreate({state,commit}){
            return new Promise((resolve,reject)=>{
                this._vm.$msal.msGraph({
                    url:'https://graph.microsoft.com/v1.0/subscriptions/'+state.subscriptions.create,method:'DELETE'
                }).then(response=>{
                    commit('setSubscriptionCreate','')
                    resolve(response)
                }).catch(error=>{
                    reject(error)
                })
            })
        },
        deleteSubscriptionUpdate({state,commit}){
            return new Promise((resolve,reject)=>{
                this._vm.$msal.msGraph({
                    url:'https://graph.microsoft.com/v1.0/subscriptions/'+state.subscriptions.update,method:'DELETE'
                }).then(response=>{
                    commit('setSubscriptionUpdate','')
                    resolve(response)
                }).catch(error=>{
                    reject(error)
                })
            })
        },
        deleteSubscriptionDelete({state,commit}){
            return new Promise((resolve,reject)=>{
                this._vm.$msal.msGraph({
                    url:'https://graph.microsoft.com/v1.0/subscriptions/'+state.subscriptions.delete,method:'DELETE'
                }).then(response=>{
                    commit('setSubscriptionDelete','')
                    resolve(response)
                }).catch(error=>{
                    reject(error)
                })
            })
        },
        subscriptionCreate({commit}){
            return new Promise((resolve,reject)=>{
                let currentDate = new Date();

                // adding one hour to the date because our token expires in 1 h
                currentDate.setHours(currentDate.getHours() + 1);
                const data={
                    changeType:'created',
                    notificationUrl:process.env.VUE_APP_API_URL+'/event/webhook/create/notificationURL',
                    resource:'/me/events',
                    expirationDateTime:currentDate,
                    clientState:'createdEventLegalProd',
                    latestSupportedTlsVersion:"v1_2"
                }
                this._vm.$msal.msGraph({
                    url:'https://graph.microsoft.com/v1.0/subscriptions/',method:'POST',data:data
                }).then(response=>{
                    const data=response.body
                    commit('setSubscriptionCreate',data.id)
                    resolve(response)
                }).catch(error=>reject(error))
            })
        },
        subscriptionUpdate({commit}){
            return new Promise((resolve,reject)=>{
                let currentDate = new Date();

                // adding one hour to the date because our token expires in 1 h
                currentDate.setHours(currentDate.getHours() + 1);
                const data={
                    changeType:'updated',
                    notificationUrl:process.env.VUE_APP_API_URL+'/event/webhook/update/notificationURL',
                    resource:'/me/events',
                    expirationDateTime:currentDate,
                    clientState:'updatedEventLegalProd',
                    latestSupportedTlsVersion:"v1_2"
                }
                this._vm.$msal.msGraph({
                    url:'https://graph.microsoft.com/v1.0/subscriptions/',method:'POST',data:data
                }).then(response=>{
                    const data=response.body
                    commit('setSubscriptionUpdate',data.id)
                    resolve(response)
                }).catch(error=>reject(error))
            })
        },
        subscriptionDelete({commit}){
            return new Promise((resolve,reject)=>{
                let currentDate = new Date();

                // adding one hour to the date because our token expires in 1 h
                currentDate.setHours(currentDate.getHours() + 1);
                const data={
                    changeType:'deleted',
                    notificationUrl:process.env.VUE_APP_API_URL+'/event/webhook/delete/notificationURL',
                    resource:'/me/events',
                    expirationDateTime:currentDate,
                    clientState:'deletedEventLegalProd',
                    latestSupportedTlsVersion:"v1_2"
                }
                this._vm.$msal.msGraph({
                    url:'https://graph.microsoft.com/v1.0/subscriptions/',method:'POST',data:data
                }).then(response=>{
                    const data=response.body
                    commit('setSubscriptionDelete',data.id)
                    resolve(response)
                }).catch(error=>reject(error))
            })
        },
        // eslint-disable-next-line no-unused-vars
        getEventFromListening({commit,dispatch},data){
            var id=data.id
            var switcher=data.switcher
            if(switcher==='create'||switcher==='update'){
                return new Promise(
                    (resolve,reject)=>{
                    this._vm.$msal.msGraph({url:'https://graph.microsoft.com/v1.0/me/events/'+id,method:'GET'}).then(
                        response=>{
                            const transformData=transformEventOutlookToEventType(response.body)
                            if(switcher==='create'){
                                commit("addEventFromCloud",transformData)
                            }
                            if(switcher==='update'){
                                commit('updateEventFromCloud',transformData)
                            }
                            //if we don't merge the case when we change event is soft doesnt change it in outlook 
                            dispatch('mergeEvents')
                            resolve(transformData)
                        }).catch((error)=>{
                           reject(error)
                        })
                })
            }
            if (switcher==='delete'){
                commit('deleteEventFromCloud',id)
                dispatch('mergeEvents')
            }
        },
        mergeEvents({state,dispatch,commit}){
            if(state.eventsFromCloud.length===0){
                commit('setEventsInMerge',[])
            }else{
                dispatch('mixEventsAfterAuth')
            }
        },
        demergeEvents({state,commit}){
            commit('setEventsInMerge',state.eventsInBd)
            commit('setEventsFromCloud',[])
        },

        mixEventsAfterAuth({state,dispatch,commit}){
            const dataInBdFilteredOnlyOutlookEvents=state.eventsInBd.filter(element=>{return element.idOutlook!=''})
            let dataInBdFilteredHashMap=dataInBdFilteredOnlyOutlookEvents.reduce(function(map,obj){
                map.set(obj.idOutlook,obj)
                return map
            },new Map())
            let dataOutlookHashMap=state.eventsFromCloud.reduce(function(map,obj){
                map.set(obj.idOutlook,obj)
                return map
            },new Map())

            for(let i=0;i<dataInBdFilteredOnlyOutlookEvents.length;i++){
                let element=dataInBdFilteredOnlyOutlookEvents[i]
                if(!dataOutlookHashMap.has(element.idOutlook)&&!element.idOutlook!==''){
                    dispatch('deleteEvent',element.Id)
                    commit('deleteEventInBd',element)
                    commit('deleteEventFromCloud',element)
                }
                else if(dataOutlookHashMap.get(element.idOutlook)){
                    const tempElementOutlook=dataOutlookHashMap.get(element.idOutlook)
                    const tempElementDb=dataInBdFilteredHashMap.get(element.idOutlook)
                    let stDatMs=new Date(tempElementOutlook.StartTime)
                    let stDatDb=new Date(tempElementDb.StartTime)
                    let endDatMs=new Date(tempElementOutlook.EndTime)
                    let endDatDb=new Date(tempElementDb.EndTime)
                    let locationMs=tempElementOutlook.Location
                    let locationDb=tempElementDb.Location
                    let subjectMs=tempElementOutlook.Subject
                    let subjectDb=tempElementDb.Subject
                    let descriptionMs=tempElementOutlook.Description
                    let descriptionDb=tempElementOutlook.Description
                    let linkMs=tempElementOutlook.Link
                    let linkDb=tempElementDb.Link
                    let eventTypeMs=tempElementOutlook.EventType
                    let eventTypeDb=tempElementDb.EventType
                    if(!locationDb){
                        locationDb=''
                    }
                    if(!descriptionDb){
                        descriptionDb=''
                    }
                    if(!linkDb){
                        linkDb=''
                    }
                    if(!subjectDb){
                        subjectDb=''
                    }
                    
                    if(!subjectMs){
                        subjectMs=''
                    }
                    if(!locationMs){
                        locationMs=''
                    }
                    if(!descriptionMs){
                        descriptionMs=''
                    }
                    if(!linkMs){
                        linkMs=''
                    }
                    if(stDatMs.getTime()!=stDatDb.getTime()||endDatMs.getTime()!=endDatDb.getTime()
                    ||(locationMs!=locationDb)||(subjectMs!=subjectDb)||(descriptionMs!=descriptionDb)||(linkMs!=linkDb)||(eventTypeDb!=eventTypeMs)){
                        let transformedEventFromOutlookToDb=getTransformedEventFromSchedule(tempElementOutlook)
                        transformedEventFromOutlookToDb['id']=tempElementDb.Id.toString()
                        ||(locationMs!=locationDb)||(!subjectMs!=subjectDb)||(descriptionMs!=descriptionDb)||(linkMs!=linkDb)
                        dispatch('updateEvent',transformedEventFromOutlookToDb)
                    }
                }
                dataOutlookHashMap.delete(element.idOutlook)
            }
            const arrFromObj=Array.from(dataOutlookHashMap.values())
            commit('setEventsInMerge',[...arrFromObj,...state.eventsInBd])
        }
}
const namespaced=true


export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}