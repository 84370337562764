import {
    buildFormData,
    getObjectKey,
    isDefined,

} from "@/utils";
import {
    getDateObjectFromDDMMYYY, dateObjFromMinusSign


} from '../../utils/timeFormatUtils'
import axiosRequest from "@/AuthenticatedRoute";
const state = {
    itemsHonors: [
        {
            title: '',
            year: '',
            description: '',
            document: [],
            action: '',
            adjusted: false,
            addState: true
        }
    ],
    itemsCredentials: [
        {
            title: '',
            date: new Date(),
            customer: '',
            description: '',
            action: '',
            adjusted: false,
            addState: true
        }
    ],
    itemsCV: [
        {
            fonction: '',
            cabinet: '',
            timeRange: [new Date(), new Date()],
            description: '',
            action: '',
            adjusted: false,
            addState: true
        }
    ],
    itemsAcadem: [
        {
            title: '',
            university: '',
            timeRange: [new Date(), new Date()],
            description: '',
            action: '', adjusted: false,
            addState: true
        }
    ],
    itemBio: {
        biography: ''
    },
    itemsPubs: [
        {
            title: '',
            url: '',
            publishedAt: new Date(),
            description: '',
            adjusted: false,
            action: '',
            addState:true,
            changingUrl:false
        }
    ]



}
const getters = {}
const mutations = {
    setBioState(state, data) {
        state.itemBio.biography = data.biography
    },
    addRowPub(state) {
        state.itemsPubs.push(
            {
                title: '',
                url: '',
                publishedAt: new Date(),
                description: '',
                adjusted: false,
                action: '',
                addState:true,
                changingUrl:false
            }
        )
    },
    deleteRowPub(state, row) {
        state.itemsPubs.splice(row, 1)
    },

    addPub(state, pub) {
        state.itemsPubs = state.itemsPubs.map(element => {
            if (element.title === pub.title && pub.university === element.university) {
                element.id = pub.id
                element.adjusted = true
                element.addState=false
            }
            return element
        })
    },
    updatePub(state, pub) {
        state.itemsPubs = state.itemsPubs.map(element => {
            if (element.id === pub.id) {
                element.adjusted = true
                element.changeState=false
                return pub
            } return element
        })
    },
    deletePub(state, exp) {
        state.itemsPubs = state.itemsPubs.filter(element => element.id !== exp.id);
    },

    setPubs(state, pubs) {
        if(pubs.length!==0){
            state.itemsPubs = pubs;
        }
    },


    addRowCV(state) {
        state.itemsCV.push(
            {
                fonction: '',
                cabinet: '',
                // timeRange: [new Date(), new Date()],
                startDate: null,
                endDate: null,
                description: '',
                action: '',
                adjusted: false,
                addState: true
            }
        )
    },
    addRowHonors(state) {
        state.itemsHonors.push(
            {
                title: '',
                year: '',
                description: '',
                document: [],
                action: '',
                adjusted: false,
                addState: true
            }
        )
    },
    addRowCredentials(state) {
        state.itemsCredentials.push(
            {
                title: '',
                date: new Date(),
                customer: '',
                description: '',
                action: '',
                adjusted: false,
                addState: true
            }
        )
    },
    addRowAcadems(state) {
        state.itemsAcadem.push(
            {
                title: '',
                university: '',
                timeRange: [new Date(), new Date()],
                description: '',
                action: '', adjusted: false,
                addState: true
            }
        )
    },
    changingUrlPubItem(state,index){
        state.itemsPubs[index].changingUrl=!state.itemsPubs[index].changingUrl
    },
    setDocumentInHonors(state, { index, document }) {
        if(getObjectKey(state.itemsHonors[index],'documentUploaded')){
            state.itemsHonors[index].documentUploaded.type=''
            state.itemsHonors[index].documentUploaded.name=document.name
        }
        state.itemsHonors[index].document = document
    },
    setTimeRange(state) {
        for (let i = 0; i < state.itemsCV.length; i++) {
            let element = state.itemsCV[i]
            if (!element.timeRange) {
                element['timeRange'] = [element.startDate, element.endDate]
            }
        }
        for (let i = 0; i < state.itemsAcadem.length; i++) {
            let element = state.itemsAcadem[i]
            if (!element.timeRange) {
                element['timeRange'] = [element.startDate, element.endDate]
            }
        }
    },


    deleteRowCV(state, row) {
        state.itemsCV.splice(row, 1)
    },
    deleteRowHonors(state, row) {
        state.itemsHonors.splice(row, 1)
    },
    deleteRowCredentials(state, row) {
        state.itemsCredentials.splice(row, 1)
    },
    deleteRowAcadems(state, row) {
        state.itemsAcadem.splice(row, 1)
    },


    filterNoAdjustedAcadem(state) {
        state.itemsAcadem = state.itemsAcadem.filter(element => {
            if (element.adjusted) {
                return true
            }
            return false
        })
    },
    filterNoAdjustedCV(state) {
        state.itemsCV = state.itemsCV.filter(element => {
            if (element.adjusted) {
                return true
            }
            return false
        })
    },
    filterNoAdjustedHonor(state) {
        state.itemsHonors = state.itemsHonors.filter(element => {
            if (element.adjusted) {
                return true
            }
            return false
        })
    },
    filterNoAdjustedCredential(state) {
        state.itemsCredentials = state.itemsCredentials.filter(element => {
            if (element.adjusted) {
                return true
            }
            return false
        })
    },
    filterNoAdjustedPub(state) {
        state.itemsPubs = state.itemsPubs.filter(element => {
            if (element.adjusted) {
                return true
            }
            return false
        })
    },

    addAcademExp(state, exp) {
        state.itemsAcadem = state.itemsAcadem.map(element => {
            if (element.title === exp.title && exp.university === element.university) {
                element.id = exp.id
                element.adjusted = true
            }
            element['timeRange'] = [dateObjFromMinusSign(element.startDate), dateObjFromMinusSign(element.endDate)]
            return element
        })
    },



    updateAcademItem(state,{value,index}){
        state.itemsAcadem[index].timeRange=value
    },
    updateCVItem(state,{value,index,name}){
        state.itemsCV[index][name]=value
    },
    updateCredentialItem(state,{value,index}){
        state.itemsCredentials[index].date=value
        state.itemsCredentials[index].timeRange=[value,value]
    },
    updatePubItem(state,{value,index}){
        state.itemsPubs[index].publishedAt=value

    },


    updateAcademExp(state, exp) {
        state.itemsAcadem = state.itemsAcadem.map(element => {
            if (element.id === exp.id) {
                exp.adjusted = true
                exp.changeState = false
                return exp
            }
            return element
        })
    },
    changeAcadem(state, index) {
        if (state.itemsAcadem[index].addState) {
            return
        }
        state.itemsAcadem[index].adjusted = false
        state.itemsAcadem[index].changeState = true
    },
    changeCV(state, index) {
        if (state.itemsCV[index].addState) {
            return
        }
        state.itemsCV[index].adjusted = false
        state.itemsCV[index].changeState = true
    },
    changeCredential(state, index) {
        if (state.itemsCredentials[index].addState) {
            return
        }
        state.itemsCredentials[index].adjusted = false
        state.itemsCredentials[index].changeState = true
    },
    changeHonor(state, index) {
        if (state.itemsHonors[index].addState) {
            return
        }
        state.itemsHonors[index].adjusted = false
        state.itemsHonors[index].changeState = true
    },
    changePub(state, index) {
        if (state.itemsPubs[index].addState) {
            return
        }
        state.itemsPubs[index].adjusted = false
        state.itemsPubs[index].changeState = true
    },
    deleteAcademExp(state, exp) {
        state.itemsAcadem = state.itemsAcadem.filter(element => element.id !== exp.id);
    },

    setAcademExp(state, exps) {
        if(exps.length!==0){
            state.itemsAcadem = exps; 
        }
    },

    addCV(state, cv) {        
        state.itemsCV = state.itemsCV.map(element => {
            if (element.fonction === cv.fonction && cv.cabinet === element.cabinet) {
                element.id = cv.id
                element.adjusted = true
                element.addState=false
            }
            // element['timeRange'] = [dateObjFromMinusSign(element.startDate), dateObjFromMinusSign(element.endDate)]
            return element
        })
    },

    updateCV(state, cv) {
        state.itemsCV = state.itemsCV.map(element => {
            if (element.id === cv.id) {
                cv.adjusted = true
                cv.changeState = false
                return cv
            } return element
        })
    },

    deleteCV(state, cv) {
        state.itemsCV = state.itemsCV.filter(element => element.id !== cv.id);
    },
    setCVs(state, cvs) {
        if(cvs.length!==0){
            state.itemsCV = cvs;
        }
    },

    addCredential(state, credential) {

        state.itemsCredentials = state.itemsCredentials.map(element => {
            if (element.fonction === credential.fonction && credential.cabinet === element.cabinet) {
                element.id = credential.id
                element.adjusted = true
                element.addState=false
            }
            if(!Object.prototype.hasOwnProperty.call(element,'timeRange')){
                element['timeRange'] = [dateObjFromMinusSign(element.startDate), dateObjFromMinusSign(element.endDate)]
            }
            return element
        })
    },

    updateCredential(state, credential) {
        state.itemsCredentials = state.itemsCredentials.map(element => {
            if (element.id === credential.id) {
                credential.adjusted = true
                credential.changeState = false
                return credential
            } return element
        })
    },
    deleteCredential(state, credential) {
        state.itemsCredentials = state.itemsCredentials.filter(element => element.id !== credential.id);
    },

    setCredentials(state, credentials) {
        if(credentials.length!==0){
            state.itemsCredentials = credentials;
        }
    },
    addHonor(state, honor) {

        state.itemsHonors = state.itemsHonors.map(element => {
            if (element.title === honor.title && parseInt(element.year)===honor.year) {
                return honor
            }
            return element
        })
    },


    updateHonor(state, honor) {
        state.itemsHonors = state.itemsHonors.map(element => {
            if (element.id === honor.id) {
                honor.adjusted = true
                honor.changeState = false
                return honor
            } return element
        })
    },
    deleteHonor(state, honor) {
        state.itemsHonors = state.itemsHonors.filter(element => element.id !== honor.id);
    },
    setHonors(state, honors) {
        if(honors.length!==0){
            state.itemsHonors = honors;
        }
    }
}
const actions = {
    createAcademicExp({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/academic-experience/',
                data: buildFormData(data),
                headers: { "Content-Type": "multipart/form-data" },
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit('addAcademExp', buildAcademExp(data))
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateAcademicExp({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: `/user/academic-experience/${data.id}`,
                data: buildFormData(data),
            })
                .then(response => {
                    const updatedData = response.data.data;

                    if (isDefined(updatedData)) {
                        commit('updateAcademExp', buildAcademExp(updatedData));
                        // Perform any additional actions upon successful update
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },


    deleteAcademicExp({ commit }, id) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/user/academic-experience/' + id,
            }).then(response => {
                commit('deleteAcademExp', id)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getListAcademicExp({ commit }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/user/academic-experience/list',
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit('setAcademExp', buildObjs(data, 4))
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // Create Professional Experience
    createProfessionalExp({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/professional-experience/',
                data: buildFormData(data, 'POST', false, true),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    const data = response.data.data;

                    if (isDefined(data)) {

                        commit('addCV', buildCV(data))
                        // Here you can perform any additional actions upon successful creation
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // Update CV
    updateProfessionalExp({ commit }, data) {        
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: `/user/professional-experience/${data.id}`,
                data: buildFormData(data, 'PUT', false, true),
            })
                .then(response => {
                    const updatedData = response.data.data;

                    if (isDefined(updatedData)) {

                        commit('updateCV', buildCV(updatedData));
                        // Perform any additional actions upon successful update
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // Delete Professional Experience
    deleteProfessionalExp({ commit }, id) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/user/professional-experience/' + id,
            })
                .then(response => {
  
                    // Here you can perform any additional actions upon successful deletion
                    commit('deleteCV', id)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // Get List of Professional Experience
    getListProfessionalExp({ commit }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/user/professional-experience/list',
            })
                .then(response => {
                    const data = response.data.data;

                    if (isDefined(data)) {
                        // Process the received professional experience list
                        commit('setCVs', buildObjs(data, 1))
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    updateBio({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/user/update',
                data: data,
            })
                .then(response => {
                    const data = response.data.data;

                    if (isDefined(data)) {

                        dispatch('account/updateBioInAcc', data, { root: true })
                        commit('setBioState', data);
                        // Here you can perform any additional actions upon successful creation
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    createCredential({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/credential/',
                data: buildFormData(data),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    const data = response.data.data;

                    if (isDefined(data)) {
   
                        commit('addCredential', buildCredential(data));
                        // Here you can perform any additional actions upon successful creation
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // Update Credential
    updateCredential({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: `/user/credential/${data.id}`,
                data: buildFormData(data),
            })
                .then(response => {
                    const updatedData = response.data.data;

                    if (isDefined(updatedData)) {
              
                        commit('updateCredential', buildCredential(updatedData));
                        // Perform any additional actions upon successful update
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },


    // Delete Credential
    deleteCredential({ commit }, id) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/user/credential/' + id,
            })
                .then(response => {
       
                    // Here you can perform any additional actions upon successful deletion
                    commit('deleteCredential', { id: id });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // Get List of Credentials
    getListCredentials({ commit }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/user/credential/list',
            })
                .then(response => {
                    const data = response.data.data;

                    if (isDefined(data)) {
                        // Process the received credential list
                        commit('setCredentials', buildObjs(data, 2));
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // Create Honor
    createHonor({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/distinction/',
                data: buildFormData(data),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    const data = response.data.data;
                    
                    if (isDefined(data)) {
      
                        commit('addHonor', buildHonor(data));
                        
                        // Here you can perform any additional actions upon successful creation
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // Update Honor
    updateHonor({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: `/user/distinction/${data.id}`,
                data: buildFormData(data),
            })
                .then(response => {
                    const updatedData = response.data.data;

                    if (isDefined(updatedData)) {
   
                        commit('updateHonor', buildHonor(updatedData));
                        // Perform any additional actions upon successful update
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    setBioInProfile({ commit }, bio) {
        commit('setBioState', { biography: bio })
    },

    // Delete Honor
    deleteHonor({ commit }, id) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/user/distinction/' + id,
            })
                .then(response => {
   
                    // Here you can perform any additional actions upon successful deletion
                    commit('deleteHonor', { id: id });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // Get List of Honors
    getListHonors({ commit }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/user/distinction/list',
            })
                .then(response => {
                    const data = response.data.data;

                    if (isDefined(data)) {
                        // Process the received honor list
                        commit('setHonors', buildObjs(data, 3));
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },


    // Create Honor
    createPub({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/user/publication/',
                data: buildFormData(data),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    const data = response.data.data;

                    if (isDefined(data)) {

                        commit('addPub', buildPub(data));
                        // Here you can perform any additional actions upon successful creation
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // Update Publication
    updatePub({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: `/user/publication/${data.id}`,
                data: buildFormData(data),
            })
                .then(response => {
                    const updatedData = response.data.data;

                    if (isDefined(updatedData)) {
     
                        commit('updatePub', buildPub(updatedData));
                        // Perform any additional actions upon successful update
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // Delete Honor
    deletePub({ commit }, id) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'DELETE',
                url: '/user/publication/' + id,
            })
                .then(response => {
                    // Here you can perform any additional actions upon successful deletion
                    commit('deletePub', { id: id });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // Get List of Honors
    getListPub({ commit }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/user/publication/list',
            })
                .then(response => {
                    const data = response.data.data;

                    if (isDefined(data)) {
                        // Process the received honor list
                        commit('setPubs', buildObjs(data, 5));
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};


function buildObjs(data, code) {
    let arr = []
    for (let record of data) {
        //1 cv,2 credential ,3 honor
        if (code === 1) {
            arr.push(buildCV(record))
        }
        if (code === 2) {
            arr.push(buildCredential(record))
        }
        if (code === 3) {
            arr.push(buildHonor(record))
        }
        if (code === 4) {
            arr.push(buildAcademExp(record))
        }
        if (code === 5) {
            arr.push(buildPub(record))
        }
    }
    return arr
}


function buildCV(cv) {
    return {
        fonction: getObjectKey(cv, 'fonction'),
        cabinet: getObjectKey(cv, 'cabinet'),
        description: getObjectKey(cv, 'description'),
        id: getObjectKey(cv, 'id'),
        action: '',
        startDate: getDateObjectFromDDMMYYY(cv.startDate),
        endDate: getDateObjectFromDDMMYYY(cv.endDate),
        isCurrent: cv.isCurrent,
        timeRange: [getDateObjectFromDDMMYYY(cv.startDate)
            , getDateObjectFromDDMMYYY(cv.endDate)],
        adjusted: true,
        addState: false,
        changeState: false,
    }
}

function buildPub(pub) {

    return {
        title: getObjectKey(pub, 'title'),
        publishedAt: getDateObjectFromDDMMYYY(getObjectKey(pub, 'publishedAt')),
        description: getObjectKey(pub, 'description'),
        url: getObjectKey(pub, 'url'),
        action: '',
        id: getObjectKey(pub, 'id'),
        adjusted: true,
        addState: false,
        changeState: false,
        changingUrl:false
    }
}

function buildAcademExp(academExp) {
    return {
        title: getObjectKey(academExp, 'title'),
        university: getObjectKey(academExp, 'university'),
        description: getObjectKey(academExp, 'description'),
        startDate: getDateObjectFromDDMMYYY(academExp.startDate),
        endDate: getDateObjectFromDDMMYYY(academExp.endDate),
        timeRange: [getDateObjectFromDDMMYYY(academExp.startDate), getDateObjectFromDDMMYYY(academExp.endDate)],
        id: getObjectKey(academExp, 'id'),
        action: '',
        adjusted: true,
        addState: false,
        changeState: false,
    }

}


function buildCredential(credential) {
    return {
        customer: getObjectKey(credential, 'customer'),
        description: getObjectKey(credential, 'description'),
        action: '',
        title: getObjectKey(credential, 'title'),
        id: getObjectKey(credential, 'id'),
        date: getDateObjectFromDDMMYYY(credential.startDate),
        timeRange: [getDateObjectFromDDMMYYY(credential.startDate), getDateObjectFromDDMMYYY(credential.endDate)],
        adjusted: true,
        addState: false,
        changeState: false,
    }

}


function buildHonor(honor) {
    const obj = {
        title: getObjectKey(honor, 'title'),
        year: getObjectKey(honor, 'year'),
        description: getObjectKey(honor, 'description'),
        action: '',
        id: getObjectKey(honor, 'id'),
        document: [],
        adjusted: true,
        addState: false,
        changeState: false,
    }
    if (getObjectKey(honor, 'document') !== null && !Array.isArray(getObjectKey(honor, 'document'))) {
        obj.documentUploaded = getObjectKey(honor, 'document')
    }
    return obj
    //ADD FILE
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
