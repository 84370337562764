<template>
  <b-modal id="sendInvitation"
           v-model="show"
           title="Invitez un associé à créer son équipe"
           centered
           scrollable>

    <template #modal-header="">
      <h4>Invitez un associé à créer son équipe</h4>
      <b-button class="btn" @click="$router.go(-1)"><font-awesome-icon icon="times"/></b-button>
    </template>

    <template>
      <b-form>
        <b-row class="mb-5">
          <b-col cols="12">
            <multiselect
                v-model="associateSelected"
                placeholder=""
                label="name"
                track-by="id"
                :options="associateList"
                :multiple="false"
                @select="isAssociateSelected"
                @remove="isNoAssociateSelected">
            </multiselect>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <vue-editor v-model="this.content" />
          </b-col>
        </b-row>
      </b-form>
    </template>

    <template #modal-footer="">
      <b-button class="btn_cancel" @click="$router.go(-1)">
        Annuler
      </b-button>

      <b-button class="btn_submit" :class="isDisabled ? 'disabled' : ''" @click.prevent="onsubmit()">
        Valider
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapState } from "vuex";
  //import { spinAction } from "@/utils";
  import { VueEditor } from "vue2-editor";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import { faTimes } from "@fortawesome/free-solid-svg-icons";
  import {library} from "@fortawesome/fontawesome-svg-core";

  Vue.component('font-awesome-icon', FontAwesomeIcon);
  library.add(faTimes);

  export default {
    name: 'SendInvitation',

    components: {
      Multiselect: () => import(/* webpackChunkName: "Multiselect" */ 'vue-multiselect'),
      VueEditor
    },

    data() {
      return {
        show: true,
        associateSelected: '',
        contentText: '',
        isDisabled: true
      }
    },

    mounted() {
      const path = 'project/create/step3/' + this.project.slug
      this.contentText = 'Bonjour, <br>Je vous invite à créer votre équipe dans le projet <a href="' + path + '">' + this.project.name+ '</a>  Cordialement, ' + this.userInfos.firstname + ' ' + this.userInfos.lastname
    },

    computed: {
      ...mapState({
        spinner: 'spinner'
      }),

      ...mapState('account', {
        listCoworkers: 'listCoworkers',
        userInfos:  'userInfos'
      }),

      ...mapState('project', {
        project: 'project'
      }),

      ...mapState('messenger', {
        currentConversation: 'currentConversation',
      }),

      associateList() {
        return this.listCoworkers.filter(item => item.isAdmin === true).filter(item => item.email !== this.userInfos.email)
      },

      content: {
        // accesseur
        get() {
          return this.contentText
        },
        // mutateur
        set(newValue) {
          this.contentText = newValue
        }
      },
    },

    methods: {
      ...mapActions({
        toggleSpinner: 'toggleSpinner',
      }),

      ...mapActions('messenger', {
        selectConversation: 'selectConversation',
        createPersonalConversation: 'createPersonalConversation',
        createMessageConversationSlug: 'createMessageConversationSlug',
      }),

      async onsubmit() {
        this.isDisabled = true

        // this.toggleSpinner()
        // spinAction(this.spinner, this)

        await this.createPersonalConversation({
          receivers: this.associateSelected.email,
          name: ''
        }).then(response => {
          this.disableValidate = false

          const date = new Date();
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          let currentDate = `${year}-${month}-${day}`;

          if (response.data.data.createdAt.date.slice(0, 10) === currentDate) {
            this.$toast.success('Conversation ajouté')
          }
        }).catch(error => {
          console.error(error)
          this.$toast.error(error.message)
          this.disableValidate = false
        })

        this.createMessageConversationSlug({
          content: this.content,
          sender: this.userInfos.email,
          conversationSlug: this.currentConversation.slug,
        }).then(() => {

          this.disableValidate = false
          this.$toast.success('Message envoyé')
        }).catch(error => {
          console.error(error)
          this.disableValidate = false
        })

        // this.toggleSpinner()
        // spinAction(this.spinner, this)
        this.$router.push({ name: 'Messagerie' })
      },

      isAssociateSelected() {
        this.isDisabled = false
      },

      isNoAssociateSelected() {
        this.isDisabled = true
      },
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>